import * as types from '../mutation-types';

export default {
    namespaced: true,
    
    state: {
        requests:[]
    },

    actions:{
        fetchAll({ commit }, { requestStatus, type, name, workload, specialtyId, regionIds, fromDate, toDate, perPage, page }){
            /*
                requestType:
                0 => Pending
                1 => Accepted
                2 => Rejected
            */
            var urlSearchParams = new URLSearchParams();
            var params = { requestStatus, type, name, workload, specialtyId, fromDate, toDate, perPage, page };

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) {
                    urlSearchParams.append(param, params[param]);
                }
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id);
            });

            return window.axios.get('/requests/entitymodifications', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        accept({ commit }, { id }){
            return window.axios.post(`/requests/entitymodifications/${id}/accept`);
        },

        reject({ commit }, { id }){
            return window.axios.post(`/requests/entitymodifications/${id}/reject`);
        },
    },

    mutations: {},
    getters:{}
}