<!-- TODO: repalce midcalRep to Supervisor -->
<!-- Add/edit supervisor dialog -->
<template>
    <div>
        <v-dialog
            transition="dialog-transition"
            max-width="700"
            ref="dialog"
        >
            <!-- <template v-slot:activator="{ on, attrs }">
                activate dialog button
                <v-btn text v-bind="attrs" v-on="on" small>
                    <slot name="activator-btn">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </slot>
                </v-btn>
            </template> -->

            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form">
                    <v-card>
                        <v-toolbar
                            color="primary"
                            dark
                            flat
                            class="text-uppercase justify-center"
                        >
                            <v-spacer/>
                            <!-- title of dialog -->
                            {{ title }}
                            <v-spacer/>
                        </v-toolbar>
                        <v-card-text class="pt-5">
                            <!-- dialog content -->
                            <v-container>
                                <v-row>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- supervisor name -->
                                        <v-text-field
                                          label="Supervisor name"
                                          clearable
                                          v-model="supervisorData.name"
                                          :rules="rules.required"
                                          required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- supervisor birthday date -->
                                        <v-menu
                                          ref="menuOfBirthdayDate"
                                          v-model="menuOfBirthdayDate"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="supervisorData.birthDate"
                                              label="Birthday date"
                                              prepend-icon="mdi-calendar"
                                              clearable
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="supervisorData.birthDate"
                                          :active-picker.sync="activePickerBirthdayDate"
                                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                          min="1940-01-01"
                                          @change="saveBirthdayDate"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- Telephone number -->
                                        <v-text-field
                                          label="Telephone number"
                                          v-model="supervisorData.landlineNumber"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- Phone number -->
                                        <v-text-field
                                          label="Phone number"
                                          v-model="supervisorData.phoneNumber"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- City -->
                                        <v-autocomplete
                                          label="City"
                                          v-model="cityId"
                                          :items="cities"
                                          item-value="id"
                                          item-text="name"
                                          @change="cityIdChange"
                                          :return-object="false"
                                          hint="Enter city to filter sectors"
                                          clearable
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- Sector -->
                                        <v-autocomplete
                                          label="Sector"
                                          v-model="supervisorData.sectorId"
                                          clearable
                                          :items="cityId? sectorsFiltered : sectors"
                                          item-value="id"
                                          item-text="name"
                                          :return-object="false"
                                          :rules="rules.required"
                                          required
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <!-- address -->
                                        <v-text-field
                                          label="Full address"
                                          v-model="supervisorData.address"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- job position -->
                                        <v-text-field
                                          label="Job position"
                                          v-model="supervisorData.jobPosition"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- facebook profile url -->
                                        <v-text-field
                                          label="facebook Profile Url"
                                          v-model="supervisorData.facebookProfileUrl"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="6" cols="12" class="py-1">
                                        <!-- Email -->
                                        <v-text-field
                                          label="Email"
                                          v-model="supervisorData.email"
                                          clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="6" cols="12" class="d-flex align-center">
                                        <!-- supervisor starting date -->
                                        <v-menu
                                          ref="menuOfStartingDate"
                                          v-model="menuOfStartingDate"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="supervisorData.startingDate"
                                              label="Starting date"
                                              prepend-icon="mdi-calendar"
                                              clearable
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="supervisorData.startingDate"
                                            :active-picker.sync="activePickerStartingDate"
                                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            min="1940-01-01"
                                            @change="saveStartingDate"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="d-flex">
                                        <!-- supervisor photo -->
                                        <v-img
                                          class="rounded"
                                          lazy-src="https://picsum.photos/id/11/10/6"
                                          height="150"
                                          width="150"
                                          src="supervisorPhoto"
                                        ></v-img>
                                        <v-file-input
                                          class="align-self-center ms-10"
                                          @change="snackbar = true"
                                          v-model="supervisorPhoto"
                                          accept="image/*"
                                          label="Supervisor photo"
                                          hide-input
                                          placeholder="Pick a photo"
                                          :prepend-icon="supervisorPhoto?'mdi-image':'mdi-camera-outline'"
                                        ></v-file-input>
                                        
                                    </v-col>
                                </v-row>

                                <!-- System account details of supervisor -->
                                <h6 class="text-h6 font-weight-regular mt-10 mb-5 primary--text">
                                    System account details of supervisor
                                </h6>
                                <v-row>
                                    <v-col class="py-1">
                                        <!-- Username on system -->
                                        <v-text-field
                                            label="Username on system"
                                            v-model="supervisorData.username"
                                            :rules="rules.required"
                                            required
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1">
                                        <!-- Password on system -->
                                        <v-text-field
                                            label="Password on system"
                                            v-model="supervisorData.password"
                                            :rules="supervisorId? []: rules.required.concat(rules.password)"
                                            :hint="supervisorId ? 'Leave it empty if you don\'t want to change password':''"
                                            clearable
                                            type="password"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                @click="localDialog = dialog"
                                type="submit"
                                color="success white--text"
                                elevation="1"
                                :loading="loading"
                            >
                                save
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="1"
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- snackbar will appear after send data -->
        <v-snackbar
          v-model="snackbar"
          transition="slide-y-reverse-transition"
          color="primary"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success lighten-2"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import sectors from '../store/modules/sectors'
import rules from '../validation rules'
export default {
    data(){
        return {
            title: null,
            supervisorId: null,

            supervisorData:{
                name: null,
                birthDate: null,
                landlineNumber: null,
                phoneNumber: null,
                sectorId: null,
                address: null,
                email: null,
                jobPosition: null,
                facebookProfileUrl: null,
                startingDate: null,
                username: null,
                password: null,
            },

            activePickerBirthdayDate: null,
            menuOfBirthdayDate: false,
            
            activePickerStartingDate: null,
            menuOfStartingDate: false,
            
            supervisorPhoto: null,
            
            cityId: null,
            
            localDialog: null,
            snackbar: false,
            loading: false,
            message: null,

            rules
        }
    },

    watch: {
        menuOfBirthdayDate (val) {
            val && setTimeout(() => (this.activePickerBirthdayDate = 'YEAR'))
        },

        menuOfStartingDate (val) {
            val && setTimeout(() => (this.activePickerStartingDate = 'YEAR'))
        },
    },

    methods: {
        saveBirthdayDate (birthdayDate) {
            this.$refs.menuOfBirthdayDate.save(birthdayDate)
        },

        saveStartingDate (startingDate) {
            this.$refs.menuOfStartingDate.save(startingDate)
        },

        resetInputs(){
            if (this.supervisorData.name) this.$refs.form.resetValidation();
            this.supervisorData.name = null;
            this.supervisorData.birthDate = null;
            this.supervisorData.landlineNumber = null;
            this.supervisorData.phoneNumber = null;
            this.supervisorData.sectorId = null;
            this.supervisorData.address = null;
            this.supervisorData.email = null;
            this.supervisorData.jobPosition = null;
            this.supervisorData.facebookProfileUrl = null;
            this.supervisorData.startingDate = null;
            this.supervisorData.username = null;
            this.supervisorData.password = null;
        },

        addNewSupervisor(){
            // to set title of dialog
            this.title = 'add supervisor';
            
            // to reset supervisorId & cityId of dialog
            this.supervisorId = null;
            this.cityId = null;

            // to reset supervisorData of dialog
            this.resetInputs();

            // to activate dialog
            this.$refs.dialog.isActive = true;
        },

        editSupervisor(supervisorId){
            // to set title of dialog
            this.title = 'edit supervisor';

            // to set supervisorId of dialog
            this.supervisorId = supervisorId;

            // to reset cityId of dialog
            this.cityId = null;
            
            // to reset supervisorData of dialog
            var supervisor = this.getSupervisorById(supervisorId);

            this.supervisorData.name = supervisor.name;
            this.supervisorData.birthDate = supervisor.birthDate ? window.moment(supervisor.birthDate).format('YYYY-MM-DD') : null;
            this.supervisorData.landlineNumber = supervisor.landlineNumber;
            this.supervisorData.phoneNumber = supervisor.phoneNumber;
            this.supervisorData.sectorId = supervisor.sectorId;
            this.supervisorData.address = supervisor.address;
            this.supervisorData.email = supervisor.email;
            this.supervisorData.jobPosition = supervisor.jobPosition;
            this.supervisorData.facebookProfileUrl = supervisor.facebookProfileUrl;
            this.supervisorData.startingDate = supervisor.startingDate ? window.moment(supervisor.startingDate).format('YYYY-MM-DD') : null;
            this.supervisorData.username = supervisor.username;
            this.supervisorData.password = supervisor.password;

            // to activate dialog
            this.$refs.dialog.isActive = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;

                var promise = this.supervisorId
                    ? this.$store.dispatch('supervisors/update', {
                        id: this.supervisorId,
                        newSupervisorData: this.supervisorData
                    })
                    : this.$store.dispatch('supervisors/create', this.supervisorData);

                promise.then(() => {
                    this.message = this.supervisorId ? 'Supervisor is edited' : 'Supervisor is added';
                    this.localDialog.value = false;
                    this.resetInputs();
                    this.$emit('on-save');
                })
                .catch((e) => {
                    this.message = e.response.data.message
                })
                .finally(() => {
                    this.loading = false;
                    this.snackbar = true;
                })
            }
        },

        cityIdChange(){
            this.$store.dispatch('sectors/fetchByCityId', {
                cityId: this.cityId
            });
        }
        
    },

    computed:{
        ...mapState({
            cities: state => state.cities.cities,
            sectors: state => state.sectors.sectors,
            sectorsFiltered: state => state.sectors.sectorsFiltered,
            supervisors: state => state.supervisors.supervisors
        }),
        
        ...mapGetters({
            getSupervisorById: 'supervisors/getSupervisorById',
        })
    },

}
</script>

<style>

</style>