<template>
	<v-tabs
		v-model="localValue"
		show-arrows
		fixed-tabs
		hide-slider
		centered
		optional
		center-active
		active-class="secondary white--text"
		:style="{ maxWidth: `${maxWidth}px`}"
	>
		<v-tab
			v-for="day in selectedMonthDays"
			:key="day.dayNumber"
			:disabled="disabled"
			class="d-flex flex-column rounded-sm px-2"
			style="min-width: 0; overflow: hidden"
			:href="`#${day.date}`"
		>
			<span class="text-caption">{{day.shortName}}</span>
			<span class="text-body-1">{{day.dayNumber}}</span>
		</v-tab>
	</v-tabs>
</template>

<script>
import moment from 'moment';
export default {
	name: 'VMonthDays',

	props: {
		month: { type: String, default: new Date().toISOString().slice(0, 7) },
		disabled: { type: Boolean, default: false },
		value: { type: String, default: '' },
		maxWidth: { type: Number }
	},

	computed: {
		selectedMonthDays() {
			const daysCount = moment(this.month, 'YYYY-MM').daysInMonth();
			const days = [];
			for (let i = 1; i <= daysCount; i++) {
				const date = this.month + '-' + i.toString().padStart(2, 0);
				days.push({
					dayNumber: i.toString().padStart(2, 0),
					shortName: moment(date).format('dd'),
					date 
				})
			}
			return days;
		},

		localValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal);
			}
		}
	}
}
</script>

<style>

</style>