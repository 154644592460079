import * as types from '../mutation-types'
export default {
    namespaced: true,

    state: {
        classification: []
    },

    actions: {
        fetchAll ({ commit }) {
            return window.axios.get('/workLoad').then(({ data }) => {
                commit(types.STORE_CLASSIFICATION, data);
                return data
            })
        },

        fetchById ({ commit }, { id }) {
            return window.axios.get(`/workLoad/${id}`).then(({ data }) => {
                return data
            })
        },

        create ({ commit }, { name, order }) {
            return window.axios.post('/workLoad', { name, order }).then(({ data }) => {
                return data
            })
        },

        update ({ commit }, { id, name, order }) {
            return window.axios.put(`/workLoad/${id}`, { name, order }).then(({ data }) => {
                return data
            })
        }
    },

    mutations: {
        [types.STORE_CLASSIFICATION] (state, classification) {
            state.classification = classification
        }
    },

    getters: {
        getClassificationById: state => id => state.classification.find(c => c.id === id),
    }
}