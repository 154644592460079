import * as types from '../mutation-types'

export default {
    namespaced: true,
    state: {},

    actions:{
        fetchAll({ commit }, { fromDate, toDate, sectorId, perPage, page }){
            return window.axios.get('/salaries', {
                params: { fromDate, toDate, sectorId, perPage, page }
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        create({ commit }, { employeeId, date, baseSalary, transportation, livingExpenses, retentionBonus, communications, liabilityCompensation, rewards, rebates }){
            return window.axios.post('/salaries', { 
                employeeId,
                date,
                baseSalary,
                transportation,
                livingExpenses,
                retentionBonus,
                communications,
                liabilityCompensation,
                rewards,
                rebates 
            })
        },

        update({ commit }, { id, employeeId, date, baseSalary, transportation, livingExpenses, retentionBonus, communications, liabilityCompensation, rewards, rebates }){
            return window.axios.put(`/salaries/${id}`, { 
                employeeId,
                date,
                baseSalary,
                transportation,
                livingExpenses,
                retentionBonus,
                communications,
                liabilityCompensation,
                rewards,
                rebates 
            })
        },

        import({ commit }, { excelFile }){
            var formData = new FormData();
            formData.append('excelFile', excelFile);
            return window.axios.post('/salaries/import', formData)
                .then((response) => {
                    return response.data;
                });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/salaries/${id}`);
        }
    }
}