import * as types from '../mutation-types'; 

export default {
    namespaced: true,

    state: {
        teams: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/Teams').then(({ data }) => {
                commit(types.STORE_TEAMS, data)
                return data
            })
        },
        
        fetchById({ commit }, { id }) {
            return window.axios.get(`/Teams/${id}`).then(({ data }) => {
                return data
            })
        },

        create({ commit }, { name, regionIds, specialtyIds }) {
            return window.axios.post('/Teams', { name, regionIds, specialtyIds }).then(({ data }) => {
                return data
            })
        },

        update({ commit }, { id, name, regionIds, specialtyIds }) {
            return window.axios.put(`/teams/${id}`, { name, regionIds, specialtyIds }).then(({ data }) => {
                return data
            })
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/teams/${id}`).then(({ data }) => {
                return data
            })
        },

        fetchEmployeeForTeam({ commit }, { id }) {
            return window.axios.get(`/teams/${id}/Employee`)
        }
        
    },

    mutations: {
        [types.STORE_TEAMS](state, teams) {
            state.teams = teams
        }
    },

    getters: {
        getTeamById: state => id => state.teams.find(c => c.id === id),
    }
}