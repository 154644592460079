<template>
   	<v-tooltip
		:disabled="disabled"
		:top="top" 
		:right="right"
		:left="left"
		:bottom="bottom"
    >
		<template v-slot:activator="{ on:tooltip, attrs }">
			<span v-bind="attrs" v-on="{...tooltip}">
			<slot></slot>
			</span>
		</template>
		<span class="text-caption">{{text}}</span>
    </v-tooltip>
</template>

<script>
export default {
    props:{
		text: { type: String },
		disabled: { type: Boolean },
		top : { type: Boolean },
		left: { type: Boolean },
		bottom: { type: Boolean },
		right: { type: Boolean },
    }
}
</script>

<style>

</style>