<template>
    <div>
        <!-- dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="500"
            ref="dialogRestore"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <div class="d-flex py-1 red lighten-1">
                        <v-card-title class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text">
                            Restore all samples
                        </v-card-title>
                        <!-- close button -->
                        <v-btn icon @click="dialog.value = false">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-1 pb-0">
                        <span class="text-body-1">
                            Are you sure you want to
                            <span class="red--text text--lighten-1">
                                restore all samples
                            </span>
                            from the selected medical rep to her / his line manager  ?
                        </span>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            color="red lighten-1"
                            class="white--text"
                            text
                            :loading="loading"
                            @click="submitRestoreData"
                        >
                            Confirm
                        </v-btn>
                        <v-btn
                            text
                            :disabled="loading"
                            @click="dialog.value = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- filter -->
        <v-form @submit.prevent="filter">
            <v-row dense class="align-center ma-0">
                <!-- date -->
                <v-col md="3" sm="6" cols="12">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col md="3" sm="6" cols="12">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <v-col class="d-flex justify-end">
                    <v-btn
                        color="success"
                        class="px-sm-4 "
                        :loading="loading"
                        :disabled="isAllFieldsEmpty || loading"
                        small
                        type="submit"
                    >
                        <v-icon size="22" class="me-1"> mdi-magnify </v-icon>
                        Search
                    </v-btn>
            
                    <template v-if="isSuperAdmin || permissionsUser.MaterialTransactions === roleName.action">
                        <v-divider vertical class="mx-2"/>
                        
                        <v-btn
                            color="red lighten-1 white--text"
                            :loading="loading"
                            :disabled="loading"
                            small
                            @click="$refs.dialogRestore.isActive = true"
                        >
                            <v-icon size="22" class="me-1">mdi-restore</v-icon>
                            Restore all samples
                        </v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-form>
        
        <div>
            <h2 class="text-body-1 primary--text text-capitalize mb-1">Samples Summary</h2>
            
            <bee-handy-table
                :headers="headers"
                :items="!loading ? summaryItems : []"
                :loading="loading"
                height="calc(33vh - 24px)"
                pagination-on-scroll
                zebra
                dense
            ></bee-handy-table>
        </div>

        <h2 class="text-body-1 primary--text text-capitalize my-1">
            Remaining Samples
        </h2>

        <bee-handy-table
            :headers="remainingHeaders"
            :items="!loading ? remainingItems : []"
            :loading="loading"
            :height="isSuperAdmin ? 'calc(33vh - 24px)' : $vuetify.breakpoint.height - 210"
            pagination-on-scroll
            zebra
            dense
        ></bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { getMaterialName } from '@/helpers/functions'
import { roleName } from '@/helpers/enums'

export default {
    name: 'MedicalRepSamples',

    data(){
        return {
            summaryItems: [],
            remainingItems: [],
            
            startDate: null,
            endDate: null,

            loading: true,
            roleName,

            headers: [
                {
                    text: 'Date',
                    name: 'date',
                    align: 'start',
                    sortable: true,
                    value: 'date',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD'),
                    class:'primary--text',
                    cellClass:'primary--text',
                },
                {
                    text: 'Material',
                    name: 'material',
                    value: 'in.materialId',
                    setValueExpr: val => this.getFullMaterialName(val),
                    align: 'start'
                },
                { 
                    text: 'Received',
                    name: 'received',
                    value: 'in.amount',
                    align: 'start',
                },
                { 
                    text: 'Provided',
                    name: 'provided',
                    value: 'out.amount',
                    align: 'start',
                },
                { 
                    text: 'Remaining',
                    name: 'remaining',
                    value: 'remaining.amount',
                    align: 'start',
                },
            ],

            remainingHeaders: [
                {
                    text: 'Material',
                    name: 'material',
                    value: 'in.materialId',
                    setValueExpr: val => this.getFullMaterialName(val),
                    align: 'start'
                },
                { 
                    text: 'Remaining',
                    name: 'remaining',
                    value: 'remaining.amount',
                    align: 'start',
                },
            ]
        }
    },

    computed: {
        isAllFieldsEmpty(){
            return !this.startDate && !this.endDate;
        },

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getSupervisorById: 'supervisors/getSupervisorById',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getMaterialFormById: 'materialForms/getMaterialFormById',
            isAdmin: 'auth/isAdmin',
        }),

        ...mapState({
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions
        })
    },

    methods: {
        filter() {
            this.loading = true;

            return this.$store.dispatch('materialTransactions/fetchMaterialReceiptsSummary', { 
                employeeId: this.$route.params.id,
                fromDate: this.startDate,
                toDate: this.endDate,
            }).then((data) => {
                this.summaryItems = data;
            }).finally(() => {
                this.loading = false;
            });
        },

        submitRestoreData(){
            this.loading = true;

            this.$store.dispatch('materialTransactions/restore', { employeeId: this.$route.params.id })
            .then(() => {
                this.$eventBus.$emit('show-snackbar', 'Samples have been restored', 'info');
                this.$refs.dialogRestore.isActive = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // helpers
        getFullMaterialName(id) {
            const material = this.getMaterialById(id);
            if (material) {
                return getMaterialName(material, this.getMaterialFormById(material.materialFormId).name);
            }
        },
    },

    created() {
        this.startDate = moment().startOf('month').format('YYYY-MM-DD');
        this.endDate = moment().endOf('month').format('YYYY-MM-DD');

        Promise.all([
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.materialForms.length ? this.$store.dispatch('materialForms/fetchAll') : null,
            this.$store.dispatch('materialTransactions/fetchRemaining', { id: this.$route.params.id }).then((data) => {
                this.remainingItems = data;
            }),
            this.filter(),
        ]);
    },

    metaInfo: {
        title: 'Medical rep samples',
        titleTemplate: '%s | Octopharma'
    }
}
</script>