var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('delete-dialog',{ref:"deleteDialog",attrs:{"success-message":"Data is deleted"},on:{"on-delete":function($event){return _vm.getDoubleVisits()}}},[_vm._v(" Are you sure you want to "),_c('span',{staticClass:"red--text"},[_vm._v("delete")]),_vm._v(" this Double visit report? ")]),(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"small":"","color":"light-blue white--text","to":{
            name: 'double-visit-report'
        }}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add new report ")],1):_vm._e(),_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.isFiltered = true; _vm.submit()}}},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{staticClass:"pe-1 pb-1",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.medicalReps.filter(function (c) { return c.employeeType  !== _vm.userType.medicalRep; }),"item-value":"id","item-text":"name","loading":_vm.submitLoading,"disabled":_vm.loading,"label":"Employee","outlined":"","dense":"","hide-details":""},model:{value:(_vm.employeeId),callback:function ($$v) {_vm.employeeId=$$v},expression:"employeeId"}})],1),_c('v-col',{staticClass:"px-1 pb-1",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.medicalReps.filter(function (c) { return c.employeeType  === _vm.userType.medicalRep; }),"item-value":"id","item-text":"name","loading":_vm.submitLoading,"disabled":_vm.loading,"label":"Medical rep","outlined":"","dense":"","hide-details":""},model:{value:(_vm.medicalRepId),callback:function ($$v) {_vm.medicalRepId=$$v},expression:"medicalRepId"}})],1),_c('v-col',{staticClass:"px-1 pb-1",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":"Start date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    },"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{staticClass:" ps-1 pb-1",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":"End date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end px-1 pb-1",attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success white--text","small":"","disabled":_vm.loading || _vm.isAllFieldsEmpty},on:{"click":function($event){return _vm.getDoubleVisits()}}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" Search ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"color":"alert-color white--text","small":"","loading":_vm.loading,"disabled":!_vm.isFiltered},on:{"click":function($event){_vm.reset(); _vm.getDoubleVisits()}}},[_vm._v(" Reset ")])],1)],1)],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":_vm.doubleVisits,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.$vuetify.breakpoint.height - 200,"pagination-on-scroll":"","zebra":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('tooltip',{attrs:{"text":"View Report","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports !== _vm.roleName.action  ? 'success' : 'edit-color',"to":{
                        name: 'double-visit-report',
                        params: {
                            id: item.id
                        },
                        query: {
                            from: 'all',
                            preview: true
                        }
                    }}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1),(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('tooltip',{attrs:{"text":"Delete report","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"alert-color"},on:{"click":function($event){return _vm.$refs.deleteDialog.activeDeleteDialog(item.id, 'doubleVisit')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }