var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center mb-sm-5"},[_c('h2',{staticClass:"text-h5 primary--text text-capitalize"},[_vm._v("Sales Statistics")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'statistics-chart', params: { type: 'sales' } }}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("mdi-chart-bar")]),_vm._v(" View chart ")],1)],1),_c('v-row',{staticClass:"mx-1"},[_c('v-col',{staticClass:"pa-1",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.materials,"item-text":_vm.getFullMaterialName,"item-value":"id","return-object":false,"label":"Material Name","dense":"","hide-details":"","multiple":"","clearable":"","loading":_vm.filterLoading,"disabled":_vm.loading,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.materialIds.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.materialIds),callback:function ($$v) {_vm.materialIds=$$v},expression:"materialIds"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"md":"2","sm":"6","cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.cities,"item-text":"name","item-value":"id","return-object":false,"label":"City","dense":"","hide-details":"","multiple":"","clearable":"","loading":_vm.filterLoading,"disabled":_vm.loading,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.cityIds.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.cityIds),callback:function ($$v) {_vm.cityIds=$$v},expression:"cityIds"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"md":"2","cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.warehouses,"item-text":"name","item-value":"id","return-object":false,"label":"Warehouse","dense":"","hide-details":"","multiple":"","clearable":"","loading":_vm.filterLoading,"disabled":_vm.loading,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.warehouseIds.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.warehouseIds),callback:function ($$v) {_vm.warehouseIds=$$v},expression:"warehouseIds"}})],1)],1),_c('v-row',{staticClass:"mx-1 mb-1"},[_c('v-col',{staticClass:"pa-1",attrs:{"md":"3","sm":"6","cols":"12","align-self":"center"}},[_c('bee-date-picker',{attrs:{"input-label":"Start date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                    outlined: true,
                    dense: true
                },"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"1940-01-01"},model:{value:(_vm.menuOfStartDate),callback:function ($$v) {_vm.menuOfStartDate=$$v},expression:"menuOfStartDate"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"md":"3","sm":"6","cols":"12","align-self":"center"}},[_c('bee-date-picker',{attrs:{"input-label":"End date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                    outlined: true,
                    dense: true
                },"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"1940-01-01"},model:{value:(_vm.menuOfEndDate),callback:function ($$v) {_vm.menuOfEndDate=$$v},expression:"menuOfEndDate"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"pa-1 d-flex justify-end",attrs:{"align-self":"center"}},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"small":""},on:{"click":function($event){_vm.isFiltered = true; _vm.filter();}}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" Search ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"disabled":!_vm.isFiltered || _vm.loading,"color":"alert-color white--text","small":""},on:{"click":function($event){_vm.clearFilter(); _vm.filter();}}},[_vm._v(" Reset ")])],1)],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":!_vm.loading ? _vm.extendedStatistics : [],"loading":_vm.loading,"dense":"","zebra":"","pagination-on-scroll":"","fixed-header":"","height":_vm.$vuetify.breakpoint.height - 188}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }