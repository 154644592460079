<template>
    <div>
        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; filter()">
            <v-row class="mb-1">
                <!-- city -->
                <v-col md="2" sm="6" cols="12" class="" align-self="center">
                    <v-autocomplete
                        v-model="sectorId"
                        :items="sectors"
                        item-text="name"
                        item-value="id"
                        :return-object="false"
                        label="Area"
                        dense
                        disabled
                        hide-details
                        :loading="filterLoading"
                        outlined
                    ></v-autocomplete>
                </v-col>
                
                <v-spacer />
                
                <!-- actions -->
                <v-col cols="" align-self="end" class="d-flex justify-start justify-sm-end w-100 pt-0 pt-sm-3">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || !sectorId"
                        dense
                        small
                        type="submit"
                    >
                        <v-icon> mdi-magnify </v-icon>
                        Search
                    </v-btn>
                    <v-btn
                        dense
                        class="px-sm-8"
                        @click="clearFilter(); filter();"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        Reset
                    </v-btn>

                    <v-divider vertical class="mx-2" />
                    <!-- BACK -->
                    <v-btn
                        color="grey darken-2 white--text"
                        small
                        :to="{ name: 'Exams' }"
                    >
                        <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                        Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- Data table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? examResults : []"
            fixed-header
            :height="$vuetify.breakpoint.height - 160"
            pagination-on-scroll
            :loading="loading"
            dense
            zebra
        >
            <!-- action -->
            <template v-slot:item.actions="{  }">
                <div class="d-flex justify-center">
                    <tooltip text="View results chart" top>
                        <v-btn text small color="success" disabled>
                            <v-icon>
                                mdi-eye-outline
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Tooltip from '@/components/Tooltip';
import { dig } from '@/helpers/functions';
export default {
    name: 'ViewExamResults',

    components:{
        Tooltip
    },

    data(){
        return {
            // helpers
            loading: false,
            filterLoading: false,
            isFiltered: false,

            // filter data
            sectorId: null,
        }
    },

    computed:{
        ...mapState({
            sectors: state => state.sectors.sectors,
            examResults: state => state.exams.examResults,
            medicalReps: state => state.medicalreps.medicalreps,
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getSectorById: 'sectors/getSectorById'
        }),
        
        headers() {
            const headers = [
                { 
                    text: 'Employee Name', 
                    name: 'medicalRep',
                    value: 'employeeId', 
                    setValueExpr: val => this.dig('getMedicalRepById', val, 'name'),
                    align: 'start', 
                    sortable: false, 
                    class:'primary--text', 
                    cellClass:'primary--text', 
                    width: '300',
                    noNotAvailableText: true 
                },
                { 
                    text: 'Sector', 
                    name: 'sector',
                    value: 'employeeId',
                    setValueExpr: val => this.dig('getSectorById', this.dig('getMedicalRepById', val, 'sectorId'), 'name'),
                    align: 'center', 
                    noNotAvailableText: true 
                },
                { 
                    text: 'Mark',
                    name: 'mark', 
                    value: 'mark', 
                    align: 'center',
                    width: '300', 
                    noNotAvailableText: true 
                },
                { 
                    text: 'Actions', 
                    name: 'actions', 
                    value: 'actions', 
                    align: 'center',
                    width: '200',
                    sortable: false,
                },
            ];

            const sharedClass = 'text-body-2 font-weight-bold px-2';

            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
            }

            return headers;
        } 
    },

    methods:{
        clearFilter(){
            this.sectorId = null;
            this.isFiltered = null;
        },

        filter(){
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },

        // Find data funcitons
        dig,
    },

    created(){
        this.loading = true;
        this.filterLoading = true;
        Promise.all([
            !this.sectors.length ? this.$store.dispatch('sectors/fetchAll') : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            this.$store.dispatch('exams/fetchExamResults', { id: this.$route.params.id })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    metaInfo: {
        title: 'Exam Results',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
