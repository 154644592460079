export default {
    "visits-count": "عدد الزيارات",
    "view-chart": "عرض المخططات البيانية",
    "visit-details": "تفاصيل الزيارة",
    "distributed-materials": "المواد الموزعة",
    "materials": "المواد",
    "quantity": "الكمية",
    "other-materials": "مواد أخرى",
    "duration-in-minutes": "المدة بالدقائق",
    "core-message": "الرسالة الأساسية",
    "notes": "الملاحظات",
    "objections": "الاعتراضات",
    "next-call": "الاتصال التالي",
    "unavailable-products": "المنتجات الغير متوفرة",
    "competitors": "المنافسين",
    "center-notes": "ملاحظات المركز"
}