<template>
    <div>
        <!-- request-dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="400"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <div 
                        class="d-flex justify-end py-1"  
                        :class="(isAcceptance ? 'success' : 'red lighten-2') + ' white--text'"
                    >
                        <v-card-title class="d-flex justify-center flex-grow-1 text-body-1 text-uppercase pa-0">
                            {{ isAcceptance ? 'Confirm acceptance' : 'Confirm rejection' }}
                        </v-card-title>
                        <v-btn
                        	icon
                        	color="white"
                        	@click="dialog.value = false"
                        >
                            <v-icon> mdi-close-circle-outline </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-2 pb-0 text-body-1">
                        Are you sure you want to 
                        <span :class="isAcceptance ? 'success--text' : 'red--text text--lighten-1'">
                            {{isAcceptance? 'Accept' : 'Reject'}}
                        </span>
                        this request?
                    </v-card-text>
                    <v-card-actions class="justify-end ">
                        <v-btn
                            text
                            @click="submit"
                            :loading="loading"
                            :color="isAcceptance ? 'success' : 'red lighten-1'"
                            class="white--text text-body-2"
                            elevation="0"
                        >
                            Confirm
                        </v-btn>
                        <v-btn
                            @click="dialog.value = false"
                            :disabled="loading"
                            text
                            elevation="0"
                            class="text-body-2"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
          ref="deleteDialog"
          @on-delete="needToRestShownItems = true; filter()"
          success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> the request?
        </delete-dialog>

        <!-- filter -->
		<v-form @submit.prevent="isFiltered = true; needToRestShownItems = true; filter();">
            <v-row dense class="align-center ma-0">
                <!-- name of -->
                <v-col :md="$route.name === 'day-off-requests' ? 2 : 3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="medicalrepId"
                        :items="medicalreps"
                        item-text="name"
                        item-value="id"
                        label="Employees"
                        dense
                        hide-details
                        clearable
                        :loading="filterLoading"
                        prepend-icon="mdi-account-tie"
                        :disabled="loading || $route.name !== 'day-off-requests'"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- sector -->
                <v-col v-if="0" md="2" sm="4" cols="12">
                    <v-autocomplete
                        v-model="sectorId"
                        :items="sectorsMine"
                        item-text="name"
                        item-value="id"
                        :return-object="false"
                        label="Area"
                        dense
                        hide-details
                        clearable
                        :loading="filterLoading"
                        :disabled="loading || $route.name !== 'day-off-requests'"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- request type -->
                <v-col :md="$route.name === 'day-off-requests' ? 2 : 3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="requestType"
                        :items="requestTypes"
                        item-text="name"
                        item-value="id"
                        :return-object="false"
                        label="Request Type"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- start date -->
                <v-col lg="3" :md="$route.name === 'day-off-requests' ? 3 : 4" sm="4" cols="12">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col lg="3" :md="$route.name === 'day-off-requests' ? 3 : 4" sm="4" cols="12">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- viewed requests -->
                <v-col sm="auto" cols="12">
                    <v-radio-group v-if="0" row dense v-model="requestState" hide-details class="mb-2 mt-0">
                        <span class="text-body-1 me-3">Viewed requests:</span>
                        <v-radio label="Pending"></v-radio>
                        <v-radio label="Accepted"></v-radio>
                        <v-radio label="Rejected"></v-radio>
                    </v-radio-group>
                    
                    <v-icon size="30" class="me-1 ms-2"> mdi-list-status </v-icon>

                    <v-btn-toggle
                        v-model="requestState"
                        mandatory
                        dense
                        class="mb-1"
                        color="primary"
                        @change="isFiltered = true; needToRestShownItems = true; filter()"
                    >
                        <v-btn :disabled="loading">Pending</v-btn>
                        <v-btn :disabled="loading">Accepted</v-btn>
                        <v-btn :disabled="loading">Rejected</v-btn>
                        <v-btn :disabled="loading">All</v-btn>
                    </v-btn-toggle>
                </v-col>
                
                <v-spacer v-if="!$vuetify.breakpoint.smAndDown"/>

                <v-col sm="auto" cols="12" class="text-end">
                    <v-btn
                        small
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading"
                        type="submit"
                    >
                        <v-icon> mdi-magnify </v-icon>
                        Search
                    </v-btn>

                    <v-btn
                        class="px-sm-8"
                        :disabled="!isFiltered || loading"
                        @click="needToRestShownItems = true; clearFilter(); filter();"
                        color="alert-color white--text"
                        small
                    >
                        Reset
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            :loading="loading"
            fixed-header
            dense
            :data-table-props="{
                disableSort: true
            }"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :pagination-on-scroll-auto-reset-shown="false"
            :server-items-length="totalRequests"
            :height="$vuetify.breakpoint.height - ($route.name === 'day-off-requests' ? 175 : 230)"
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            zebra
            @pagination-on-scroll:reach-last-row="filter"
        >
            <!-- status -->
            <template v-slot:item.status="{item}">
                <span
                	:class="{
                        'warning--text': item.status === requestStatus.pending,
                        'success--text': item.status === requestStatus.accepted,
                        'red--text text--lighten-2': item.status === requestStatus.rejected,
                    }"
                >
                    {{requestStates.find(c => c.id === item.status).name}}
                </span>
            </template>

            <!-- actions -->
            <template v-slot:item.action="{item}">
                <tooltip text="Accept" v-if="item.status === requestStatus.pending" top>
                    <v-btn
                    	small
                        icon
                    	color="success"
                    	@click="initializeResponseConfirm(item.id, true)"
                    >
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </tooltip>

                <tooltip text="Reject" v-if="item.status === requestStatus.pending" top>
                    <v-btn
                    	small
                        icon
                    	color="red lighten-2 white--text"
                    	class="mx-1"
                    	@click="initializeResponseConfirm(item.id, false)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </tooltip>

                <tooltip text="Delete request" top>
                    <v-btn
                        icon
                        small
                        color="alert-color white--text"
                        @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'medicalrepsRequests')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import Tooltip from '@/components/Tooltip'
import { mapState, mapGetters } from 'vuex';
import { requestStatus, requestStates, requestTypes, roleName } from '@/helpers/enums'
import { dig } from '@/helpers/functions';
import moment from 'moment'

export default {
    name: 'DayOffRequests',
    
    components:{
        DeleteDialog,
        Tooltip
    },

    data(){
        return {
            roleName,
            // helpers
            tab: 0,
            loading: false,
            filterLoading: false,
            isFiltered: false,
            
            requestTypes,
            requestStates,
            requestStatus,

            // pagination
            needToRestShownItems: false,
            scrollOptions: {},
            totalRequests: null,

            // filter data
            medicalrepId: null,
            sectorId: null,
            requestType: null,
            requestState: 0,
            startDate: null,
            endDate: null,

            // request dialog
            isAcceptance: true,
            
            // data
            id: null,
        }
    },

    computed:{
        ...mapState({
            sectorsMine: state => state.sectors.sectorsMine,
            medicalreps: state => state.medicalreps.medicalreps,
            requests: state => state.medicalrepsRequests.requests,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            getCityNameBySectorId: 'sectors/getCityNameBySectorId',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
        }),

        headers() { 
            const headers = [
                { 
                    name: 'employeeId',
                    text: 'Employee',
                    value: 'employeeId',
                    align: 'start',
                    columnClass: 'primary--text',
                    setValueExpr: value => this.getMedicalRepById(value)?.name || '-',
                },
                {
                    name: 'createdAt', 
                    text: 'Submission Date',
                    value: 'createdAt', 
                    type: 'date',
                    align: 'start', 
                    cellClass:'fixed-width-date',
                },
                {
                    name: 'startDate', 
                    text: 'Start date',
                    value: 'date', 
                    type: 'date',
                    align: 'start', 
                    cellClass:'fixed-width-date',
                },
                { 
                    name: 'type', 
                    text: 'Request Type', 
                    value: 'type', 
                    align: 'start',
                    setValueExpr: val => this.requestTypes.find(c => c.id === val)?.name ?? '-',
                    noNotAvailableText: true
                },
                { 
                    name: 'status',
                    text: 'Request State',
                    value: 'status',
                    align: 'start',
                    noNotAvailableText: true
                },
                { 
                    name: 'days', 
                    text: 'Days',
                    value: 'days', 
                    align: 'start', 
                },
                { 
                    name: 'reason', 
                    text: 'Reason', 
                    value: 'reason', 
                    align: 'start', 
                }
            ];

			const sharedClass = 'text-body-2 font-weight-bold px-2';
			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
			}

			if (this.isSuperAdmin || this.permissionsUser.Leaves === roleName.action){
				headers.push(
					{ 
						name: 'action', 
						text: 'Action', 
						value: 'action', 
						align: 'center', 
					}
				);
			}

            return headers;
        }
    },

    methods:{
        moment,
        // filter functions
        clearFilter(){
            this.medicalrepId = null;
            this.sectorId = null;
            this.requestType = null;
            this.requestState = 0;
            this.startDate = null;
            this.endDate = null;

            this.isFiltered = false;
        },

        filter(newScrollOptions, isFirstLoad = false){
            this.loading = true;
            let page = 1;

            if (newScrollOptions) {
                page = newScrollOptions.page;
            }

            const { itemsPerPage } = this.scrollOptions;

            // fetch data depends on filter
            return this.$store.dispatch('medicalrepsRequests/fetchAll', {
                employeeId: this.medicalrepId,
                sectorId: this.sectorId,
                type: this.requestType,
                status: this.requestState !== 3 ? this.requestState : null,
                fromDate: this.startDate,
                toDate: this.endDate,
                page: page,
                perPage: itemsPerPage || 20,
            })
            .then(({ data, total }) => {
                if (this.needToRestShownItems) {
                    this.$refs.table.resetShownRows(data);
                    this.needToRestShownItems = false;
                }
                this.totalRequests = total;
                this.$refs.table.addScrollItems(data)
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        // actions functions
        initializeResponseConfirm(id, isAcceptance){
            this.id = id;
            this.isAcceptance = isAcceptance;
            this.$refs.dialog.isActive = true;
        },
        
        // submit dialog visit
        submit() {
            this.loading = true;
            var action = this.isAcceptance ? 'accept' : 'reject';

            this.$store.dispatch(`medicalrepsRequests/${action}`, { id: this.id })
            .then(() => {
                const message = 'The request is ' + (this.isAcceptance ? 'accepted' : 'rejected');

                this.$eventBus.$emit('show-snackbar', message, 'info');

                this.$refs.dialog.isActive = false;
                this.needToRestShownItems = true;
                this.filter();
            })
        },

        // helpers
        dig,
    },

    created(){
        this.loading = true;
        this.filterLoading = true;
        
        // get query from url to filter on it
        if (this.$route.params.id) {
            this.isFiltered = true;
            this.medicalrepId = this.$route.params.id;
        }

        Promise.all([
            !this.sectorsMine.length ? this.$store.dispatch('sectors/getSectorsMine') : null,
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            this.filter(null, true),
        ])
        .then(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    metaInfo: {
        title: 'Day-off requests',
        titleTemplate: '%s | Octopharma'
    }
}
</script>