import * as types from '../mutation-types'

export default {
    namespaced: true,

    state: {
        doubleVisits: [],
        detailsType: []
    },

    actions: {
        fetchAll({ commit }, { medicalRepId, employeeId, date, fromDate, toDate }) {
            return window.axios.get('/DoubleVisit/medicalRep', { params: { medicalRepId, employeeId, date, fromDate, toDate } }).then(({ data }) => {
                commit(types.STORE_DOUBLE_VISIT, data)
                return data
            })
        },

        fetchInfoDetailType({ commit }) {
            return window.axios.get('/DoubleVisit/DetailType').then(({ data }) => {
                commit(types.STORE_INFO_TYPE, data)
                return data
            })
        },

        create({ commit }, { date, medicalRepId, entityId, details, regionsName, notes }) {
            return window.axios.post('/DoubleVisit', { date, medicalRepId, entityId, details, regionsName, notes }).then(({ data }) => {
                return data
            })
        },

        update({ commit }, { id, date, medicalRepId, regionsName, notes, details }) {
            return window.axios.put(`/DoubleVisit/${id}`, 
                { date, medicalRepId, regionsName, notes, details }, 
                { params: { id } }).then(({ data }) => {
                return data
            })
        },
        delete({ commit }, { id }) {
            return window.axios.delete(`/DoubleVisit/${id}`)
        }
    },

    mutations: {
        [types.STORE_DOUBLE_VISIT](state, doubleVisits) {
            state.doubleVisits = doubleVisits
        },
        [types.STORE_INFO_TYPE](state, detailsType) {
            state.detailsType = detailsType
        }
    },

    getters: {}
}