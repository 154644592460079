<template>
    <div>
        <v-form ref="form" @submit.prevent="getChartData">
            <v-card flat>
                <v-card-text class="">
                    <v-row>
                        <!-- city -->
                        <v-col sm="3" cols="12" class="pa-1">
                            <v-autocomplete
                                v-model="citiesIds"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="City Name"
                                dense
                                multiple
                                clearable
                                :disabled="loading"
                                :rules="$route.params.type === 'sales' ? rules.required : []"
                                outlined
                                hide-details="auto"
                                :search-input.sync="searchCityId"
                                @change="searchCityId = null"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleCities"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconCities }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ citiesIds.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- region -->
                        <v-col
                            v-if="['samples', 'visits'].includes($route.params.type)"
                            sm="4"
                            md="3"
                            cols="12"
                            class="pa-1"
                        >
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => citiesIds.includes(c.cityId))"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="Region"
                                dense
                                multiple
                                clearable
                                :disabled="loading"
                                outlined
                                hide-details="auto"
                                :search-input.sync="searchRegion"
                                @change="searchRegion = null"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleRegion"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconRegions }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- entities -->
                        <v-col cols="6" sm="4" md="3" v-if="['visits'].includes($route.params.type)" class="pa-1">
                            <v-autocomplete
                                v-model="entityId"
                                :items="entitiesBrief"
                                item-text="name"
                                item-value="id"
                                label="Doctor name"
                                dense
                                outlined
                                hide-details
                                clearable
                                :disabled="loading"
                            />
                        </v-col>
                        
                        <!-- medical-reps -->
                        <v-col cols="6" sm="4" md="3" v-if="['visits'].includes($route.params.type)" class="pa-1">
                            <v-autocomplete
                                v-model="medicalRepId"
                                :items="medicalreps"
                                item-text="name"
                                item-value="id"
                                label="Medical rep name"
                                dense
                                outlined
                                hide-details
                                clearable
                                :disabled="loading"
                            />
                        </v-col>

                            <v-col sm="3" cols="12" v-if="['samples'].includes($route.params.type)">
                                <v-text-field
                                    v-model="materialName"
                                    label="Product name"
                                    clearable
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    disabled
                                ></v-text-field>
                            </v-col>

                            <!-- pharmaceutical form -->
                            <v-col sm="3" cols="12" v-if="['samples'].includes($route.params.type)">
                                <v-autocomplete
                                    v-model="materialFormId"
                                    label="Pharmaceutical form"
                                    clearable
                                    :items="pharmaceuticalForm"
                                    item-text="name"
                                    item-value="id"
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    disabled
                                ></v-autocomplete>
                            </v-col>

                        <!-- product name -->
                        <v-col v-if="$route.params.type === 'sales'" sm="4" cols="12">
                            <v-autocomplete
                                v-model="materialsIds"
                                :items="materials"
                                :item-text="getFullMaterialName"
                                item-value="id"
                                :return-object="false"
                                label="product name"
                                dense
                                multiple
                                clearable
                                :disabled="loading"
                                outlined
                                hide-details="auto"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleMaterials"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconMaterials }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ materialsIds.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        
                        <!-- radios -->
                        <v-col cols="auto" class="d-flex flex-wrap align-center py-0">
                            <span class="scondary--text text-body-1 me-3">
                                Divide chart:
                            </span>
                            <v-radio-group
                                v-model="divideBy"
                                row
                                hide-details
                                class="mt-0"
                                @change="resetDivideByInputs"
                                :disabled="loading"
                            >
                                <v-radio
                                    label="Quarterly"
                                    :value="0"
                                ></v-radio>
                                <v-radio
                                    label="By date"
                                    :value="1"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>

                        <!-- quarter -->
                        <template v-if="divideBy === 0">
                            <v-col sm="6" md="3" cols="12" class="d-sm-flex align-center py-0">
                                <span class="text-body-1 primary--text me-3">Start:</span>
                                <v-row class="ma-0">
                                    <v-col cols="6" class="py-0 pe-1">
                                        <v-text-field
                                            v-model="startYear"
                                            label="Year"
                                            type="number"
                                            min="0"
                                            dense
                                            multiple
                                            :disabled="loading"
                                            :rules="rules.year"
                                            outlined
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="py-0 ps-0">
                                        <v-text-field
                                            v-model="startQuarter"
                                            label="Quarter"
                                            type="number"
                                            min="1"
                                            max="4"
                                            dense
                                            multiple
                                            :disabled="loading"
                                            :rules="rules.quarter"
                                            outlined
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col sm="6" md="3" cols="12" class="d-sm-flex align-center py-0">
                                <span class="text-body-1 primary--text me-3">End:</span>
                                <v-row class="ma-0">
                                    <v-col cols="6" class="py-0 pe-1">
                                        <v-text-field
                                            v-model="endYear"
                                            label="Year"
                                            type="number"
                                            min="0"
                                            dense
                                            multiple
                                            :disabled="loading"
                                            :rules="rules.year"
                                            outlined
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="py-0 ps-0">
                                        <v-text-field
                                            v-model="endQuarter"
                                            label="Quarter"
                                            type="number"
                                            min="1"
                                            max="4"
                                            dense
                                            multiple
                                            :disabled="loading"
                                            :rules="rules.quarter"
                                            outlined
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>

                        <!-- date -->
                        <template v-if="divideBy === 1">
                            <!-- visits & samples date -->
                            <template v-if="$route.params.type !== 'sales'">
                                <!-- start date -->
                                <v-col sm="6" md="3" cols="12" class="py-0">
                                    <bee-date-picker
                                        v-model="startDate"
                                        input-label="Start date"
                                        elevation="2"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :disabled="loading"
                                        :rules="rules.required"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                    ></bee-date-picker>
                                </v-col>

                                <!-- end date -->
                                <v-col sm="6" md="3" cols="12" class="py-0">
                                    <bee-date-picker
                                        v-model="endDate"
                                        input-label="End date"
                                        elevation="2"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :disabled="loading"
                                        :rules="rules.required"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                    ></bee-date-picker>
                                </v-col>
                            </template>

                            <!-- sales date -->
                            <template v-else>
                                <v-col sm="3" cols="12" class="d-sm-flex align-center pt-0">
                                    <span class="text-body-1 primary--text me-3">Start:</span>
                                    <v-row style="margin-top: 2px">
                                        <v-col cols="6" class="py-0 pe-1">
                                            <v-text-field
                                                v-model="startSalesYear"
                                                label="Year"
                                                type="number"
                                                min="0"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.year"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="py-0 ps-0">
                                            <v-text-field
                                                v-model="startSalesMonth"
                                                label="Mouth"
                                                type="number"
                                                min="1"
                                                max="12"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.month"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field></v-col>
                                    </v-row>
                                </v-col>
                                <v-col sm="3" cols="12" class="d-sm-flex align-center pt-0">
                                    <span class="text-body-1 primary--text me-3">End:</span>
                                    <v-row style="margin-top: 2px">
                                        <v-col cols="6" class="py-0 pe-1">
                                            <v-text-field
                                                v-model="endSalesYear"
                                                label="Year"
                                                type="number"
                                                min="0"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.year"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="py-0 ps-0">
                                            <v-text-field
                                                v-model="endSalesMonth"
                                                label="Mouth"
                                                type="number"
                                                min="1"
                                                max="12"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.month"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </template>
                        </template>

                        <v-spacer />
                        <v-col cols="auto" align-self="center" class="py-0">
                            <v-btn
                                color="success"
                                :loading="loading"
                                :disabled="loading"
                                type="submit"
                                small
                                class="me-1"
                                @click="getChartData"
                            >
                                Create
                            </v-btn>
                            <v-btn
                                small
                                :disabled="loading"
                                @click="reset()"
                                color="alert-color white--text"
                            >
                                Reset
                            </v-btn>

                            <v-divider vertical class="mx-2" style="height: 24px"/>
                            <!-- Back to -->
                            <v-btn
                                small
                                :to="{ name: $route.params.type !== 'sales' ? $route.params.type : 'statistics'}"
                                color="grey white--text"
                            >
                                <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                                Back
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
        <!-- Wanted data -->
        <v-dialog v-if="0"  v-model="dialog" persistent width="650">
            <v-form ref="form" @submit.prevent="getChartData">
                <v-card flat>
                    <v-toolbar color="primary" dark flat class="text-uppercase justify-center">
                        <v-spacer/>
                        Chart data
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text class="mt-4">
                        <v-row>
                            <!-- city -->
                            <v-col sm="6" cols="12">
                                <v-autocomplete
                                    v-model="citiesIds"
                                    :items="cities"
                                    item-text="name"
                                    item-value="id"
                                    :return-object="false"
                                    label="City Name"
                                    dense
                                    multiple
                                    clearable
                                    :disabled="loading"
                                    :rules="$route.params.type === 'sales' ? rules.required : []"
                                    @change="setRegions"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @click="toggleCities"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ iconCities }}
                                                </v-icon>
                                            </v-list-item-action>
                                            
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ citiesIds.length - 1 }})
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <!-- region -->
                            <v-col
                            	v-if="['samples', 'visits'].includes($route.params.type)"
                            	sm="6"
                            	cols="12"
                            >
                                <v-autocomplete
                                    v-model="regionIds"
                                    :items="regionsAll"
                                    item-text="name"
                                    item-value="id"
                                    :return-object="false"
                                    label="Region"
                                    dense
                                    multiple
                                    clearable
                                    :disabled="loading"
                                    :rules="rules.required"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ regionIds.length - 1 }})
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <!-- product name -->
                            <v-col v-if="$route.params.type === 'sales'" sm="6" cols="12">
                                <v-autocomplete
                                    v-model="materialsIds"
                                    :items="materials"
                                    :item-text="getFullMaterialName"
                                    item-value="id"
                                    :return-object="false"
                                    label="product name"
                                    dense
                                    multiple
                                    clearable
                                    :disabled="loading"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @click="toggleMaterials"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ iconMaterials }}
                                                </v-icon>
                                            </v-list-item-action>
                                            
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ materialsIds.length - 1 }})
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <!-- radios -->
                            <v-col cols="12" class="d-flex flex-wrap align-center pb-0">
                                <span class="scondary--text text-body-1 me-3">
                                    Divide chart:
                                </span>
                                <v-radio-group
                                    v-model="divideBy"
                                    row
                                    hide-details
                                    class="mt-0"
                                    @change="resetDivideByInputs"
                                    :disabled="loading"
                                >
                                    <v-radio
                                        label="Quarterly"
                                        :value="0"
                                    ></v-radio>
                                    <v-radio
                                        label="By date"
                                        :value="1"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                            <!-- quarter -->
                            <template v-if="divideBy === 0">
                                <v-col sm="6" cols="12" class="d-sm-flex align-center py-0">
                                    <span class="text-body-1 primary--text me-3">Start:</span>
                                    <v-row style="margin-top: 2px">
                                        <v-col cols="6" class="py-0 pe-1">
                                            <v-text-field
                                                v-model="startYear"
                                                label="Year"
                                                type="number"
                                                min="0"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.year"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="py-0 ps-0">
                                            <v-text-field
                                                v-model="startQuarter"
                                                label="Quarter"
                                                type="number"
                                                min="1"
                                                max="4"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.quarter"
                                            ></v-text-field></v-col>
                                    </v-row>
                                </v-col>
                                <v-col sm="6" cols="12" class="d-sm-flex align-center py-0">
                                    <span class="text-body-1 primary--text me-3">End:</span>
                                    <v-row style="margin-top: 2px">
                                        <v-col cols="6" class="py-0 pe-1">
                                            <v-text-field
                                                v-model="endYear"
                                                label="Year"
                                                type="number"
                                                min="0"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.year"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="py-0 ps-0">
                                            <v-text-field
                                                v-model="endQuarter"
                                                label="Quarter"
                                                type="number"
                                                min="1"
                                                max="4"
                                                dense
                                                multiple
                                                :disabled="loading"
                                                :rules="rules.quarter"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </template>

                            <!-- date -->
                            <template v-if="divideBy === 1">
                                <!-- visits & samples date -->
                                <template v-if="$route.params.type !== 'sales'">
                                    <!-- start date -->
                                    <v-col sm="6" cols="12">
                                        <v-menu
                                            ref="menuOfStartDate"
                                            v-model="menuOfStartDate"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="startDate"
                                                    label="Start date"
                                                    prepend-icon="mdi-calendar"
                                                    dense
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="loading"
                                                    :rules="rules.required"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="startDate"
                                                :active-picker.sync="activePickerStartDate"
                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="saveStartDate"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <!-- end date -->
                                    <v-col sm="6" cols="12">
                                        <v-menu
                                            ref="menuOfEndDate"
                                            v-model="menuOfEndDate"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="endDate"
                                                    label="End date"
                                                    prepend-icon="mdi-calendar"
                                                    dense
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="loading"
                                                    :rules="rules.required"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="endDate"
                                                :active-picker.sync="activePickerEndDate"
                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="saveEndDate"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </template>

                                <!-- sales date -->
                                <template v-else>
                                    <v-col sm="6" cols="12" class="d-sm-flex align-center pt-0">
                                        <span class="text-body-1 primary--text me-3">Start:</span>
                                        <v-row style="margin-top: 2px">
                                            <v-col cols="6" class="py-0 pe-1">
                                                <v-text-field
                                                    v-model="startSalesYear"
                                                    label="Year"
                                                    type="number"
                                                    min="0"
                                                    dense
                                                    multiple
                                                    :disabled="loading"
                                                    :rules="rules.year"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0 ps-0">
                                                <v-text-field
                                                    v-model="startSalesMonth"
                                                    label="Mouth"
                                                    type="number"
                                                    min="1"
                                                    max="12"
                                                    dense
                                                    multiple
                                                    :disabled="loading"
                                                    :rules="rules.month"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="d-sm-flex align-center pt-0">
                                        <span class="text-body-1 primary--text me-3">End:</span>
                                        <v-row style="margin-top: 2px">
                                            <v-col cols="6" class="py-0 pe-1">
                                                <v-text-field
                                                    v-model="endSalesYear"
                                                    label="Year"
                                                    type="number"
                                                    min="0"
                                                    dense
                                                    multiple
                                                    :disabled="loading"
                                                    :rules="rules.year"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0 ps-0">
                                                <v-text-field
                                                    v-model="endSalesMonth"
                                                    label="Mouth"
                                                    type="number"
                                                    min="1"
                                                    max="12"
                                                    dense
                                                    multiple
                                                    :disabled="loading"
                                                    :rules="rules.month"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </template>
                            </template>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            text
                            color="success"
                            :loading="loading"
                            :disabled="loading"
                            @click="getChartData"
                            type="submit"
                        >
                            Create
                        </v-btn>
                        <v-btn
                            text
                            @click="reset()"
                            :disabled="loading"
                        >
                            Reset
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-container
            v-if="!datasets.length"
        	class="d-flex justify-center align-center chart-placeholder"
        	style="height: 400px"
        >
            <span class="text-h6 text--scondary" :class="{'error--text': isFailedCreation}">
                {{isFailedCreation ? 'Creation Failed, Please try create a new one.' : 'Please create a chart.'}}
            </span>
        </v-container>

        <!-- chart -->
        <bar-chart v-else :labels="labels" :datasets="datasets"></bar-chart>
    </div>
</template>

<script>
import BarChart from '../components/BarChart.vue'
import rules from '../validation rules'
import { mapState, mapGetters } from 'vuex'
import { entityType } from '@/helpers/enums'

export default {
    components: {
        BarChart
    },

    data() {
        return {
            searchRegion: null,
            searchCityId: null,
            // helpers
            loading: false,
            dialog: false,
            isFailedCreation: false,
            isToggleChartColor: false,
            rules,

            // start date
            activePickerStartDate: null,
            menuOfStartDate:false,

            // end date
            activePickerEndDate: null,
            menuOfEndDate:false,
            
            // data
            citiesIds: [],
            regionIds: [],
            materialsIds: [],
            divideBy: 0,
            startDate: null,
            endDate: null,

            startSalesYear: null,
            startSalesMonth: null,
            endSalesYear: null,
            endSalesMonth: null,

            startYear: window.moment().format('YYYY'),
            startQuarter: 1,
            endYear: window.moment().format('YYYY'),
            endQuarter: 4,

            labels: [],
            datasets: [],
            entityId: null,
            medicalRepId: null,
            materialFormId: null,
            materialName: null
        }
    },

    watch: {
        menuOfStartDate (val) {
            val && setTimeout(() => (this.activePickerStartDate = 'YEAR'))
        },

        menuOfEndDate (val) {
            val && setTimeout(() => (this.activePickerEndDate = 'YEAR'))
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            entitiesBrief: state => state.entities.entitiesBrief,
            medicalreps: state => state.medicalreps.medicalreps,
            regionsAll: state => state.regions.regionsAll,
            materials: state => state.materials.materials,
            entities: state => state.entities.entities,
            pharmaceuticalForm: state => state.materialForms.materialForms,
        }),
        ...mapGetters({
            getMaterialFormById: 'materialForms/getMaterialFormById',
        }),

        selectedAllCities () {
            return this.citiesIds.length === this.cities.length;
        },
        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllMaterials () {
            return this.materialsIds.length === this.materials.length;
        },
        iconMaterials () {
            if (this.selectedAllMaterials) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.citiesIds?.includes(c.cityId)).length;
        }
        
    },

    methods: {
        // FIXME remove testData
        // testData() {
        //     var arr = [];
        //     for (let i = 0; i < (Math.round(Math.random() * 1000) % 23); i++) {
        //         arr.push({
        //             value: Math.round(Math.random() * 100),
        //             date: `2022-${Math.round(Math.random() * 10)}-${Math.round(Math.random() * 10)}`,
        //             quarter: {
        //                 year: `202${(Math.round(Math.random() * 10) % 2) + 1}`,
        //                 num: Math.round(Math.random() * 100) % 4,
        //             }
        //         })
        //     }
        //     return arr;
        // },

        getChartData() {
            // if (this.$refs.form.validate()) {
                this.loading = true;
                this.isFailedCreation = false;
                this.isToggleChartColor = !this.isToggleChartColor;
                
                const labels = [];
                const data = [];

                let responseData;
                let responseBarValue;
                switch (this.$route.params.type) {
                    case 'sales': responseBarValue = 'quantity'; break;
                    case 'visits': responseBarValue = 'count'; break;
                    case 'samples': responseBarValue = 'count'; break;
                }

                this.$store.dispatch('statistics/fetchStatistics', {
                    type: this.$route.params.type,
                    fromDate: this.startDate,
                    toDate: this.endDate,
                    startSalesYear: this.startSalesYear,
                    startSalesMonth: this.startSalesMonth,
                    endSalesYear: this.endSalesYear,
                    endSalesMonth: this.endSalesMonth,
                    startYear: this.startYear,
                    startQuarter: this.startQuarter,
                    endYear: this.endYear,
                    endQuarter: this.endQuarter,
                    materialsIds: this.materialsIds,
                    regionIds: this.regionIds,
                    citiesIds: this.citiesIds,
                    entityId: this.entityId,
                    employeeId: this.medicalRepId
                })
                .then((data) => {
                    switch (this.$route.params.type) {
                        case 'sales': responseData = data.salesStatistics; break;
                        case 'visits': responseData = data.dailyVisitStatistics; break;
                        case 'samples': responseData = data.dailyMaterialTransactionsStatistics; break;
                    }
                })
                .then(() => {
                    if (this.divideBy === 0) {
                        responseData.forEach(item => {
                            labels.push(`${item.yearQuarter.year} - Q${item.yearQuarter.quarterNo}`);
                            data.push(item[responseBarValue]);
                        });
                    }
                    else if (this.divideBy === 1){
                        if (this.$route.params.type !== 'sales') {
                            responseData.forEach(item => {
                                labels.push(window.moment(item.date).format('YYYY-MM-DD'));
                                data.push(item[responseBarValue]);
                            });
                        }
                        else {
                            responseData.forEach(item => {
                                const month = item.yearMonth.month;
                                labels.push(`${item.yearMonth.year}-${month <= 9 ? '0' + month : month}`);
                                data.push(item[responseBarValue]);
                            });
                        }
                    }

                    this.labels = labels;
                    this.datasets = [
                        {
                            label:
                                this.$route.params.type !== 'sales'
                                ? this.$route.params.type + ' count'
                                : 'sales quantity',
                            backgroundColor: ['#13a689', '#083866'][this.isToggleChartColor ? 0 : 1],
                            data
                        },
                    ];
                    // this.reset();
                })
                .catch(() => {
                    this.isFailedCreation = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                })
            // }
        },

        reset() {
            this.citiesIds = [];
            this.regionIds = [];
            this.materialsIds = [];
            this.startDate = null;
            this.endDate = null;
            this.startYear = null;
            this.startQuarter = null;
            this.endYear = null;
            this.endQuarter = null;
            this.entityId = null;
            this.medicalRepId = null;
            this.$refs.form.resetValidation()

            if (this.divideBy === 0) {
                this.startYear = Number(window.moment().format('YYYY'));
                this.startQuarter = 1;
                this.endYear = Number(window.moment().format('YYYY'));
                this.endQuarter = 4;
            }
            else if (this.divideBy === 1) {
                this.startDate = window.moment().format('YYYY') + '-01-01';
                this.endDate = window.moment().format('YYYY') + '-12-31';
                this.startSalesYear = Number(window.moment().format('YYYY'));
                this.startSalesMonth = 1;
                this.endSalesYear = Number(window.moment().format('YYYY'));
                this.endSalesMonth = 12;
            }

            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            })
        },

        getFullMaterialName(material) {
            var details = [
                this.getMaterialFormById(material.materialFormId).name,
                material.dose,
                material.strength
            ];

            return `${material.name} (${details.filter(c => c != null).join(' ')})`;
        },

        setRegions() {
            if (['samples', 'visits'].includes(this.$route.params.type)) {
                this.regionIds = [];
                this.regionsAll.forEach(region => {
                    if (this.citiesIds.includes(region.cityId)) {
                        this.regionIds.push(region.id);
                    }
                });   
            }
        },

        resetDivideByInputs() {
            if (this.divideBy === 0) {
                this.startDate = null;
                this.endDate = null;
                this.startSalesYear = null;
                this.startSalesMonth = null;
                this.endSalesYear = null;
                this.endSalesMonth = null;

                this.startYear = Number(window.moment().format('YYYY'));
                this.startQuarter = 1;
                this.endYear = Number(window.moment().format('YYYY'));
                this.endQuarter = 4;
            }
            else if (this.divideBy === 1) {
                this.startYear = null;
                this.startQuarter = null;
                this.endYear = null;
                this.endQuarter = null;

                if (this.$route.params.type !== 'sales') {
                    this.startDate = window.moment().format('YYYY') + '-01-01';
                    this.endDate = window.moment().format('YYYY') + '-12-31';
                }
                else {
                    this.startSalesYear = Number(window.moment().format('YYYY'));
                    this.startSalesMonth = 1;
                    this.endSalesYear = Number(window.moment().format('YYYY'));
                    this.endSalesMonth = 12;
                }
            }
        },

        // select all inputs
        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.citiesIds = []
                } 
                else {
                    this.citiesIds = this.cities.map(c => c.id);
                    // this.setRegions();
                }
            })
        },

        toggleMaterials () {
            this.$nextTick(() => {
                if (this.selectedAllMaterials) {
                    this.materialsIds = []
                } 
                else {
                    this.materialsIds = this.materials.map(c => c.id);
                }
            })
        },

        // dates
        saveStartDate (date) {
            this.$refs.menuOfStartDate.save(date);
        },
        saveEndDate (date) {
            this.$refs.menuOfEndDate.save(date);
        },

        toggleRegion() {
            this.regionIds = []
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.citiesIds.includes(c.cityId)).map(c => c.id)
                }
            })
        },
    },

    created() {
        this.loading = true;
        const promises = [
            this.$store.dispatch('entities/fetchAllBrief', { type: entityType.entityId, regionIds: [] }),
            this.$store.dispatch('medicalreps/fetchAll', true),
            !this.pharmaceuticalForm.length ? this.$store.dispatch('materialForms/fetchAll') : null
        ];
        
        if (this.cities.length !== null) {
            promises.push(
                this.$store.dispatch('cities/fetchCityMine')
                .then((data) => {
                    if (['samples', 'visits'].includes(this.$route.params.type)) {
                        this.$store.dispatch('regions/fetchAllRegions', { cities: data })
                    }
                }),

                // this.$store.dispatch('entities/fetchByFilter', { type: 1 })
            //     type: this.entityTypeForCurrentTab,
            //     name: this.name,
            //     workloadId: this.workload,
            //     specialtyId: this.specialtyId,
            //     regionIds: this.regionIds,
            //     page: page, 
            //     perPage: itemsPerPage,
            //     nonDeleted: true,
            // }).then(({ data, total }) => {
            //     if (this.needToResetItems) { 
            //         this.$refs.table.resetShownRows();
            //         this.needToResetItems = false;
            //     }
                
            //     this.$refs.table.addScrollItems(data);
            //     this.serverItemsLength = total;
            // }).finally(() => {
            //     this.loading = false;
            // })
            )
        }
        else if (this.regionsAll.length !== 0 && ['samples', 'visits'].includes(this.$route.params.type)) {
            this.$store.dispatch('regions/fetchAllRegions', { cities: this.cities })
        }
        else if (
            ['visits'].includes(this.$route.params.type)    
            ) 
        {
            this.entityId = this.$route.query.doctorId
            this.medicalRepId = this.$route.query.medicalRepId
            this.divideBy = this.$route.query.divideBy
            this.regionIds = this.$route.query.regionId
            this.citiesIds = this.$route.query.cityId
            this.startDate = this.$route.query.startDate
            this.endDate = this.$route.query.endDate
            this.startYear = this.$route.query.startYear
            this.startQuarter = this.$route.query.startQuarter
            this.endYear = this.$route.query.endYear
            this.endQuarter = this.$route.query.endQuarter

            this.getChartData()
        }
        if (!this.materials.length && this.$route.params.type === 'sales'){
            promises.push(this.$store.dispatch('materials/fetchAll'));
            promises.push(this.$store.dispatch('materialForms/fetchAll'));
        }

        Promise.all(promises)
        .finally(() => {
            this.loading = false;
        })
    },

    metaInfo() {
        return {
            title: this.$route.params.type[0].toUpperCase() + this.$route.params.type.slice(1) + ' statistics',
            titleTemplate: '%s | Octopharma'
        }
    }
}
</script>

<style scoped>
.chart-placeholder {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}
</style>