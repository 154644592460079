import * as types from '../mutation-types';

export default {
    namespaced: true,

    state: {
        vacations: []
    },

    actions: {
        fetchCount ({ commit }, { employeeId }) {
            return window.axios.get(`/Leaves/${employeeId}`).then(({ data }) => {
                return data
            })
        },

        create ({ commit }, { date, reason, type, days }) {
            return window.axios.post('/Requests/DaysOff', { date, reason, type, days }).then(({ data }) => {
                return data
            })
        }
     },

    mutations: {

    },

    getters: {
        
    }
}