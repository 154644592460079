<template>
    <div>
        <div class="d-flex flex-wrap align-center ">
            <h2 class="text-h5 primary--text text-capitalize">Sales</h2>
            
            <v-spacer/>

            <div class="d-flex flex-wrap">
                <!-- add new sale -->
                <v-btn
                    small
                    @click="addSaleDialog()"
                    color="light-blue white--text"
                >
                    <v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    Add new sales entry
                </v-btn>

                <!-- <v-spacer/> -->

                <!-- warehouse managment -->
                <v-btn
                	small
                	color="light-blue white--text"
                	:to="{ name:'warehouses' }"
                    class="mx-2"
                >
                    warehouses management
                </v-btn>
            </div>  

            <v-divider vertical class="me-2" />

            <div class="my-sm-0 my-5">
                <!-- Import Excel -->
                <v-btn small color="green darken-1 white--text" @click="$refs.ImportDialog.import('sales')">
                    <v-icon class="me-1">
                        mdi-file-table-outline
                    </v-icon>
                    Import from Excel file
                </v-btn>
            </div>
        </div>
        
        <!-- sale-dialog decleared here to use it as refs -->
        <!-- <sale-dialog ref="SaleDialog" @on-save="
        "></sale-dialog> -->
        <v-dialog
            transition="dialog-transition"
            max-width="700"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form
                  @submit.prevent="submit"
                  ref="form"
                  class="supervisor-report-form"
                >
                    <v-card>
                        <div class="d-flex justify-end py-1 background-title-card ">
                            <v-card-title
                                class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                            >
                                {{ title }}
                            </v-card-title>
                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false; $refs.form.resetValidation()">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-5 px-6">
                            <!-- dialog content -->
                            <v-row>
                                <!-- Material -->
                                <v-col sm="6" cols="12" class="pa-1">
                                    <v-autocomplete
                                        v-model="materialId"
                                        :items="materials"
                                        :item-text="getFullMaterialName"
                                        item-value="id"
                                        :return-object="false"
                                        label="Material"
                                        :rules="rules.required"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- date -->
                                <v-col sm="6" cols="12" class="pa-1">
                                    <bee-date-picker
                                        v-model="date"
                                        input-label="Date"
                                        elevation="2"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1940-01-01"
                                        :disabled="loading"
                                        :rules="rules.required"
                                    ></bee-date-picker>
                                </v-col>

                                <!-- city -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-autocomplete
                                        v-model="cityId"
                                        :items="cities"
                                        item-text="name"
                                        item-value="id"
                                        :return-object="false"
                                        label="City"
                                        :rules="rules.required"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <!-- warehouse -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-autocomplete
                                        v-model="warehouseId"
                                        :items="warehouses"
                                        item-text="name"
                                        item-value="id"
                                        :return-object="false"
                                        label="Warehouse"
                                        :rules="rules.required"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- quantity -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-text-field
                                        v-model.number="quantity"
                                        label="Quantity"
                                        type="number"
                                        min="0"
                                        :rules="rules.number"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- gifts -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-text-field
                                        v-model.number="gifts"
                                        label="Gifts"
                                        type="number"
                                        min="0"
                                        :rules="rules.number"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- inventory -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-text-field
                                        v-model.number="inventory"
                                        label="Inventory"
                                        type="number"
                                        min="0"
                                        :rules="rules.number"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- order -->
                                <v-col sm="4" cols="12" class="pa-1">
                                    <v-text-field
                                        v-model.number="order"
                                        label="Order"
                                        type="number"
                                        min="0"
                                        :rules="rules.number"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- package capacity -->
                                <v-col sm="12" cols="12" class="pa-1">
                                    <v-text-field
                                        v-model.number="packageCapacity"
                                        label="Package Capacity"
                                        type="number"
                                        min="0"
                                        :rules="rules.number"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- notes -->
                                <v-col cols="12" class="pa-1">
                                    <v-textarea
                                        v-model="notes"
                                        rows="2"
                                        label="Notes"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-textarea>  
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                color="success white--text"
                                elevation="0"
                                text
                            >
                                save
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="0"
                                text
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <v-snackbar
          v-model="snackbar"
          transition="slide-y-reverse-transition"
          color="primary"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success lighten-2"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- import-dialog decleared here to use it as refs -->
        <import-dialog ref="ImportDialog" @imported="fetchSales"></import-dialog>

        <!-- delete-dialog decleared here to use it as refs -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="filter"
            success-message='Data is deleted'
        >
            <template v-slot:default>
                <p class="mb-2">
                    Are you sure you want to <span class="red--text">delete</span> the sale ?
                </p>
            </template>
        </delete-dialog>

        <!-- filter -->
        <v-row class="mt-1 mx-1" align="center">
            <!-- material name -->
            <v-col md="3" sm="6" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="materialIds"
                    :items="materials"
                    :item-text="getFullMaterialName"
                    item-value="id"
                    :return-object="false"
                    label="Material Name"
                    
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ materialIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>

            <!-- city name -->
            <v-col md="3" sm="6" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="cityIds"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="City"
                    
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ cityIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>

            <!-- warehouses -->
            <v-col md="3" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="warehouseIds"
                    :items="warehouses"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="Warehouse"
                    
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading" 
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ warehouseIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>

        <!-- date -->
        <v-row class="mx-1 mb-1">
            <!-- start date -->
            <v-col md="3" sm="6" cols="12" class="pa-1">
                <bee-date-picker
                    v-model="startDate"
                    input-label="Start date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-prop="{
                        outlined: true,
                        dense: true
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- end date -->
            <v-col md="3" sm="6" cols="12" class="pa-1">
             <bee-date-picker
                    v-model="endDate"
                    input-label="End date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-prop="{
                        outlined: true,
                        dense: true
                    }"
                ></bee-date-picker>
            </v-col>
            <v-spacer />
            <v-col class="d-flex justify-end">
                <v-btn
                    color="success"
                    class="px-sm-4 mx-2"
                    :loading="loading"
                    :disabled="loading || isAllFieldsEmpty"
                    @click="isFiltered=true; filter();"
                    small
                    depressed
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>
                <v-btn
                    color="alert-color white--text"
                    class="px-sm-8"
                    @click="fetchSales"
                    :disabled="!isFiltered || loading"
                    small
                >
                    Reset
                </v-btn>
            </v-col>
        </v-row>

        <!-- data table -->
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? sales : []"
            :loading="loading"
            zebra
            dense
            pagination-on-scroll
            fixed-header
            :height="$vuetify.breakpoint.height - 190"
        >
            <!-- actions -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex">
                    <tooltip text="Edit sale" top>
                        <!-- activate button -->
                        <v-btn
                            icon
                            small
                            @click="saleId = item.id; editSaleDialog(item.id)"
                            :disabled="editLoading"
                            :loading="editLoading && item.id === saleId"
                            color="edit-color"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip text="Delete sale" top>
                        <!-- activate button -->
                        <v-btn
                            icon
                            small
                            @click="activeDeleteDialog(item.id, 'sales')"
                            :disabled="editLoading"
                            color="alert-color"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import SaleDialog from '../../components/SaleDialog'
import DeleteDialog from '../../components/DeleteDialog'
import ImportDialog from '../../components/ImportDialog'
import Tooltip from '../../components/Tooltip'
import rules from '../../validation rules'

export default {
    data(){
        return {
            // helpers
            saleId: null,
            loading: false,
            filterLoading: false,
            editLoading: false,
            isFiltered: false,

            // option
            options: null,
            totalSales: 3,
            
            // filter data
            materialIds: [],
            cityIds: [],
            warehouseIds: [],
            startDate: null,
            endDate: null,
            rules,

            // sale dialog
            // date
            activePickerDate: null,
            menuOfDate:false,

            // helpers
            title: null,
            snackbar: false,
            message: null,

            // data
            materialId: null,
            date: null,
            cityId: null,
            warehouseId: null,
            quantity: null,
            gifts: null,
            inventory: null,
            order: null,
            packageCapacity: null,
            notes: null,
        }
    },

    components:{
        // SaleDialog,
        DeleteDialog,
        ImportDialog,
        Tooltip
    },
    
    watch: {
        options(){
            // this.filter();
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                this.materialIds.length === 0 && this.cityIds.length === 0 && this.warehouseIds.length === 0 && !this.startDate && !this.endDate
            );
        },

        ...mapState({
            cities: state => state.cities.cities,
            materials: state => state.materials.materials,
            warehouses: state => state.warehouses.warehouses,
            sales: state => state.sales.sales,
            salesFiltered: state => state.sales.salesFiltered,
        }),

        ...mapGetters({
            getCityById: 'cities/getCityById',
            getMaterialById: 'materials/getMaterialById',
            getWarehouseById: 'warehouses/getWarehouseById',
            getMaterialFormById: 'materialForms/getMaterialFormById',
            getSaleById: 'sales/getSaleById',
        }),

        headers() {
            return [
                { 
                    text: 'Date', 
                    align: 'start', 
                    sortable: true, 
                    value: 'date', 
                    setValueExpr: val => this.moment(val),
                    class:'primary--text', 
                    cellClass:'primary--text fixed-width-date'
                },
                { 
                    text: 'City', 
                    value: 'cityId',
                    setValueExpr: val => this.dig('getCityById', val, 'name'),
                    align: 'center', 
                    sortable: false, 
                },
                {
                    text: 'Warehouse', 
                    value: 'warehouseId', 
                    setValueExpr: val => this.dig('getWarehouseById', val, 'name'),
                    align: 'center', 
                    sortable: false,
                },
                { 
                    text: 'Material', 
                    value: 'materialId', 
                    setValueExpr: val => this.getFullMaterialName(this.getMaterialById(val)),
                    align: 'center', 
                    sortable: false, 
                    cellClass:'fixed-width-material', 
                },
                { 
                    text: 'Quantity', 
                    value: 'quantity',
                    align: 'center', 
                    sortable: false, 
                },
                { text: 'Gifts', value: 'gifts', align: 'center', sortable: false, },
                { text: 'Inventory', value: 'inventory', align: 'center', sortable: false, },
                { text: 'Order', value: 'order', align: 'center', sortable: false, },
                { text: 'Package Capacity', value: 'packageCapacity', align: 'center', sortable: false, },
                { text: 'Notes', value: 'notes', align: 'center', sortable: false, cellClass:'fixed-width-notes' },
                { text: 'Actions', name: 'actions', value: 'actions', align: 'center', sortable: false, },
            ]
        }
    },

    methods:{
        getFullMaterialName(material) {
            // check if material has no value
            if (!material) return null;

            var details = [
                this.getMaterialFormById(material.materialFormId).name,
                material.dose,
                material.strength
            ];

            return `${material.name} (${details.filter(c => c != null).join(' ')})`;
        },

        // filter funcitons
        filter(){
            this.loading = true;
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            
            this.$store.dispatch('sales/fetchAll', {
                materialIds: this.materialIds,
                cityIds: this.cityIds,
                warehouseIds: this.warehouseIds,
                fromDate: this.startDate,
                toDate: this.endDate,
                returnSums: false
            })
            .finally(() => {
                this.loading = false;
            })
        },

        clearFilter(){
            this.materialIds = [];
            this.cityIds = [];
            this.warehouseIds = [];
            this.startDate = null;
            this.endDate = null;

            this.isFiltered = false;
        },

        fetchSales(){
            this.clearFilter();
            this.filter();
        },

        // table funtions
        moment(date){
            return window.moment(date).format('YYYY-MM');
        },

        // Find data funcitons
        dig(getterName, id, prop){
            var object = this[getterName](id);
            if (prop === 'cityName') {
                var array = object;
                return array.join(', ');
            }
            return object ? object[prop] : null;
        },

        // dialog functions
        addSaleDialog(){
            this.reset();
            this.title = 'add new sale entry';
            this.$refs.dialog.isActive = true;
        },

        editSaleDialog(id){
            this.editLoading = true;
            var data = this.getSaleById(id);

            // replace setTimeout with $store.dispatch
            setTimeout(() => {
            //    this.editSaleDialog(data);
                this.reset();
                this.saleId = data.id;
                this.title = 'edit sale';
                this.fillData(data);
                this.$refs.dialog.isActive = true;
                this.editLoading = false;
            }, 500);
        },

        // delete dialog functions
        activeDeleteDialog(id, moduleType){
            this.$refs.deleteDialog.activeDeleteDialog(id, moduleType);
        },

        // editSaleDialog(data){
        //     this.reset();
        //     this.saleId = data.id;
        //     this.title = 'edit sale';
        //     this.fillData(data);
        //     this.$refs.dialog.isActive = true;
        // },

        reset(){
            if (this.materialId !== null) this.$refs.form.resetValidation();

            this.materialId = null;
            this.date = null;
            this.cityId = null;
            this.warehouseId = null;
            this.quantity = null;
            // this.sales = null;
            this.gifts = null;
            this.inventory = null;
            this.order = null;
            this.packageCapacity = null;
            this.notes = null;

            this.saleId = null;
        },

        fillData(data){
            this.materialId = data.materialId;
            this.date = window.moment(data.date).format('YYYY-MM-DD');
            this.cityId = data.cityId;
            this.warehouseId = data.warehouseId;
            this.quantity = data.quantity;
            this.gifts = data.gifts;
            this.inventory = data.inventory;
            this.order = data.order;
            this.packageCapacity = data.packageCapacity;
            this.notes = data.notes;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                // create sale
                if (this.saleId === null){
                    this.$store.dispatch('sales/create', {
                        materialId: this.materialId,
                        date: this.date,
                        cityId: this.cityId,
                        warehouseId: this.warehouseId,
                        quantity: this.quantity,
                        gifts: this.gifts,
                        inventory: this.inventory,
                        order: this.order,
                        packageCapacity: this.packageCapacity,
                        notes: this.notes,
                    })
                    .then(() => {
                        this.message = 'Sale is added';
                        // this.$emit('on-save');
                        this.filter()
                        this.$refs.dialog.isActive = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                   
                    this.message = 'Sale is added';
                }
                // edit sale
                else {
                    this.$store.dispatch('sales/update', {
                        id: this.saleId,
                        materialId: this.materialId,
                        date: this.date,
                        cityId: this.cityId,
                        warehouseId: this.warehouseId,
                        quantity: this.quantity,
                        gifts: this.gifts,
                        inventory: this.inventory,
                        order: this.order,
                        packageCapacity: this.packageCapacity,
                        notes: this.notes,
                    })
                    .then(() => {
                        this.message = 'Sale is edited';
                        // this.$emit('on-save');
                        this.filter()
                        this.$refs.dialog.isActive = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.snackbar = true;
                    })
                   
                    this.message = 'Sale is edited'
                }

                // replace setTimeout with $store.dispatch
                setTimeout(() => {
                    this.loading = false;
                    this.snackbar = true;
                    this.$refs.dialog.isActive = false;
                }, 1000);
            }
        },
    },

    mounted(){
        this.loading = true;
        this.filterLoading = true;

        Promise.all([
            this.$store.dispatch('cities/fetchCityMine'),
            this.$store.dispatch('materials/fetchAll'),
            this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('warehouses/fetchAll'),
            this.$store.dispatch('sales/fetchAll', {
                materialIds: this.materialIds,
                cityIds: this.cityIds,
                warehouseIds: this.warehouseIds,
                fromDate: this.startDate,
                toDate: this.endDate,
                returnSums: false
            }),
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    metaInfo: {
        title: 'Sales',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>