<template>
	<div class="mt-2">
		<!-- dialog -->
		<v-dialog width="400" v-model="dialog">
			<v-card>
				<div class="d-flex primary py-1">
					<v-card-title
						class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
					>
						{{ $t('edit') }}
					</v-card-title>

					<!-- close button -->
					<v-btn icon @click="dialog = false; $refs.form.resetValidation()">
						<v-icon color="white">
							mdi-close-circle-outline
						</v-icon>
					</v-btn>
				</div>

				<v-card-text class="mt-4">
					<v-row>
						<v-col cols="12" sm="12" class="py-1">
							<v-autocomplete
								v-model="sampleData.accountId"
								:items="allAccountsBrief.filter(c => c.id !== employeeId)"
								item-value="accountId"
								item-text="name"
								:disabled="submitLoading"
								:label="$t('inputs.account')"
								outlined
								dense
								hide-details
							></v-autocomplete>
						</v-col>

						<v-col cols="12" sm="6" class="py-1">
							<v-autocomplete
								v-model="sampleData.materialsAmount.materialId"
								:items="myMaterials"
								item-value="id"
								:item-text="(item) => `${item.remaining} - ${item.name}`"
								:label="$t('inputs.item')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							></v-autocomplete>
						</v-col>

						<v-col cols="12" sm="6" class="py-1">
							<v-text-field
								v-model.number="sampleData.materialsAmount.amount"
								:label="$t('inputs.amount')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							/>
						</v-col>

						<!-- <v-col cols="12" sm="6" class="py-1">
							<v-text-field
								v-model.number="sampleData.materialsAmount.sellPrice"
								:label="$t('inputs.price')"
								outlined
								dense
								hide-details
								:disabled="submitLoading || this.entityTab === 0"
							/>
						</v-col> -->

						<v-col cols="12" sm="12" class="py-1">
							<v-text-field
								v-model="sampleData.description"
								:label="$t('inputs.notes')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn color="success" small @click="editItem()" :loading="submitLoading">
						{{$t('save')}}
					</v-btn>

					<v-btn color="alert-color white--text" small @click="dialog = false" :disabled="submitLoading">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
            ref="deleteDialog"
            success-message='Data is deleted'
            @on-delete="getStatement"
        >
			Are you sure you want to delete this transfer?
        </delete-dialog>
		<v-row dense align="center">
			<!-- filter -->
			<v-col order-sm="0" order="1" md="3" sm="4" cols="12">
				<bee-date-input
					v-model="date"
					:label="$t('inputs.date')"
					dense
					:disabled="loading"
					input-format-order="DD-MM-YYYY"
					hide-details
					append-icon="mdi-menu-right"
					prepend-icon="mdi-menu-left"
					@click:append="
                        needToResetShownRows = true;
                        date = moment(date).add(1, 'day').format('YYYY-MM-DD');
                        getJournal(null, null);
                    "
					@click:prepend="
                        needToResetShownRows = true;
                        date = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
                        getJournal(null, null);
                    "
				></bee-date-input>
			</v-col>

			<v-col v-if="entityTab === 1" md="2">
				<v-autocomplete 
					v-model.number="entity"
					:items="entityTypeArray"
					item-text="name"
					item-value="id"
					label="Type"
					outlined
					dense
					hide-details
					:disabled="loading || submitLoading"
					:loading="loading || submitLoading"
				/>
			</v-col>

			<!-- entityTab === 1 city region filter -->
            <v-col v-if="0" lg="3" md="5" sm="7" cols="12" class="pa-1">
                <v-row class="pa-3 pa-3 d-flex justify-space-between">
                    <!-- cities -->
                    <v-col cols="5" class="pa-0 pe-1 d-flex">
                        <v-icon class="me-2" size="28"> mdi-map-marker-multiple </v-icon>
                        <v-autocomplete
                            v-model="cityId"
                            :items="cities"
                            item-text="name"
                            item-value="id"
                            label="City"
                            dense
                            hide-details
                            append-icon
                            @change="setRegions(); searchCityId = null"
                            :disabled="loading"
                            outlined
                            multiple
                            :search-input.sync="searchCityId"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggleCities"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ iconCities }}
                                        </v-icon>
                                    </v-list-item-action>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>

                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ cityId.length - 1 }})
                                </span>
                            </template>   
                        </v-autocomplete>
                    </v-col>

                    <!-- region -->
                    <v-col v-if="0" cols="7" class="pa-0">
                        <v-autocomplete
                            v-model="regionIds"
                            :items="regionsAll.filter(c => cityId.includes(c.cityId))"
                            item-text="name"
                            item-value="id"
                            label="Regions"
                            dense
                            multiple
                            clearable
                            hide-details
                            :loading="regionsLoading"
                            :disabled="loading || regionsLoading || cityId === []"
                            outlined
                            :search-input.sync="searchRegionId"
                            @change="searchRegionId = null"
                        >
                            <template v-slot:prepend-item v-if="regions">
                                <v-list-item ripple @click="toggleReignsFilterItems">
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                >
                                    (+{{ regionIds.length - 1 }})
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>

			<v-col v-if="entityTab === 1" class="pt-0 pb-1">
				<v-btn
					@click="search()"
					color="success white--text"
					small
					:disabled="loading"
					:loading="loading || submitLoading"
				>
					{{$t('search')}} 
				</v-btn>
			</v-col>
			<v-spacer/>

			<!-- remaining -->
			<v-col sm="auto" cols="12" class="text-end">
				<v-btn
					v-if="isSuperAdmin || permissionsUser.MaterialTransactions !== roleName.nothing"
					small
					:to="{ 
						name: 'supervisor-remaining-samples',
						query: { from: 'delivery' }
					}"
					color="light-blue white--text"
				>
					{{$t('JournalDeliveryOfSamples.remaining-samples')}}
				</v-btn>
			</v-col>
		</v-row>

		<!-- type tabs -->
		<v-tabs v-model="entityTab" height="40" @change="existType = false; resetFilter(); updateTableDueTab()">
			<v-tab
				v-for="item in types"
				:key="item.id"
				:disabled="loading"
			>
				{{item.name}}
			</v-tab>
		</v-tabs>
		<v-divider class="mb-2"/>

		<!-- deliver / receive tabs -->
		<v-tabs
			v-if="entityTab === 0"
			v-model="tab"
			height="30"
			active-class="secondary lighten-1 white--text overflow-hidden rounded-t"
			@change="needToResetShownRows = true; getJournal(null, null); addTransactionToEntity = false"
		>
			<v-tab :disabled="loading" @click="getMaterialByAccountd()">
				{{$t('JournalDeliveryOfSamples.deliver')}}
			</v-tab>
			<v-tab :disabled="loading">{{$t('JournalDeliveryOfSamples.receive')}}</v-tab>
			<v-tabs-slider color="secondary lighten-3"></v-tabs-slider>
		</v-tabs>
		
		<!-- table -->
     	<bee-handy-smart-table
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="[]"
			:show-add-row="(entityTab === 1 && addTransactionToEntity === true)||((entityTab !== 1 || existType) && permissionsUser.MaterialTransactions !== roleName.read )|| (isSuperAdmin && entityTab !== 1 || existType)"
			:loading="loading"
			:items-per-page="50"
			hide-default-footer 
			dense
			zebra
			:disable-input-fields="loading"
            fixed-header
            :height="((entityTab !== 1 || existType) && permissionsUser.MaterialTransactions !== roleName.read )|| (isSuperAdmin && entityTab !== 1 || existType) ? $vuetify.breakpoint.height - ($vuetify.breakpoint.smAndUp ? 210 : 250) : '70' "
            :options-scroll.sync="scrollOptions"
            pagination-on-scroll
            pagination-on-scroll-server-items
			hide-inputs-details
            :server-items-length="serverItemsLength"
            :pagination-on-scroll-auto-reset-shown="false"
			@keypress.enter="addItem"
			@sheet-add-clicked="addItem"
            @pagination-on-scroll:reach-last-row="getJournal"
			@change-accountId="getMaterialByAccount()"
		>
			<!-- <template v-slot:item.accountId="{ item }">
				{{getAccount(item)}}
			</template> -->

            <!-- accounts -->
			<template v-slot:input.accountId="{ on, attr }">
				<v-autocomplete
					:items="allAccountsBrief.filter(c => c.id !== employeeId)"
					:item-value="entityTab !== 1 ? 'accountId' : 'name'"
					item-text="name"
					v-on="on"
					v-bind="attr"
					:readonly="materialLoading"
				></v-autocomplete>
			</template>

			<!-- product name -->
			<template v-slot:input.materialId="{ on, attr }">
				<v-autocomplete
					:items="myMaterials"
					item-value="id"
					:item-text="(item) =>`${item.remaining} - ${item.name} `"
					v-on="on"
					v-bind="attr"
					:loading="materialLoading"
					:disabled="materialLoading"
				></v-autocomplete>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-btn
					color="success"
					small
					icon
					@click="dialog = true; myItem = item; isEdit = true"
					:disabled="materialLoading"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<v-btn
					color="alert-color"
					small
					icon
					@click="$refs.deleteDialog.activeDeleteDialog(item.id, 'materialTransactions')"
					:disabled="materialLoading"
				>
					<v-icon> mdi-delete </v-icon>
				</v-btn>
            </template>
       	</bee-handy-smart-table>

		<div
			v-if="!existType && entityTab === 1"
			class="d-flex flex-column justify-center align-center"
            :style="{ height: `${$vuetify.breakpoint.height - 300}px` }"
		>
			<template >
				<v-icon size="60" class="mb-1">mdi-invoice-list</v-icon>
				<span class="text-h6">{{$t('SamplesStatement.please-select-a-filter')}}</span>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
import { roleName, entityTypeArray, entityType } from '@/helpers/enums'
import moment from 'moment'
import rules from '@/validation rules'

export default {
	name: 'JournalDeliveryOfSamples',
	components: {
		DeleteDialog
	},
	watch: {
		dialog (val) {
			if (val) {
				this.sampleData.materialsAmount.materialId = this.myItem.materialId
				this.sampleData.accountId = this.myItem.accountId
				this.sampleData.materialsAmount.amount = this.myItem.in !== null ? this.myItem.in.amount : this.myItem.out.amount
				this.sampleData.description = this.myItem.description
				this.itemId = this.myItem.id
				this.sampleData.date = this.myItem.date
				this.sampleData.type = this.myItem.transactionType
				// this.sampleData.materialsAmount.sellPrice = this.myItem.sellPrice
			} else {
				this.sampleData.materialsAmount.materialId = null
				this.sampleData.accountId = null
				this.sampleData.materialsAmount.amount = null
				this.sampleData.description = null
				this.itemId = null
				this.sampleData.date = null
				this.sampleData.type = null
				// this.sampleData.materialsAmount.sellPrice = null
				this.isEdit = false
			}
		},

		entity(val) {
			if (val === null) {
				this.existType = false
			}
		},

		// tab(val) {
		// 	if (val === 0) {
		// 		console.log()
		// 		this.getMaterialByAccount(this.employeeId)
		// 	}
		// }
	},

    data () {
        return {
			cityId: [],
            searchCityId: null,
			regionIds: [],
			regionsLoading: false,
			searchRegionId: null,

			entityTypeArray,
			dialog: false,
			tab: 0,
			entityTab: 0,
			roleName,
            loading: false,
            tableInputs: {},

			date: this.moment().format('YYYY-MM-DD'),
			types: [
                { id: 0, name: this.$t('tabs.staff') },
                { id: 1, name: this.$t('tabs.customers') },
                { id: 2, name: this.$t('tabs.external') }
            ],
			serverItemsLength: 0,
			scrollOptions: {},

			briefAccounts: [
                [], // for staff
                [], // for customers
                [], // for external
            ],
			
			itemId: null,
			sampleData:{
                materialsAmount:{
                    materialId: null,
                    amount: null,
					// sellPrice: 0,
                },
                accountId: null,
                date: null,
                description: null,
                type: null
            },

			submitLoading: false,
			entity: null,
			existType: false,
			materialLoading: false,
			addTransactionToEntity: false
        }
    },
    computed: {
        ...mapState({
            supervisors: state => state.supervisors.supervisors,
            materials: state => state.materials.materials,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			permissionsUser: state => state.auth.youPermissions,
			employeeId: state => state.auth.userData.employeeId,
			myMaterials: state => state.materials.myMaterials,
			cities: state => state.cities.cities,
			regionsAll: state => state.regions.regionsAll,
			regions: state => state.regions.regions,
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getSupervisorById: 'supervisors/getSupervisorById',
			getAccountById: 'accounts/getAccountById'
        }),
		
        headers () {
            const headers = [
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.myMaterials?.find(c => c.id === val)?.name,
                    type: 'string'
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
					inputRules: rules.IntNumber,
                    type: 'number'
                },
				{
                    text: this.$t('headers.notes'),
                    name: 'description',
                    value: 'description',
                    type: 'string'
                },
				{
                    text: this.$t('headers.deliver-date'),
                    name: 'date',
                    value: 'date',
                    type: 'date',
                    width: "20%"
                },
				{
					text: '',
                    name: 'actions',
                    value: 'actions',
                    width: "10%",
                    noInput: true,
                    noNotAvailableText: true,
                    sortable: false
				}
            ]
			if (this.entityTab !== 1){
				headers.unshift({
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
                    setValueExpr: val => this.allAccountsBrief?.find(c => c.accountId === val)?.name,
                    type: 'string'
				})
			} else {
				headers.unshift({
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountName',
                    // setValueExpr: val => this.accounts?.find(c => c.accountId === val)?.name,
                    type: 'string'
				})
			}
			return headers
			// if (this.entityTab === 0) {
			// 	headers = [
			// 		{
			// 			text: this.$t('headers.account'),
			// 			name: 'accountId',
			// 			value: 'accountId',
			// 			setValueExpr: val => this.accounts?.find(c => c.accountId === val)?.name,
			// 			type: 'string'
			// 		},
			// 		{
			// 			text: this.$t('headers.item'),
			// 			name: 'materialId',
			// 			value: 'materialId',
			// 			setValueExpr: val => this.myMaterials?.find(c => c.id === val)?.name,
			// 			type: 'string'
			// 		},
			// 		{
			// 			text: this.$t('headers.amount'),
			// 			name: 'amount',
			// 			value: 'amount',
			// 			inputRules: rules.IntNumber,
			// 			type: 'number'
			// 		},
			// 		{
			// 			text: this.$t('headers.notes'),
			// 			name: 'description',
			// 			value: 'description',
			// 			type: 'string'
			// 		},
			// 		{
			// 			text: this.$t('headers.deliver-date'),
			// 			name: 'date',
			// 			value: 'date',
			// 			type: 'date',
			// 			width: "20%"
			// 		},
			// 		{
			// 			text: '',
            //         	name: 'actions',
            //         	value: 'actions',
            //         	width: "10%",
            //         	noInput: true,
            //         	noNotAvailableText: true,
			// 			sortable: false
			// 		}
			// 	]
			// }
        },

		allAccountsBrief() {
            return this.briefAccounts[this.entityTab];
        },

		currentUserData() {
            return this.briefAccounts[0].length
                ? this.briefAccounts[0].find(c => c.id === this.employeeId) || {}
                : {};
        },

        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },

        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

		icon () {
            if (this.selectedAllSectors) return 'mdi-close-box'
            if (this.selectedSomeSectors) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
		search() {
			this.existType = true; 
			// if (this.existType === true) {
			this.needToResetShownRows = true; 
				// this.updateTableDueTab()
			// this.updateJournal()
			// this.getAllAccount()
			this.getJournal(null, true).finally(() => {
				this.loading = false
			})
			this.loading = true
			this.$store.dispatch('accounts/fetchAllBrief', {
				regionId: [],
                type: this.entityTab,
				entityType: this.entity ? this.entity : null
            }).then((data) => {
                this.briefAccounts[this.entityTab].push(...data);
				this.addTransactionToEntity = true
            })
		},
		editItem() {
			this.submitLoading = true
			this.$store.dispatch('materialTransactions/update', {
				id: this.itemId,
				newSampleData: this.sampleData
			}).then(() => {
				this.dialog = false
				this.needToResetShownRows = true
				this.getJournal()
			}).finally(() => {
				this.submitLoading = false
			})
		},

		getAllAccount(isFirstLoad) {
            if (this.allAccountsBrief.length && this.entityTab !== 1) return;
			
            this.loading = true;
            return this.$store.dispatch('accounts/fetchAllBrief', {
				regionId: [],
                type: this.entityTab,
				entityType: this.entity ? this.entity : null
            }).then((data) => {
                this.briefAccounts[this.entityTab].push(...data);
            }).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

		updateTableDueTab() {
			this.$router.replace({ name: 'journal-delivery-of-samples', query: { tab: this.entityTab } });
			this.$refs.table.resetShownRows();
			this.$refs.table.resetInputs({ date: this.moment().format('YYYY-MM-DD') });
			if (this.existType === false && this.entityTab !== 1) {
				this.updateJournal()
			}
        },
		
		async updateJournal(isFirstLoad = false) {
            this.loading = true;
            return Promise.all([
                await this.getAllAccount(),
                this.getJournal(null, true),
            ]).catch(() => {
				this.loading = false;
			}).finally(() => {
                // if (!isFirstLoad) 
				this.loading = false;
            });
        },

        addItem () {
			// console.log(this.currentUserData.accountId)
			if (this.$refs.table.validate()) {
				this.loading = true
				var materials = {
					materialsAmount: {
						materialId: this.tableInputs.materialId,
						amount: this.tableInputs.amount	
					},
					fromAccountId: this.tab === 0 ? this.currentUserData.accountId : (this.entityTab !== 1 ? this.tableInputs.accountId : this.allAccountsBrief.find(c => c.name === this.tableInputs.accountId)?.accountId),
					toAccountId: this.tab === 0 ? (this.entityTab !== 1 ? this.tableInputs.accountId : this.allAccountsBrief.find(c => c.name === this.tableInputs.accountId)?.accountId) : this.currentUserData.accountId,
					date: this.tableInputs.date,
					description: this.tableInputs.description
				}
				this.$store.dispatch('materialTransactions/createDeliverAndReceive', { materials }).then(async() => {
					this.needToResetShownRows = true;
					this.tableInputs.materialId = null;
					this.tableInputs.amount = null;
					await this.updateJournal();
				}).finally(() => {
					this.$refs.table.resetInputsFocus()
				})
			}
        },

		getJournal(newOptionsScroll, isFirstLoad = false) {
            let page = 1
            const { itemsPerPage } = this.scrollOptions;
            if (newOptionsScroll) page = newOptionsScroll.page || 1;
			if (this.needToResetShownRows) {
				this.needToResetShownRows = false;
				this.$refs.table.resetShownRows();
			}
            
            this.loading = true
			return this.$store.dispatch('materialTransactions/fetchStatement', {
                Date: this.date,
                Page: page,
                PerPage: itemsPerPage || 50,
                type: this.entityTab,
                WithVisitTransaction: null,
				SelectInAmount: this.entityTab === 0 && this.tab === 0,
				SelectOutAmount: this.entityTab === 0 && this.tab === 1,
				entity: this.entity,
				regionIds: this.regionIds 
            }).then(({ data, total }) => {
				const items = data.map(c => ({ 
                    ...c, 
                    amount: (c.in || c.out).amount,
	            	materialId: (c.in || c.out).materialId,
                    accountId: c.accountId,
                    notes: c.description,
                }));

                this.serverItemsLength = total;
				if (!this.existType && this.entityTab === 1) {
					return []
				} else {
					this.$refs.table.addScrollItems(items);
				}
			}).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
		},

		getAccount(item) {
			const accountName = this.allAccountsBrief.find(c => c.accountId === (
				this.entityTab === 0 && this.tab === 1
					? item.fromAccountId
					: item.accountId
				)
			)
			return accountName ? accountName.name : '-'
		},

		moment,

		setRegions () {
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

		toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.setRegions()
                }
            })
        },

		toggleReignsFilterItems () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } else {
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

		resetFilter () {
			this.type = null
			this.cityId = []
			this.regionIds = []
			this.entity = null
		},

		getStatement () {
			this.needToResetShownRows = true
			this.updateJournal()
		},

		getMaterialByAccount () {
			let employeeId = null
			if (this.tab === 1) {
				employeeId = this.allAccountsBrief.find(c => c.accountId === this.tableInputs.accountId)?.id
				this.materialLoading = true
				this.$store.dispatch('materials/fetchMyMaterial', { employeeId: employeeId }).finally(() => {
					this.materialLoading = false
				})
			}
			console.log(employeeId)
		},
		getMaterialByAccountd () {
			// employeeId = this.employeeId
			this.materialLoading = true
		 	const employeeId = this.allAccountsBrief.find(c => c.accountId === this.employeeId)?.id
			this.$store.dispatch('materials/fetchMyMaterial', { employeeId: employeeId }).finally(() => {
				this.materialLoading = false
			})
		}

    },

    mounted () {
        this.loading = true;
		this.needToResetShownRows = true;
		this.entityTab = +this.$route.query.tab || 0;
		this.$refs.table.resetInputs({ date: this.moment().format('YYYY-MM-DD') });

        Promise.all([
			this.$store.dispatch('materials/fetchMyMaterial', { employeeId: this.allAccountsBrief.find(c => c.accountId === this.tableInputs.accountId)?.id }),
			// !this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
			// !this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {
			// 	cities: this.cities
			// }) : null,
        ]).then(() => {
			this.updateJournal(true)
		}).finally(() => {
            this.loading = false
        })
    }
}
</script>
<style scoped>
</style>
