<template>
    <div>
        <!-- rate dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="400"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex primary">
                            <v-card-title
                                class="text-uppercase flex-grow-1 text-subtitle-1 d-flex justify-center pa-0 white--text"
                            >
                                Add New Review
                            </v-card-title>
                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>

                        <v-card-text class="pa-3 pb-0">
                            <!-- dialog content -->
                            <v-row dense class="ma-0">
                                <!-- date -->
                                <v-col sm="6" cols="12">
                                    <bee-date-picker
                                        v-model="date"
                                        input-label="Date"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :rules="rules.required"
                                    ></bee-date-picker>
                                </v-col>

                                <!-- Visits Quality -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="visitsQuality"
                                        type="number"
                                        min="0"
                                        max="25"
                                        label="Visits Quality"
                                        suffix="/ 25"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                        :rules="getMarkValidationRule(25)"
                                    ></v-text-field>
                                </v-col>

                                <!-- Exam Result -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="examResult"
                                        type="number"
                                        min="0"
                                        max="20"
                                        label="Exam Result"
                                        suffix="/ 20"
                                        :rules="getMarkValidationRule(20)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>

                                <!-- Region Sales -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="regionSalesLevel"
                                        type="number"
                                        min="0"
                                        max="15"
                                        label="Region Sales"
                                        suffix="/ 15"
                                        :rules="getMarkValidationRule(15)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Commitment & Punctuality -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="cooperation"
                                        type="number"
                                        min="0"
                                        max="10"
                                        label="Cooperation"
                                        suffix="/ 10"
                                        :rules="getMarkValidationRule(10)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Commitment & Punctuality -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="commitment"
                                        type="number"
                                        min="0"
                                        max="10"
                                        label="Commitment"
                                        suffix="/ 10"
                                        :rules="getMarkValidationRule(10)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Behavior -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="behavior"
                                        type="number"
                                        min="0"
                                        max="10"
                                        label="Behavior"
                                        suffix="/ 10"
                                        :rules="getMarkValidationRule(10)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Appearance -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="appearance"
                                        type="number"
                                        min="0"
                                        max="10"
                                        label="Appearance"
                                        suffix="/ 10"
                                        :rules="getMarkValidationRule(10)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>

                                <!-- Competitive spirit -->
                                <v-col v-if="0" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="CompetitiveSpirit"
                                        type="number"
                                        min="0"
                                        max="20"
                                        label="Competitive spirit"
                                        suffix="/ 20"
                                        :rules="getMarkValidationRule(20)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Initiative -->
                                <v-col v-if="0" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="Initiative"
                                        type="number"
                                        min="0"
                                        max="20"
                                        label="Initiative"
                                        suffix="/ 20"
                                        :rules="getMarkValidationRule(20)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Inspecting New Doctors -->
                                <v-col v-if="0" sm="12" cols="12">
                                    <v-text-field
                                        v-model.number="inspectingNewDoctors"
                                        type="number"
                                        min="0"
                                        max="20"
                                        label="Inspecting New Doctors"
                                        suffix="/ 20"
                                        :rules="getMarkValidationRule(20)"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Total -->
                                <v-col sm="12" cols="12" class="py-1 mx-auto">
                                    <v-text-field
                                        v-model.number="totalMarks"
                                        type="number"
                                        outlined
                                        min="0"
                                        max="100"
                                        label="Total"
                                        readonly
                                        suffix="/ 100"
                                        :rules="getMarkValidationRule(100)"
                                        dense
                                        hide-details="auto"
                                        
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                color="success"
                                text
                            >
                                save
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                text
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- delete-dialog declared here to use it as ref -->
        <delete-dialog
            ref="deleteDialog"
            success-message='Data is deleted'
            @on-delete="filter"
        >
            Are you sure you want to <span class="red--text">delete</span> the review?
        </delete-dialog>

        <!-- progress & filters -->
        <div class="d-flex  align-center mb-2">
            <div class="me-2">
                <vue-ellipse-progress 
                    :progress="loading ? 0 : rate.averageMarks.sum"
                    :angle="-90"
                    :color="loading ? '#0b5b6f' : colors"
                    :size="$vuetify.breakpoint.smAndUp ? 60 : 80"
                    :emptyThickness="6"
                    :thickness="6"
                    legendClass="primary--text"
                    :loading="loading"
                >
                    <span slot="legend-value">%</span>            
                </vue-ellipse-progress>
            </div>

            <!-- <v-spacer/> -->

            <!-- filter -->
            <v-form @submit.prevent="isFiltered = true; filter();">
                <v-row dense class="align-center">
                    <!-- start date -->
                    <v-col md="3" sm="6" cols="12">
                        <bee-date-picker
                            v-model="startDate"
                            input-label="Start date"
                            elevation="2"
                            menu-picker
                            dense
                            hide-details
                            :input-props="{
                                outlined: true,
                                dense: true
                            }"
                        ></bee-date-picker>
                    </v-col>
                
                    <!-- end date -->
                    <v-col md="3" sm="6" cols="12">
                        <bee-date-picker
                            v-model="endDate"
                            input-label="End date"
                            elevation="2"
                            menu-picker
                            dense
                            hide-details
                            :input-props="{
                                outlined: true,
                                dense: true
                            }"
                        ></bee-date-picker>
                    </v-col>

                    <!-- actions -->
                    <v-col cols="auto">
                        <v-btn
                            color="success"
                            class="me-2"
                            :loading="loading"
                            :disabled="loading || isAllFieldsEmpty"
                            small
                            type="submit"
                        >
                            <v-icon> mdi-magnify </v-icon>
                            Search
                        </v-btn>
                        <v-btn
                            class="px-sm-8"
                            @click="clearFilter(); filter();"
                            :disabled="!isFiltered || loading"
                            color="alert-color white--text"
                            small
                        >
                            Reset
                        </v-btn>
                    </v-col>
                    
                    <v-spacer/>

                    <!-- add review -->
                    <v-col cols="auto" class="d-flex justify-end">
                        <v-btn
                            v-if="isSuperAdmin || permissionsUser.Reviews === roleName.action"
                            @click="activeMedicalRepRateDialog"
                            small
                            color="light-blue white--text"
                        >
                            <v-icon class="me-2">
                                mdi-plus-circle-outline
                            </v-icon>
                            add review
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>

        <!-- data table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? rate.reviews : []"
            :loading="loading"
            :height="$vuetify.breakpoint.height - 208"
            :data-table-props="{
                disableSort: true
            }"
			dense
			zebra
			pagination-on-scroll
        >
			<!-- actions -->
            <template v-slot:item.actions="{item}">
                <tooltip text="Delete Review" top>
                    <v-btn
                        icon
                        small
                        @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'medicalRepRates')"
                        color="alert-color"
                    >
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </tooltip>
            </template>
		</bee-handy-table>
    </div>
</template>

<script>
// import vueCircle from 'vue2-circle-progress'
import { mapState } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
import Tooltip from '@/components/Tooltip'
import rules from '@/validation rules'
import { roleName } from '@/helpers/enums'

export default {
    name: 'MedicalRepRates',

    components:{
        DeleteDialog,
        Tooltip,
    },

    data(){
        return {
            colors: [
                { color: '#EF476F', offset: '0' },
                { color: '#118AB2', offset: '100' },
            ],
            roleName,
            // filter
            startDate: null,
            endDate: null,
            isFiltered: false,

            // helpers
            loading: false,
            submitLoading: false,
            medicalRepId: null,

            // data
            date: null,
            visitsQuality: null,
            examResult: null,
            regionSalesLevel: null,
            cooperation: null,
            commitment: null,
            behavior: null,
            appearance: null,
            rules,
            CompetitiveSpirit: null,
            Initiative: null,
            inspectingNewDoctors: null
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return !this.startDate && !this.endDate;
        },

        ...mapState({
            rate: state => state.medicalRepRates.rate,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions
        }),

        headers() {
			const headers = [
                { 
					text: 'Date', 
					name: 'date', 
					value: 'date', 
					type: 'date',
					align: 'start',
                },
				{ 
					text: 'Visits Quality', 
					name: 'visitsQuality', 
					value: 'marks.visitsQuality', 
					align: 'center'
				},
				{ 
					text: 'Exam Result', 
					name: 'Result', 
					value: 'marks.examResult', 
					align: 'center', 
				},
				{ 
					text: 'Region Sales',
					name: 'sales',
					value: 'marks.regionSalesLevel', 
					align: 'center', 
				},
				{ 
					text: 'Participation', 
					name: 'scientific', 
					value: 'marks.cooperation', 
					align: 'center', 
				},
				{ 
					text: 'Commitment & Punctuality', 
					name: 'commitment', 
					value: 'marks.commitment', 
					align: 'center', 
				},
				{ 
					text: 'Behavior', 
					name: 'behavior', 
					value: 'marks.behavior', 
					align: 'center', 
				},
				{ 
					text: 'Appearance', 
					name: 'appearance', 
					value: 'marks.appearance', 
					align: 'center', 
				},
				{ 
					text: 'Total', 
					name: 'total', 
					value: 'marks.sum', 
					align: 'center', 
					cellClass:'fixed-width-date',
				},
				{ text: 'Actions', name: 'actions', value: 'actions', align: 'center' },
			];

            const sharedClass = 'text-subtitle-2 px-1';
            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
                headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-1' : 'px-1';
            }

            return headers;
        },

        totalMarks(){
            return (
                Number(this.visitsQuality) +
                Number(this.examResult) +
                Number(this.regionSalesLevel) +
                Number(this.cooperation) +
                Number(this.commitment) +
                Number(this.behavior) +
                Number(this.appearance)
            )
        },
    },

    methods:{
        // filter functions
        clearFilter(){
            this.startDate = null;
            this.endDate = null;
            
            this.isFiltered = false;
        },

        filter(){
            this.loading = true;
            this.$store.dispatch('medicalRepRates/fetchAll', { 
                employeeId: this.$route.params.id,
                fromData: this.startDate,
                toDate: this.endDate
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // dialog functions
        getMarkValidationRule(num){
            return [val => (val >= 0 && val <= num && val !== null) || 'Invalid mark']
        },

        activeMedicalRepRateDialog(){
            if (this.$refs.form) this.$refs.form.reset();
            this.$refs.dialog.isActive = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;

                this.$store.dispatch('medicalRepRates/create', {
                    employeeId: this.$route.params.id,
                    date: this.date,
                    visitsQuality: this.visitsQuality,
                    examResult: this.examResult,
                    regionSalesLevel: this.regionSalesLevel,
                    cooperation: this.cooperation,
                    commitment: this.commitment,
                    behavior: this.behavior,
                    appearance: this.appearance
                    
                }).then(() => {
                    this.$eventBus.$emit('show-snackbar', 'Rate is added', 'info');
                    this.$refs.dialog.isActive = false;
                    this.filter();
                }).finally(() => {
                    this.submitLoading = false;
                })
            }
        }
    },

    created(){
        this.loading = true;
        this.$store.dispatch('medicalRepRates/fetchAll', { 
            employeeId: this.$route.params.id
        })
        .finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Medical Rep Rating',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style scoped>
    .font-sm {
        font-size: 10px;
    }
</style>