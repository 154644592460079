var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center "},[(_vm.isSuperAdmin || _vm.permissionsUser === _vm.roleName.action)?_c('v-btn',{attrs:{"small":"","color":"light-blue white--text"},on:{"click":function($event){return _vm.addExam()}}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" add new exam ")],1):_vm._e()],1),_c('v-dialog',{ref:"dialog",attrs:{"transition":"dialog-transition","max-width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-form',{ref:"form",attrs:{"disabled":_vm.submitLoading},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-space-between py-1 primary"},[_c('v-card-title',{staticClass:"justify-center flex-grow-1 text-uppercase text-subtitle-1 white--text white--text pa-0"},[_vm._v(" "+_vm._s(!_vm.id ? 'add new exam' : 'edit exam')+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close-circle-outline ")])],1)],1),_c('v-card-text',{staticClass:"pt-7"},[_c('v-row',[_c('v-col',{staticClass:"py-0 px-2",attrs:{"sm":"7","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Exam Link","clearable":"","disabled":_vm.loading,"rules":_vm.rules.url,"required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1),_c('v-col',{staticClass:"py-0 px-2",attrs:{"sm":"5","cols":"12"}},[_c('bee-date-picker',{attrs:{"input-label":"Date","menu-picker":"","dense":"","hide-details":"","max-date":_vm.moment().add(1, 'month').toISOString().slice(0, 10),"input-props":{
                                        outlined: true,
                                        dense: true
                                    }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.submitLoading,"disabled":_vm.submitLoading,"color":"success white--text","elevation":"0","text":""}},[_vm._v(" save ")]),_c('v-btn',{attrs:{"elevation":"0","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" cancel ")])],1)],1)],1)]}}])}),_c('delete-dialog',{ref:"deleteDialog",attrs:{"success-message":"Data is deleted"},on:{"on-delete":function($event){_vm.needToResetItems = true; _vm.filter()}}},[_vm._v(" Are you sure you want to "),_c('span',{staticClass:"red--text"},[_vm._v("delete")]),_vm._v(" the exam ? ")]),_c('import-dialog',{ref:"ImportDialog"}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.needToResetItems = true; _vm.isFiltered = true; _vm.filter();}}},[_c('v-row',{staticClass:"my-1",attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"3","sm":"6","cols":"12"}},[_c('bee-date-picker',{attrs:{"input-label":"Start date","menu-picker":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true
                    }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"md":"3","sm":"6","cols":"12"}},[_c('bee-date-picker',{attrs:{"input-label":"End date","menu-picker":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"d-flex justify-start justify-md-end pt-0 pt-sm-2"},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"small":"","type":"submit"}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" Search ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"disabled":!_vm.isFiltered || _vm.loading,"color":"alert-color white--text","small":""},on:{"click":function($event){_vm.clearFilter(); _vm.filter();}}},[_vm._v(" Reset ")])],1)],1)],1),_c('bee-handy-table',{ref:"table",attrs:{"headers":_vm.headers,"items":[],"items-per-page":20,"pagination-on-scroll":"","pagination-on-scroll-server-items":"","server-items-length":_vm.totalExams,"pagination-on-scroll-auto-reset-shown":false,"data-table-props":{
            disableSort: true
        },"dense":"","zebra":"","options-scroll":_vm.scrollOptions,"height":_vm.$vuetify.breakpoint.height - 155,"loading":_vm.loading},on:{"update:optionsScroll":function($event){_vm.scrollOptions=$event},"update:options-scroll":function($event){_vm.scrollOptions=$event},"pagination-on-scroll:reach-last-row":_vm.filter},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":"View exam results","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","to":{name: 'exam-results', params:{id: item.id}},"color":"primary"}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1),(_vm.isSuperAdmin || _vm.permissionsUser === _vm.roleName.action)?_c('tooltip',{attrs:{"text":"Import excel file","top":""}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$refs.ImportDialog.import('exams', item.id)}}},[_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v(" mdi-file-table-outline ")])],1)],1):_vm._e(),(_vm.isSuperAdmin || _vm.permissionsUser === _vm.roleName.action)?_c('tooltip',{attrs:{"text":"Edit exam","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"edit-color"},on:{"click":function($event){return _vm.editExam(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e(),(_vm.isSuperAdmin || _vm.permissionsUser === _vm.roleName.action)?_c('tooltip',{attrs:{"text":"Delete exam","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"alert-color"},on:{"click":function($event){return _vm.$refs.deleteDialog.activeDeleteDialog(item.id, 'exams')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }