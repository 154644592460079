var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"show-arrows":"","height":"35"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"text-capitalize ",attrs:{"to":{ 
                name: 'administrative-weekly-report',
                query: {
                    employeeId: _vm.$route.query.employeeId
                }
            }}},[_vm._v(" Reports ")]),_c('v-tab',{staticClass:"text-capitalize ",attrs:{"to":{ 
                name: 'administrative-double-visits',
                query: {
                    employeeId: _vm.$route.query.employeeId
                }
            }}},[_vm._v(" Double Visits ")]),_c('v-tab',{staticClass:"text-capitalize ",attrs:{"to":{ 
                name: 'administrative-visits',
                query: {
                    employeeId: _vm.$route.query.employeeId
                }
            }}},[_vm._v(" Visits ")]),_c('v-tabs-slider',{attrs:{"color":"primary"}})],1),(!_vm.$route.query.preview)?_c('v-divider',{staticClass:"mb-2"}):_vm._e(),(_vm.loading)?_c('loader'):_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }