<template>
    <div>
		<!-- dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="500"
            v-model="entityDialog"
        >
            <template v-slot:default="dialog">
				<v-form @submit.prevent="" ref="externalEntityForm" :disabled="submitLoading">
					<v-card>
						<div class="d-flex justify-end py-1 background-title-card ">
							<v-card-title
								class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
							>
							<!-- accountIdExternal -->
								{{ idExternal ? 'Edit external entity' : 'Add new entity' }}
							</v-card-title>
							<!-- close button -->
							<v-btn
								icon
								@click="entityDialog = false; accountIdExternal = null; $refs.tableAddress.resetEdit(); $refs.contactsTable.resetEdit(); idExternal = null"
							>

								<v-icon color="white">
									mdi-close-circle-outline
								</v-icon>
							</v-btn>
						</div>
						<v-card-text class="pt-2 px-3 pb-0">
							<!-- dialog content -->
							<v-row dense class="my-0">
								<!-- docotor name -->
								<v-col sm="12" cols="12">
									<v-text-field
										label="Name"
										v-model="ExternalData.name"
										clearable
										:rules="rules.required"
										required
										outlined
										dense
										hide-details="auto"
									></v-text-field>
								</v-col>

								<!-- specialty -->
								<v-col v-if="0" sm="6" cols="12">
									<v-autocomplete
										label="Specialty"
										v-model="ExternalData.specialtyId"
										:items="specialties"
										item-text="name"
										item-value="id"
										clearable
										:rules="rules.required"
										required
										outlined
										dense
										hide-details="auto"
										multiple
									></v-autocomplete>
								</v-col>
							</v-row>

							<!-- table contact information -->
							<p class="text-body-1 mb-1"> Contact information </p>
							<bee-handy-smart-table 
								v-model="ExternalTableInputs.contactInfo"
								ref="contactsTable"
								:headers="contactInfosHeaders" 
								:items="ExternalData.contactInfo"
								show-add-row
								:editable-rows="!submitLoading"
								:data-table-props="{
									disableSort: true
								}"
								hide-default-footer
								hide-inputs-details
								hide-no-data-area
								dense
								zebra
								:disable-input-fields="submitLoading"
								@keypress.enter="isEditContact ? editExternalEntityContactRow() : addExternalEntityContactRow()"
							>
								<!-- contact type -->
								<template v-slot:input.contactType="{ on, attr }">
									<v-autocomplete 
										:items="contactInfoTypes"
										item-text="name"
										item-value="id"
										v-on="on"
										v-bind="attr"
									/>
								</template>

								<!-- action column -->
								<template v-slot:item.actions="{ item, index }">
									<div class="d-flex">        
										<tooltip text="Delete contact" top>
											<v-btn
												icon
												small
												color="alert-color"
												:disabled="submitLoading"
												@click="deleteExternalContactRow({ id: item.id, index})"
											>
												<v-icon>
													mdi-delete
												</v-icon>
											</v-btn>
										</tooltip>
									</div>
								</template>

								<!-- no-input action -->
								<template v-slot:no-input.actions>
									<div class="d-flex justify-center">
										<tooltip text="Add" top>
											<v-btn
												icon
												small
												color="success"
												:disabled="isContactTableInputsEmpty"
												@click="isEditContact ?  editExternalEntityContactRow() : addExternalEntityContactRow()"
											>
												<v-icon>
													mdi-check-circle-outline
												</v-icon>
											</v-btn>
										</tooltip>
									</div>
								</template>
							</bee-handy-smart-table>

							<!-- addresses -->
							<p class="text-body-1 my-1"> Addresses </p>

							<bee-handy-smart-table
								v-model="ExternalTableInputs.addresses"
								ref="tableAddress"
								:headers="addressesHeaders"
								:items="ExternalData.addresses"
								show-add-row
								:editable-rows="!submitLoading"
								dense
								zebra
								:disable-input-fields="submitLoading"
								hide-inputs-details
								hide-default-footer
								hide-no-data-area
								hot-inputs
								@change-cityId="
									ExternalTableInputs.addresses.regionId = null;
									fetchRegionsForAddress(ExternalTableInputs.addresses.cityId);
								"
								@keypress.enter="isEditAddress ? editAddressExternalEntity() : addAddressExternalEntity()"
							>
								<!-- city id -->
								<template v-slot:input.cityId="{ on, attr }" >
									<v-autocomplete
										:items="cities"
										item-text="name"
										item-value="id"
										v-on="on"
										v-bind="attr"
									></v-autocomplete>
								</template>

								<!-- region -->
								<template v-slot:input.regionId="{ on, attr }" >
									<v-autocomplete
										:items="regionsAll.filter(c => c.cityId === ExternalTableInputs.addresses.cityId)"
										item-text="name"
										item-value="id"
										v-on="on"
										v-bind="attr"
										:loading="regionsLoading"
										:disabled="!ExternalTableInputs.addresses.cityId || regionsLoading"
									></v-autocomplete>
								</template>

								<!-- workload -->
								<template v-slot:input.workload="{ on, attr }" >
									<v-autocomplete
										:items="classification"
										item-text="name"
										item-value="id"
										v-on="on"
										v-bind="attr"
									></v-autocomplete>
								</template>

								<!-- action column -->
								<template v-slot:item.actions="{ item, index }">
									<div class="d-flex">
										<tooltip text="Edit external entity" top>
											<v-btn
												icon
												small
												color="edit-color"
												:disabled="submitLoading"
												@click="() => {
													$refs.tableAddress.activeEditForRow(index);
													editAddressRowIndex = index;
                                                    isEditAddress = true;
                                                    $nextTick(() => {
                                                        $refs.tableAddress.resetInputsFocus()
                                                    })
												}"
											>
												<v-icon>
													mdi-pencil
												</v-icon>
											</v-btn>
										</tooltip>
										
										<tooltip text="Delete external entity" top>
											<v-btn
												icon
												small
												:disabled="submitLoading"
												@click="deleteExternalAddressRow({ id: item.id, index }); externalRowId = null"
												color="alert-color"
											>
												<v-icon>
													mdi-delete
												</v-icon>
											</v-btn>
										</tooltip>
									</div>
								</template>
		
								<!-- no-input action -->
								<template v-slot:no-input.actions>
									<div class="d-flex justify-center">
										<tooltip text="Add" top>
											<v-btn
												icon
												small
												color="success"
												:disabled="isAddressTableInputsEmpty"
												@click="isEditAddress ? editAddressExternalEntity() : addAddressExternalEntity()"
											>
												<v-icon>
													mdi-check-circle-outline
												</v-icon>
											</v-btn>
										</tooltip>
									</div>
								</template>
							</bee-handy-smart-table>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn
								:loading="submitLoading"
								:disabled="submitLoading"
								color="success white--text"
								text
								@click="submit"
							>
								save
							</v-btn>
							<v-btn
								@click="dialog.value = false; accountIdExternal = null; $refs.tableAddress.resetEdit(); $refs.contactsTable.resetEdit(); externalRowId = null"
								text
							>
								cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
            </template>
        </v-dialog>

		<!-- delete dialog -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="needToResetItems = true; filter();"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> this entity data?
        </delete-dialog>

		<v-row >
			<v-col>
				<v-tabs
					v-if="0"
					v-model="accountTap"
					@change="needToResetItems = true; clearFilter();getAccount() ;filter()"
					active-class
				>
					<v-tab 
						:disabled="loading"
						v-for="(tab, index) in tabs"
						:key="index"
						class="text-capitalize"
					>
						{{ tab.name }}
					</v-tab>
				</v-tabs>
				<v-divider v-if="0" />
			</v-col>

			<v-col
				v-if="isSuperAdmin || permissionsUser.Entities === roleName.action"
				class="d-flex justify-end"
				align-self="end"
			>
				<v-btn
					@click="isAdd = true; resetInputs(); entityDialog = true"
					color="success"
					small
				>
					<v-icon class="me-2"> mdi-plus-circle-outline </v-icon>
					new entity external
				</v-btn>
			</v-col>
		</v-row>	
		<!-- filter -->
		<v-form>
			<v-row class="mx-1 mb-1 mt-2" align="center">
				<!-- city region filter -->
				<v-col lg="4" md="5" sm="6" cols="12" class="pa-0 ">
					<v-row class="pa-3">
						<!-- city name -->
						<v-col cols="5" class="pa-0 pe-1 d-flex" >
							<v-autocomplete
								v-model="cityId"
								:items="cities"
								item-text="name"
								item-value="id"
								:return-object="false"
								label="City"
								@change="fetchRegionsDueCity(); searchCityId = null"
								dense
								hide-details
								append-icon
								:disabled="loading"
								outlined
								prepend-icon="mdi-map-marker-multiple"
								multiple
								:search-input.sync="searchCityId"
							>
								<template v-slot:prepend-item>
									<v-list-item
										ripple
										@click="toggleCities"
									>
										<v-list-item-action>
											<v-icon>
												{{ iconCities }}
											</v-icon>
										</v-list-item-action>
										
										<v-list-item-content>
											<v-list-item-title>
												Select All
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2"></v-divider>
								</template>

								<template v-slot:selection="{ item, index }">
									<span v-if="index === 0" class="me-1">{{ item.name }}</span>
									<span
										v-if="index === 1"
										class="grey--text text-caption"
									>
										(+{{ cityId.length - 1 }})
									</span>
								</template>
							</v-autocomplete>
						</v-col>
			
						<!-- region -->
						<v-col cols="7" class="pa-0">
							<v-autocomplete
								v-model="regionIds"
								:items="regionsAll.filter(c => cityId.includes(c.cityId))"
								item-text="name"
								item-value="id"
								:return-object="false"
								label="Regions"
								dense
								multiple
								hide-details
								clearable
								:loading="regionsLoading"
								:disabled="regionsLoading || cityId === []"
								outlined
								:search-input.sync="searchRegionId"
								@change="searchRegionId = null"
							>
								<template v-slot:selection="{ item, index }">
									<span v-if="index === 0" class="me-1">{{ item.name }}</span>
									<span
										v-if="index === 1"
										class="grey--text text-caption"
									>
										(+{{ regionIds.length - 1 }})
									</span>
								</template>

								<template v-slot:prepend-item>
									<v-list-item ripple @click="toggleRegionFilter">
										<v-list-item-action>
											<v-icon color='indigo darken-4'>{{ iconRegionsFilter }}</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>Select All</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider />
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-col>
			
				<!-- employee name -->
				<v-col md="2" sm="4" cols="12" class="pa-0 ms-sm-3 d-flex">
					<v-autocomplete
						v-model="name"
						:items="accounts"
						item-value="name"
						item-text="name"
						:return-object="false"
						label="name"
						dense
						hide-details
						clearable
						:disabled="loading"
						outlined
						prepend-icon="mdi-account-tie"
					></v-autocomplete>
				</v-col>

				<!-- specialty -->
				<v-col v-if="0" md="2" sm="3" cols="12" class="pa-1">
					<v-autocomplete
						v-model="specialtyId"
						:items="specialties"
						item-text="name"
						item-value="id"
						label="Specialty"
						dense
						clearable
						hide-details
						:disabled="loading"
						outlined
					></v-autocomplete>
				</v-col>

				<!-- workload -->
				<v-col v-if="0" sm="2" cols="12" class="pa-1 workload">
					<v-autocomplete
						v-model="workload"
						:items="classification"
						item-text="name"
						item-value="id"
						label="Workload"
						dense
						clearable
						hide-details
						:disabled="loading"
						outlined
					></v-autocomplete>
				</v-col>
				
				<v-spacer />
			
				<v-col cols="auto" sm="6" md="auto" class="d-flex justify-md-end px-0 pa-0">
					<v-btn
						color="success"
						class="px-sm-4 mx-2"
						:loading="loading"
						:disabled="loading || isAllFieldsEmpty"
						type="submit"
						small
						@click="needToResetItems = true; filter()"
					>
						<v-icon> mdi-magnify </v-icon>
						Search
					</v-btn>
					<v-btn
						class="px-sm-8"
						@click="clearFilter(); filter();"
						:disabled="loading"
						color="alert-color white--text"
						small
					>
						Reset
					</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<bee-handy-table
			v-if="accountTap !== 0"
			ref="table"
			:items="[]"
			:headers="headers"
			dense
			zebra
			hide-default-footer
			:height="$vuetify.breakpoint.height - 80"
			:loading="loading"
			fixed-header
			pagination-on-scroll
			:pagination-on-scroll-auto-reset-shown="false"
			pagination-on-scroll-server-items
			:options-scroll.sync="scrollOptions"
			:server-items-length="serverItemsLength"
			@pagination-on-scroll:reach-last-row="filter"
		>
			<template v-if="accountTap === 2" v-slot:item.actions="{ item }">
				<v-btn
					@click="idExternal = item.entityId; editExternal(item);"
					color="edit-color"
					icon
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>

				<v-btn
					@click="$refs.deleteDialog.activeDeleteDialog(item.entityId, 'accounts')"
					color="alert-color"
					icon
				>
					<v-icon> mdi-delete </v-icon>
				</v-btn>
			</template>
		</bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
// import Tooltip from '@/components/Tooltip'
import rules from '@/validation rules'
import { entityType, contactInfoTypes, contactInfoType, roleName } from '@/helpers/enums'
import moment from 'moment'
import Tooltip from '@/components/Tooltip'
export default {
	components: {
		Tooltip,
		DeleteDialog
	},

	watch: {
        entityDialog(val) {
            if (val === false) {
                this.isAdd = false
            }
            if (this.isAdd) {
                this.ExternalData.contactInfo = [
                    {
                        contactInfoType: 0, value: ''
                    },
                    {
                        contactInfoType: 0, value: ''
                    }
                ]
            }
        }
    },

    data() {
        return {
			searchRegionId: null,
			searchCityId: null,
			isAdd: false,
			idExternal: null,
			idDelete: null,
			rules,
			loading: false,
            items: [],
			accountTap: 2,
			roleName,
			tab: 0,
			tabs: [
				{
					name: 'Staff'
				},
				{
					name: 'Customers'
				},
				{
					name: 'External entities'
				}
			],
			CustomersTab: [
				{
					name: 'Doctors',
					type: entityType.doctor
				},
				{
					name: 'Pharmacy',
					type: entityType.pharmacy
				},
				{
					name: 'Center',
					type: entityType.center
				}
			],
			entityType,
			cityId: [],
			regionIds: [],
			name: null,
			regionsLoading: false,
			employee: [],
			sectorId: null,
			specialtyId: null,
			workload: null,
			// pagination
            scrollOptions: {},
            serverItemsLength: 0,
            needToResetItems: false,
			accountIdExternal: null,
			entityDialog: false,
			// ExternalData: {
			// 	name: null,
			// 	specialtyId: [],
			// 	contactInfo: [],
			// 	addresses: []
			// },
			
            ExternalData: {
                name: null,
                entityType : 4, // 1 for doctor type in API
                specialtyId: [],
                contactInfo: [
                    {
                        contactInfoType: 0
                    },
                    {
                        contactInfoType: 0,
                    }
                ],
                addresses: [],
				// residentDoctorNames: ''
            },
			submitLoading: false,
			contactInfoType,
			contactInfoTypes,
			ExternalTableInputs: {
                contactInfo: {
                    contactValue: null
                },
                addresses: {},
            },
			isEditContact: null,
			isEditAddress: false,
			editAddressRowIndex: null,
			externalRowId: null,
			myAccounts: [],
			accountsBrief: [],
			numContact: 0,
        }
    },

    computed: {
        ...mapState({
			medicalReps: state => state.medicalreps.medicalreps,
			allEntities: state => state.entities.allEntities,
			cities: state => state.cities.cities,
			regionsAll: state => state.regions.regionsAll,
			regions: state => state.regions.regions,
			sectorCities: state => state.sectors.sectorCities.map(c => c.sectors[0]),
            // sectorsMine: state => state.sectors.sectorsMine,
			specialties: state => state.specialties.specialties,
			classification: state => state.workload.classification,
			citiesBySectorFilter: state => state.sectors.citiesBySectorFilter,
			accounts: state => state.accounts.accounts,
			permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin,
        }),

		...mapGetters({
			getMedicalRepById: 'medicalreps/getMedicalRepById',
			getCustomerById: 'entities/getCustomerById',
			getCityById: 'cities/getCityById',
			getRegionAllById: 'regions/getRegionAllById',
			getClassificationById: 'workload/getClassificationById',
			getAccountById: 'accounts/getAccountById'
		}),

		headers() {
			const headers = [
				{
					text: 'Name',
					name: 'Name',
					value: 'name'
				},
				{
					text: 'Phone',
					name: 'phoneNumber',
					value: 'contactInfos',
					setValueExpr: val => val.length !== 0 ? val?.filter(c => c.contactInfoType === 0).map(c => c.value).join(', ') : '-'
				},
				{
					text: 'Landline',
					name: 'landlineNumber',
					value: 'contactInfos',
					setValueExpr: val => val.length !== 0 ? val?.filter(c => c.contactInfoType === 1).map(c => c.value).join(', ') : '-'
				},
				{
					text: 'Email',
					name: 'email',
					value: 'contactInfos',
					setValueExpr: val => val?.filter(c => c.contactInfoType === 2).map(c => c.value).join(', ') || '-'
				},
				{
					text: 'Address',
					name: 'address',
					value: 'entityAddresses',
					setValueExpr: val => val?.map(c => c.address).join(', ') || '-'
				},
			]
			if (this.isSuperAdmin || this.permissionsUser.Entities === roleName.action) {
				headers.push(
					{
						text: 'Actions',
						name: 'actions',
						value: 'actions'
					}
				)
			}
			return headers
		},

		headersStaff() {
			return [
				{
					text: 'Name',
					name: 'Name',
					value: 'name'
				},
				{
					text: 'Phone',
					name: 'phoneNumber',
					value: 'phoneNumber',
				},
				{
					text: 'Telephone',
					name: 'landlineNumber',
					value: 'landlineNumber',
				},
				{
					text: 'Email',
					name: 'email',
					value: 'email',
				},
				{
					text: 'Address',
					name: 'address',
					value: 'address'
				}
			]
		},

		contactInfosHeaders() {
            return [
                {
                    text: 'Contact type',
                    name: 'contactType',
                    value: 'contactInfoType',
                    setValueExpr: val => contactInfoTypes.find(c => c.id === val)?.name,
                    noNotAvailableText: true,
                    writable: !this.loading
                }, 
                {
                    text: 'Contact',
                    name: 'contactValue',
                    value: 'value',
                    writable: !this.loading,
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    align: 'center',
                    noInput: true
                }
            ]
        },

		addressesHeaders() {
            return [
                {
                    text: 'City',
                    name: 'cityId',
                    value: 'cityId',
                    setValueExpr: val => this.getCityById(val)?.name,
                    inputRules: this.isAddressTableInputsEmpty ? [] : rules.required,
                    noNotAvailableText: true,
                },
                {
                    text: 'Region', 
                    name: 'regionId',
                    value: 'regionId',
                    setValueExpr: val => this.getRegionAllById(val)?.name,
                    inputRules: this.isAddressTableInputsEmpty ? [] : rules.required,
                    noNotAvailableText: true,
                },
                // {
                //     text: 'Workload',
                //     name: 'workload',
                //     value: 'workloadId',
                //     setValueExpr: val => this.getClassificationById(val)?.name,
                //     // setValueExpr: val => this.classification.find(c => c.id === val)?.name,
                //     // inputRules: this.isAddressTableInputsEmpty ? [] : rules.requiredAsNumber,
                //     // noNotAvailableText: true,
                // },
                // {   
                //     text: 'Visit time',
                //     name: 'visitTime',
                //     value: 'visitTime'                    
                // },
                {
                    text: 'Full address',
                    name: 'address',
                    value: 'address',
                    inputRules: this.isAddressTableInputsEmpty ? [] : rules.required,
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                }
            ]
        },

		// contact
        isContactTableInputsEmpty() {
            for (const key in this.ExternalTableInputs.contactInfo) {
                if (this.ExternalTableInputs.contactInfo[key] !== null) return false;
            }
            return true;
        },

		isAllFieldsEmpty(){
            return !this.name && !this.regionIds.length;
        },

		selectedAllCities () {
            return this.cityId?.length === this.cities.length;
        },

		iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		entityTypeForCurrentTab(){
            switch (this.CustomersTab) {
                case 0: return entityType.doctor;
                case 1: return entityType.pharmacy;
                case 2: return entityType.center;
                default: return null;
            }
        },

		isAddressTableInputsEmpty() {
            for (const key in this.ExternalTableInputs.addresses) {
                if (this.ExternalTableInputs.addresses[key]) return false;
            }
            return true
        },

		selectedAllRegionsFilter () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

		iconRegionsFilter() {
            if (this.selectedAllRegionsFilter) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

	methods: {
		moment,
		toggleRegionFilter() {
            this.$nextTick(() => {
                if (this.selectedAllRegionsFilter) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);  
                }
            })
        },
		getAccount() {
			this.$store.dispatch('accounts/fetchAllBrief', { type: this.accountTap, regionId: [] }).then((data) => {
				this.accountsBrief = data
			})
		},
		submit() {
            const isValid = this.$refs.externalEntityForm.validate();
            if (isValid) {
                this.submitLoading = true;
                const actionName = this.idExternal === null ? 'create' : 'update'
                const id = this.idExternal;
                const data = 
                    // isCurrentDoctor?
					{
						name: this.ExternalData.name,
						entityType : 4,
						// specialtyId: this.ExternalData.specialtyId,
						contactInfo: this.ExternalData.contactInfo.map(({ id, ...data }) => data),
						addresses: this.ExternalData.addresses.map(({ id, ...data }) => data),
						bestTimeToVisit: '2023-11-25T08:22:08.243Z',
						openingTime: "2023-11-25T08:22:08.243Z",
						closingTime: "2023-11-25T08:22:08.243Z",
						// residentDoctorNames: "string"
					}
                this.$store.dispatch(`accounts/${actionName}`, { id, data }).then(() => {
                    const message = 'The account' + (actionName === 'create' ? ' is added' : ' is edited');
                        
                    this.$eventBus.$emit('show-snackbar', message, 'info');

                    this.needToResetItems = true;
                    this.filter();

                    this.$refs.tableAddress.resetEdit()
                    this.$refs.contactsTable.resetEdit()

                    // this.doctorId = null;
					this.externalRowId = null
                    // this.directoryId = null;
                }).finally(() => {
                    this.submitLoading = false;
                    this.isAdd = false
					this.entityDialog = false
                })
            }
		},
		getItem () {
			if (this.accountTap === 0 && !this.medicalReps.length) {
				this.loading = true
				this.$store.dispatch('medicalreps/fetchAll', true).then((data) => {
					this.items = data
				}).finally(() => {
					this.loading = false
				})
			}
			else if (this.accountTap === 1) {
				this.loading = true
				this.filter()
			}
		},

		// Filter functions
        fetchRegionsDueCity(){
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

		fetchRegionsForAddress(cityId){
            this.regionIds = [];
            if (!cityId) return;
            
            this.regionsLoading = true;
            this.$store.dispatch('regions/fetchAll', {
                cityId: cityId
            })
            .finally(() => {
                this.regionsLoading = false
            })
        },

		setRowIndex(rowData) {
			this.editAddressRowIndex = rowData.index;
			this.isEditAddress = true;
			this.$nextTick(() => {
				this.$refs.tableAddress.resetInputsFocus()
			})
		},

		toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCity()
                }
            })
        },

		clearFilter(){
            this.cityId = [];
            this.name = null;
            this.regionIds = [];
			this.$refs.table.resetShownRows()
            // this.isFiltered = false;
        },

		filterStaff() {
			this.loading = true
			this.$store.dispatch('medicalreps/fetchByFilter', {
				name: this.name,
				regionIds: this.regionIds
			}).then((data) => {
				this.items = data;
			}).finally(() => {
				this.loading = false
			})
		},

		addExternalEntityContactRow() {
            if (!this.isContactTableInputsEmpty) {
                this.ExternalData.contactInfo.push({
                    id: ++this.numContact,
                    contactInfoType: this.ExternalTableInputs.contactInfo.contactType,
                    value: this.ExternalTableInputs.contactInfo.contactValue
                });
                this.$refs.contactsTable.resetInputs();
                this.$refs.contactsTable.resetInputsFocus();
            }
        },

		editExternalEntityContactRow() {
			if (this.$refs.contactsTable.validate()) {
				this.ExternalData.contactInfo[this.editContactRowIndex].contactInfoType =
                    this.ExternalTableInputs.contactInfo.contactType;
				this.ExternalData.contactInfo[this.editContactRowIndex].value =
                    this.ExternalTableInputs.contactInfo.contactValue
				this.$refs.contactsTable.resetEdit();
				this.isEditContact = false;
				
				this.$nextTick(() => {
					this.$refs.contactsTable.resetInputs();
					this.$refs.contactsTable.resetInputsFocus();
				})
			}
		},

		editAddressExternalEntity() {
			if (this.$refs.tableAddress.validate()) {
                this.ExternalData.addresses[this.editAddressRowIndex].cityId = this.ExternalTableInputs.addresses.cityId;
                this.ExternalData.addresses[this.editAddressRowIndex].regionId = this.ExternalTableInputs.addresses.regionId;
                this.ExternalData.addresses[this.editAddressRowIndex].address = this.ExternalTableInputs.addresses.address;
                this.ExternalData.addresses[this.editAddressRowIndex].workloadId = this.ExternalTableInputs.addresses.workload;
                this.ExternalData.addresses[this.editAddressRowIndex].visitTime = this.ExternalTableInputs.addresses.visitTime;
                // this.ExternalData.addresses[this.editAddressRowIndex].bestTimeToVisit = this.addresses.addresses.bestTimeToVisit;
                
                this.$refs.tableAddress.resetEdit();
                this.isEditAddress = false;
                this.$nextTick(() => {
                    this.$refs.tableAddress.resetInputs();
					this.$refs.tableAddress.resetInputsFocus()
                })
			} 
        },

		getCitiesFilter() {
            if (this.sectorId !== null) {
                this.loading = true;
                this.$store.dispatch('sectors/fetchCitiesInSector', { id: this.sectorId }).finally(() => {
                    this.loading = false
                })
            }
        },
		searchStaff() {
			this.loading = true
			this.$store.dispatch('accounts/fetchAll', { 
				regionIds: this.regionIds, 
				name: this.name, 
				type: this.accountTap, 
				nonDeleted: true, 
				IncludeContactInfo: true, 
				includeAddresses: true 
			}).then(() => {
				this.$refs.table.resetShownRows();
				this.loading = false
			})
		},
		// filter functions
        filter(newOptionsScroll){
            this.loading = true;
            let page = 1;

            const { itemsPerPage } = this.scrollOptions;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

			this.$store.dispatch('accounts/fetchAll', { 
				regionIds: this.regionIds, 
				name: this.name, 
				type: this.accountTap, 
				nonDeleted: true, 
				IncludeContactInfo: true, 
				includeAddresses: true,
				perPage: itemsPerPage, 
				page: page 
			}).then(({ data, total }) => {
				// console.log(data)
                if (this.needToResetItems) { 
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false;
                }
                
                this.$refs.table.addScrollItems(data);
                this.serverItemsLength = total;
            }).finally(() => {
                this.loading = false;
            })
        },

		// address
        addAddressExternalEntity() {
            if (this.$refs.tableAddress.validate() && !this.isAddressTableInputsEmpty) {
                this.ExternalData.addresses.push({
                    id: ++this.numAddresses,
                    cityId: this.ExternalTableInputs.addresses.cityId,
                    regionId: this.ExternalTableInputs.addresses.regionId,
                    visitTime: this.ExternalTableInputs.addresses.visitTime,
                    address: this.ExternalTableInputs.addresses.address,
                    workloadId: this.ExternalTableInputs.addresses.workload,
                })
                this.$refs.tableAddress.resetInputs();
                this.$refs.tableAddress.resetInputsFocus();
            }
        },

		deleteExternalContactRow(rowData) {
            // this.$refs.doctorContactsTable.resetEdit();
			this.isEditContact = false;
			this.ExternalData.contactInfo.splice(rowData.index, 1);
            this.numContact--;

            for (let i = 0; i < this.ExternalTableInputs.contactInfo.length; i++) {
                if (this.ExternalTableInputs.contactInfo[i].id > rowData.id) {
                    this.ExternalTableInputs.contactInfo[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.contactsTable.resetInputs();
				this.$refs.contactsTable.resetInputsFocus()
			})
		},

		deleteExternalAddressRow(rowData) {
            this.$refs.tableAddress.resetEdit();
			this.isEditAddress = false;
			this.ExternalData.addresses.splice(rowData.index, 1);
            this.numContact--;

            for (let i = 0; i < this.ExternalData.addresses.length; i++) {
                if (this.ExternalData.addresses[i].id > rowData.id) {
                    this.ExternalData.addresses[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.tableAddress.resetInputs();
				this.$refs.tableAddress.resetInputsFocus()
			})
		},

		resetInputs(){
            if (this.$refs.externalEntityForm) this.$refs.externalEntityForm.resetValidation();

            // reset counters
            this.numContact = 0;
            this.ExternalData.contactInfo = [];

            this.numAddresses = 0;
            this.ExternalData.addresses = [];

            // reset doctor data
            this.ExternalData.name = null;
            this.ExternalData.entityType = 4;
            this.ExternalData.specialtyId = [];
            this.ExternalData.contactInfo = [];
            this.ExternalData.addresses = [];
        },

		editExternal(item){
            this.externalRowId = item.id;
            this.resetInputs();

            // var doctor = this.getEntityById(doctorId);
            var external = item;
            // var doctor = this.item
            // console.log(this.item)
            this.ExternalData.name = external?.name;
            this.ExternalData.specialtyId = external?.specialtyIds;
        
            external.contactInfos.forEach((contactInfo) => {
                this.ExternalData.contactInfo.push(
                    {
                        id: ++this.numContact,
                        contactInfoType: contactInfo.contactInfoType,
                        value: contactInfo.value,
                    }
                );
            });
            
            external.entityAddresses.forEach((address) => {
                this.ExternalData.addresses.push(
                    {
                        id: ++this.numAddresses,
                        cityId: this.getRegionAllById(address.regionId)?.cityId,
                        regionId: address.regionId,
                        workloadId : address.workloadId,
                        address: address.address,
                        visitTime: address.visitTime,
                        bestTimeToVisit: address.bestTimeToVisit
                    }
                );
            });

            this.entityDialog = true
        },

		// filterObject(item, queryText, itemText) {
		// 	return (
		// 		item.includes() >
		// 		-1 ||
		// 		item.prop2.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
		// 	);
    	// }
	},

    created() {
		this.loading = true
		this.filter()
		// this.$store.dispatch('accounts/fetchAll', { regionIds: this.regionIds, name: this.name, type: this.accountTap, nonDeleted: true, IncludeContactInfo: true, includeAddresses: true, perPage: 20, page: 1 }).finally(() => {
		// 	this.loading = false
		// })
		Promise.all([
			// this.$store.dispatch('rolePermission/fetchAll'),
			this.$store.dispatch('workload/fetchAll'),
			!this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
			// !this.sectors.length ? this.$store.dispatch('sectors/fetchAll') : null,
			!this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
				this.$store.dispatch('regions/fetchAllRegions', { cities: data })
                // this.$store.dispatch('sectors/fetchSectorCities', { cities: data })
            }) : null,
			!this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true).then((data) => {
				this.items = data
			}) : null
		]).finally(() => {
			this.loading = false
		})
    }
}
</script>

<style scoped>
.style-tree {
    /* background: rgba(238, 236, 236, 0.726); */
    background: #118aa825;
    border-radius: 10px;
}
.blue {
	background: transparent;
}
</style>