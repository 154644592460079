export const logo = require('@/assets/logo-eng (2).png');
export const drawerLogos = {
    normal: require('@/assets/brands/ibn-alhaythem.png'),
    mini: require('@/assets/brands/ibn-alhaythem-mini.png')
};

export const colors = {
    primary:  '#0b5b6f',
    // '#', // #253c99  #014a97  #  041943 
    primaryLight: '#a0ecff',
    //  '#', // #b2c1ff #c0dfff #  ccddfd
    secondary: '#f57f20', // #f57f20 // # f57f20 5371ad
    lightBlue: '#0996b9', // #0996b9 #103aa2 # 0996b9 85b901
}