import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        supervisors:[]
    },

    actions:{
        fetchAll({ commit }, nonDeleted = null){
            return window.axios.get('/employees/supervisors', {
                params: {
                    nonDeleted
                }
            }).then((response) => {
                commit(types.STORE_SUPERVISOR, response.data);
                return response.data;
            })
        },

        fetchByFilter({ commit }, { name, regionIds }){
            var urlSearchParams = new URLSearchParams();
            if (name !== null) urlSearchParams.append('name', name);
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id);
            });
            urlSearchParams.append('nonDeleted', true);
            return window.axios.get('/employees/supervisors', {
                params:urlSearchParams
            })
            .then((response) => {
                return response.data;
            })
        },

        create({ commit }, { data }){
            return window.axios.post('/employees/supervisors', data);
        },

        update({ commit }, { id, data }){
            return window.axios.put(`/employees/supervisors/${id}`, data);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/employees/${id}`);
        },
        
		toggle({ commit }, { id }){
            return window.axios.put(`/employees/${id}/toggle`);
        },
    },

    mutations:{
        [types.STORE_SUPERVISOR](state, supervisors){
            state.supervisors = supervisors;
        },
    },

    getters:{
        getSupervisorById: state => id => state.supervisors.find(c => c.id === id)
    }
}