export function dig(getterName, id, prop){
    var object = this[getterName](id);
    if (prop === 'cityName') {
        var array = object;
        return array.join(', ');
    }
    return object ? object[prop] : null;
}

export function getMaterialName(material, formName) {
    if (!material) return;

    var details = [
        formName,
        material.dose,
        material.strength
    ];

    return `${material.name} (${details.filter(c => c != null).join(' ')})`;
}

export function findClassification(workload){
    switch (workload){
        case 0: return 'A+';
        case 1: return 'A';
        case 2: return 'A-';
        case 3: return 'B+';
        case 4: return 'B';
        case 5: return 'B-';
        case 6: return 'C';
    }
}

export function downloadBlob(blob, fileName) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName || true;
	a.click();
	a.remove();
}