import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        materials:[],
        myMaterials: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/materials').then((response) => {
                commit(types.STORE_MATERIALS, response.data)
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/materials/${id}`).then((response) => {
                return response.data;
            })
        }, 
        
        fetchByFilter({ commit }, { name, materialFormId }){
            return window.axios.get('/materials', {
                params:{
                    name,
                    materialFormId
                }
            })
            .then((response) => {
                return response.data
            })
        },

        create({ commit }, { data }){
            return window.axios.post('/materials', data);
        },

        update({ commit }, { id, data }){
            return window.axios.put(`materials/${id}`, data);
        },

        fetchMyMaterial ({ commit }, { employeeId }) {
            return window.axios.get('materials/mine', { params: { employeeId } }).then(({ data }) => {
                commit(types.STORE_MINE_MATERIALS, data)
                return data
            })
        }
    },

    mutations:{
        [types.STORE_MATERIALS](state, materials){
            state.materials = materials.sort(function(a, b) { if (a.name < b.name) { 
                return -1
            }; 
            if (a.name > b.name) {
                return 1 
            }; 
                return 0 
            });
        },

        [types.STORE_MINE_MATERIALS] (state, myMaterials) {
            state.myMaterials = myMaterials
        }
    },

    getters:{
        getMaterialById: state => id => state.materials.find(c => c.id === id),
        getMaterialByName: state => name => state.materials.find(c => c.name === name)
    }
}