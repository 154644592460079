import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        specialties:[],
        specialty:{}
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/specialties').then((response) => {
                commit(types.STORE_SPECIALTIES, response.data);

                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/specialties/${id}`).then((response) => {
                commit(types.STORE_SPECIALTY, response.data);

                return response.data;
            })
        },

        create({ commit }, { specialty }){
            return window.axios.post('/specialties', specialty);
        },

        update({ commit }, { id, specialty }){
            return window.axios.put(`/specialties/${id}`, specialty);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/specialties/${id}`);
        },
    },

    mutations:{
        [types.STORE_SPECIALTIES](state, specialties){
            state.specialties = specialties.sort(function(a, b) { if (a.name < b.name) { 
				return -1
			}; 
			if (a.name > b.name) {
				return 1 
			}; 
			return 0 
		});
        },

        [types.STORE_SPECIALTY](state, specialty){
            state.specialty = specialty;
        },
    },

    getters:{
        getSpecialtyById: state => id => state.specialties.find(c => c.id === id)
    }
}