import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
        sectors: [],
        sectorsFiltered: [],
        sectorCities: {
            sectors: []
        },
        citiesBySectorFilter: [],
        sectorsMine: []
	},

	actions: {
		fetchAll({ commit }) {
            return window.axios.get('/sectors').then(response => {
                commit(types.STORE_SECTORS, response.data);

                return response.data;
            });
        },
        
		fetchByCityId({ commit }, { cityId }) {
            return window.axios.get("/sectors", {
                params:{
                    cityId
                }
            }).then(response => {
                commit(types.STORE_SECTORS_FILTERED, response.data);
                return response.data;
            });
        },
        
        fetchSectorCities({ commit, dispatch }, { cities }) {
            const promises = [];

            commit(types.CLEAR_SECTOR_CITY);
            cities.forEach(city => {
                promises.push(
                    dispatch('fetchByCityId', { cityId: city.id })
                        .then(sectors => {
                            commit(types.STORE_SECTOR_CITY, { cityId: city.id, cityName: city.name, sectors });
                        })
                )
            });

            return Promise.all(promises);
        },
	
        create({ commit }, { name, regionIds }) {
            return window.axios.post('/sectors', {
                name,
                regionIds
            });
        },

        update({ commit }, { id, name, regionIds }) {
            return window.axios.put(`/sectors/${id}`, {
                name,
                regionIds
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/sectors/${id}`);
        },

        fetchCitiesInSector({ commit }, { id }) {
            return window.axios.get(`/Sectors/${id}/Cities`).then(({ data }) => {
                commit(types.STORE_CITIES_IN_SECTOR, data)
                return data
            })
        },

        getSectorsMine({ commit }) {
            return window.axios.get('sectors/mine').then(({ data }) => {
                commit(types.STORE_SECTOR_MINE, data)
                return data
            })
        }
	},

    mutations: {
		[types.STORE_SECTORS](state, sectors){
			state.sectors = sectors;
        },

		[types.STORE_SECTORS_FILTERED](state, sectors){
			state.sectorsFiltered = sectors;
        },

            // { cityId, cityName, sectors }
        [types.STORE_SECTOR_CITY](state, sectorCities) {
            // state.sectorCities.push({
            //     cityId,
            //     cityName,
            //     sectors
            // });
            state.sectorCities = sectorCities
        },

        [types.CLEAR_SECTOR_CITY](state) {
            state.sectorCities = [];
        },

        [types.STORE_CITIES_IN_SECTOR](state, citiesBySectorFilter) {
            state.citiesBySectorFilter = citiesBySectorFilter
        },
        [types.STORE_SECTOR_MINE](state, sectorsMine) {
            state.sectorsMine = sectorsMine
        }
	},

	getters: {
        getSectorById: state => id => state.sectors.find(c => c.id === id),
        getCityNameBySectorId: state => sectorId => state.sectorCities.sectors?.filter(c => c.sectors?.filter(v => v.id === sectorId).length > 0).map(c => c.cityName),
        getSectorCitiesById: state => id => state.sectorCities.sectors?.find(c => c.id === id),
	},
}