import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        target: null
    },

    actions:{
        fetch({ commit }, { id }){
            return window.axios.get(`/targets/${id}`)
            .then((response) => {
                commit(types.STORE_MEDICALREP_TARGET, response.data.value);
                return response.data.value;
            });
        },
        
        update({ commit }, { id, value }){
            return window.axios.post(`/targets/${id}`, { value });
        }
    },

    mutations:{
        [types.STORE_MEDICALREP_TARGET](state, target){
            state.target = target;
        }
    },

    getters:{

    }
}