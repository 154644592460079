<template>
    <div>
        <div class="d-flex justify-space-between">            
            <div class="d-sm-flex">
                <!-- activate dialog button -->
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.GenerateMaterials === roleName.action"
                    small
                    color="light-blue white--text"
                    class="mx-2 my-1"
                    @click="addProduct"
                >
                    <v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{$t('Products.add-product')}}
                </v-btn>

                <v-btn
                    v-if="isSuperAdmin || permissionsUser.GenerateMaterials !== roleName.nothing"
                    :to="{ name: 'pharmaceutical-forms' }"
                    color="light-blue white--text"
                    small
                    class="my-1"
                >
                    {{$t('Products.pharmaceutical-forms')}}
                </v-btn>
            </div>
        </div>
        
        <!-- dialogs is declared here to use as ref -->
        <v-dialog
            transition="dialog-transition"
            max-width="600"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex primary py-1">
                            <v-card-title
                                class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
                            >
                                {{ !materialId ? $t('Products.add-product') : $t('Products.edit-product') }}
                            </v-card-title>

                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false; $refs.form.resetValidation()">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-2 px-3 pb-0">
                            <!-- dialog content -->
                            <v-row dense class="ma-0">
                                <!-- Number -->
                                <v-col sm="6" md="4" cols="12">
                                    <v-text-field
                                        v-model="materialData.number"
                                        :label="$t('inputs.number')"
                                        type="number"
                                        min="0"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Product name -->
                                <v-col sm="6" md="4" cols="12">
                                    <v-text-field
                                        v-model="materialData.name"
                                        :label="$t('inputs.product-name')"
                                        clearable
                                        :rules="rules.required"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- pharmaceutical form -->
                                <v-col sm="6" md="4" cols="12">
                                    <v-autocomplete
                                        v-model="materialData.materialFormId"
                                        :label="$t('inputs.pharmaceutical-form')"
                                        clearable
                                        :items="pharmaceuticalForm"
                                        item-text="name"
                                        item-value="id"
                                        :rules="rules.required"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- Volume -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="materialData.dose"
                                        :label="$t('inputs.volume')"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Pills per box -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="materialData.pillsPerBox"
                                        :label="$t('inputs.pills-per-box')"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Strength -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model="materialData.strength"
                                        :label="$t('inputs.strength')"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- min Amount -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="materialData.minAmount"
                                        :label="$t('inputs.min-amount')"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons
                                    ></v-text-field>
                                </v-col>

                                <!-- max amount -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="materialData.maxAmount"
                                        :label="$t('inputs.max-amount')"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons   
                                    ></v-text-field>
                                </v-col>

                                <!-- Price -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="materialData.price"
                                        :label="$t('inputs.price')"
                                        type="number"
                                        min="0"
                                        step="50"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- product file  -->
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="materialData.leafletFilePath"
                                        :label="$t('inputs.leaflet-url')"
                                        class="align-self-center"
                                        :prepend-icon="materialData.leafletFilePath?'mdi-link-box-variant':'mdi-link-box-variant-outline'"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- description & image -->
                                <v-col cols="12">
                                    <v-row dense class="ma-0">
                                        <!-- description -->
                                        <v-col cols="12" class="px-0">
                                            <v-textarea
                                                v-model="materialData.description"
                                                label="Description"
                                                rows="3"
                                                no-resize
                                                auto-grow
                                                outlined
                                                dense
                                                hide-details="auto"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end pt-0">
                            <v-btn
                                type="submit"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                color="success"
                                text
                            >
                                {{$t('save')}}
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                text
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>
        
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
        	ref="deleteDialog"
            @on-delete="filter"
        	success-message="Data is deleted"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{$t('delete')}}</span> {{$t('the-product')}}
                </p>
            </template>
        </delete-dialog>

        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; filter();" :disabled="loading">
            <v-row dense class="my-1" align="center">
                <!-- product name -->
                <v-col md="3" sm="6" cols="12">
                    <v-autocomplete
                        v-model="materialName"
                        :items="loading ? [] : materials"
                        :item-text="getFullMaterialName"
                        item-value="name"
                        :label="$t('inputs.product-name')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- pharmaceutical form -->
                <v-col md="3" sm="6" cols="12">
                    <v-autocomplete
                        v-model="materialFormId"
                        :items="materialForms"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.pharmaceutical-form')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <v-spacer />
            
                <v-col class="d-flex justify-start justify-md-end">
                    <v-btn
                        type="submit"
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="isAllFieldsEmpty || loading"
                        small
                    >
                        <v-icon> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        color="alert-color white--text"
                        class="px-sm-8"
                        small
                        :disabled="!isFiltered || loading"
                        @click="clearFilter(); filter()"
                    >
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="materialsFiltered"
            fixed-header
            :items-per-page="20"
            pagination-on-scroll
            :height="$vuetify.breakpoint.height - 160"
            zebra
            dense
            :loading="loading"
            :data-table-props="{
                disableSort: true
            }"
        >
            <!-- action column will appaer only for admin -->
            <template v-slot:item.action="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('Products.edit-product')" top>
                        <v-btn icon small @click="editProduct(item.id)" color="edit-color">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>    
</template>

<script>
import Tooltip from '@/components/Tooltip'
import DeleteDialog from '@/components/DeleteDialog'
import { mapState, mapGetters } from 'vuex'
import { getMaterialName } from '@/helpers/functions'
import rules from '@/validation rules'
import { roleName } from '@/helpers/enums'

export default {
    name: 'Products',
    
    components:{
        Tooltip,
        DeleteDialog
    },

    data(){
        return {
            roleName,
            // filter
            materialName: null,
            materialFormId: null,
            isFiltered: false,
            materialsFiltered: [],

            // helpers
            loading: false,
            submitLoading: false,
            rules,

            // product dialog
            materialId: null,

            // data
            materialData:{
                name: null,
                materialFormId: null,
                dose: null,
                strength: null,
                pillsPerBox: null,
                number: null,
                price: null,
                description: null,
                leafletFilePath: null,
                maxAmount: 0,
                minAmount: 0
            },
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return !this.materialName && !this.materialFormId;
        },

        ...mapState({
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            pharmaceuticalForm: state => state.materialForms.materialForms,
            specialties: state => state.specialties.specialties,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            getMaterialFormById: 'materialForms/getMaterialFormById',
            isAdmin: 'auth/isAdmin',
            getMaterialById: 'materials/getMaterialById',
            getSpecialtyById: 'specialties/getSpecialtyById'
        }),

        headers() {
            const headers = [
                { 
                    text: this.$t('headers.product-name'), 
                    align: 'start', 
                    value: 'name',
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'primary--text',
                },
                {
                    text: this.$t('headers.pharmaceutical-form'), 
                    value: 'materialFormId', 
                    setValueExpr: val => val ? this.getMaterialFormById(val)?.name : '-',
                    align: 'start', 
                    noNotAvailableText: true,
                    class: 'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.volume'), 
                    value: 'dose', 
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.max-amount'), 
                    value: 'maxAmount', 
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    columnClass: 'px-2',
                },
                { 
                    text: this.$t('headers.min-amount'), 
                    value: 'minAmount', 
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.pills-per-box'), 
                    value: 'pillsPerBox', 
                    align: 'center', 
                    class: 'text-body-2 font-weight-bold',

                },
                { 
                    text: this.$t('headers.strength'), 
                    value: 'strength', 
                    align: 'start',
                    class: 'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.price'), 
                    value: 'price', 
                    setValueExpr: val => val !== null ? val : '-',
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('headers.description'), 
                    value: 'description', 
                    setValueExpr: val => val !== null ? val : '-',
                    align: 'start', 
                    class: 'text-body-2 font-weight-bold',
                    width: 300,
                    noNotAvailableText: true,
                }
            ];

            if (this.isSuperAdmin || this.permissionsUser.MaterialTransactions === roleName.action){
                headers.push({ text: this.$t('headers.actions'), name: 'action', value: 'action', align: 'center', cellClass:'width', sortable: false })
            }

            return headers;
        },

        iconSpecialty() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        getFullMaterialName(material) {
            return getMaterialName(material, this.getMaterialFormById(material.materialFormId).name)
        },

        // filter
        clearFilter(){
            this.materialName = null;
            this.materialFormId = null;
            this.isFiltered = false;
        },
        
        filter(isFirstLoad = false){
            this.loading = true;
            return this.$store.dispatch('materials/fetchByFilter', {
                name: this.materialName,
                materialFormId: this.materialFormId
            }).then((data) => {
                this.materialsFiltered = data;
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        // product dialog
        resetInputs(){
            if (this.$refs.form) this.$refs.form.resetValidation();
            this.materialId = null;

            this.materialData.name = null;
            this.materialData.materialFormId = null;
            this.materialData.dose = null;
            this.materialData.strength = null;
            this.materialData.pillsPerBox = null;
            this.materialData.number = null;
            this.materialData.price = null;
            this.materialData.description = null;
            this.materialData.leafletFilePath = null;
            this.materialData.minAmount = 0;
            this.materialData.maxAmount = 0;
        },

        addProduct(){
            this.resetInputs();
            this.$refs.dialog.isActive = true;
        },

        editProduct(materialId) {
            this.resetInputs();
            this.materialId = materialId;
            this.$refs.dialog.isActive = true;
            var material = this.getMaterialById(materialId);
            
            this.materialData.name = material.name;
            this.materialData.materialFormId = material.materialFormId;
            this.materialData.dose = material.dose;
            this.materialData.strength = material.strength;
            this.materialData.pillsPerBox = material.pillsPerBox;
            this.materialData.number = material.number;
            this.materialData.price = material.price;
            this.materialData.description = material.description;
            this.materialData.leafletFilePath = material.leafletFilePath;
            this.materialData.maxAmount = material.maxAmount;
            this.materialData.minAmount = material.minAmount
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                const actionName = !this.materialId ? 'create' : 'update';
                this.$store.dispatch(`materials/${actionName}`, {
                    id: this.materialId,
                    data: this.materialData
                })
                .then(() => {
                    const message = 'Product is ' + (!this.materialId ? 'created' : 'edited');
                    this.$eventBus.$emit('show-snackbar', message, 'info');
                    this.$store.dispatch('materials/fetchAll');
                    this.filter();
                    this.$refs.dialog.isActive = false;
                })
                .finally(() => {
                    this.submitLoading = false;
                })
            }
        }
    },

    created(){
        this.loading = true;
        Promise.all([
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.materialForms.length ? this.$store.dispatch('materialForms/fetchAll') : null,
            this.$store.dispatch('specialties/fetchAll'),
            this.filter(true)
        ]).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Products',
        titleTemplate: '%s | Octopharma'
    }
}
</script>