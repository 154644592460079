<template>
    <div>
        <delete-dialog ref="DeleteVisitDialog" @on-delete="fetchAllDataInCalendar()">
            <p class="mb-0 text-body-1">
                Are you sure you want to <span class="red--text">DELETE</span> the visit ?
            </p>
        </delete-dialog>
        <!-- visit dialog -->
        <visit-dialog ref="VisitDialogAdd" @on-save="fetchAllDataInCalendar" :create="dateEvent" />
        <v-dialog
            v-model="VisitDialog"
            transition="dialog-transition"
            max-width="700"
        >
            <!-- dialog content -->
            <v-card>
                <div class="d-flex justify-end background-title-card white--text">
                    <v-card-title
                        class="d-flex w-100 justify-center py-1 d-flex"
                    >
                        Visit details
                    </v-card-title>
                    <v-btn icon @click="VisitDialog = false; entityTypeCustomer = 1" class="me-2">
                        <v-icon color="white" > mdi-close-circle-outline </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="pb-0 pt-1 px-3">
                    <!-- dialog content -->
                    <!-- samples table -->
                    <div>
                        <p class="text-body-2 font-weight-bold my-1 mb-2">Distributed samples</p>
                        <bee-handy-smart-table
                            ref="sampleTable"
                            v-model="sampleTableInputs"
                            :items="entriesSamples"
                            :headers="headerSamples"
                            zebra
                            dense
                            height="auto"
                            pagination-on-scroll
                            show-add-row
                            :editable-rows="!loading"
                            @keypress.enter="isEditSample ? editSamples() : addSamples()"
                        >
                            <!-- product name -->
                            <template v-slot:input.materialId="{ on, attr }">
                                <v-autocomplete
                                    :items="materials"
                                    item-value="id"
                                    item-text="name"
                                    v-on="on"
                                    v-bind="attr"
                                ></v-autocomplete>
                            </template>

                            <!-- action column -->
                            <template v-slot:item.actions="{ item, index }">
                                <div class="d-flex">
                                    <v-btn
                                        icon
                                        small
                                        color="edit-color"
                                        :disabled="loading"
                                        @click="() => {
                                            $refs.sampleTable.activeEditForRow(index);
                                            editAddressRowIndex = index;
                                            isEditSample = true;
                                            $nextTick(() => {
                                                $refs.sampleTable.resetInputsFocus()
                                            })
                                        }"
                                    >
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        small
                                        @click="deleteSamplesRow({ id: item.id, index })"
                                        color="alert-color"
                                    >
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </bee-handy-smart-table>
                    </div> 

                    <v-row class="mt-1">
                        <!-- other samples table  v-if="others"-->
                        <v-col cols="4">
                            <v-text-field
                                v-model="otherSamples"
                                class="mb-1"
                                label="Other samples"
                                dense
                                outlined
                                hide-details
                            >
                            </v-text-field>
                        </v-col>

                        <v-divider vertical class="my-1" />

                        <!-- durationInMinutes-->
                        <v-col cols="4">
                            <v-text-field
                                v-model.number="durationInMinutes"
                                class="mb-1"
                                label="Duration In Minutes"
                                dense
                                outlined
                                hide-details
                                type="number"
                                hide-spin-buttons
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- coreMessage -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="coreMessage"
                                class="mb-1"
                                label="Core message"
                                dense
                                outlined
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        
                        <v-divider vertical class="my-1" />

                        <!-- Notes -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="notes"
                                class="mb-1"
                                label="Notes"
                                dense
                                outlined
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- Objections-->
                        <v-col cols="4">
                            <v-text-field
                                v-model="objections"
                                class="mb-1"
                                label="Objections"
                                dense
                                outlined
                                hide-details
                            >
                            </v-text-field>
                        </v-col>

                        <!-- notes -->
                        <v-col cols="4" >
                            <v-text-field
                                v-model="nextCall"
                                class="mb-1"
                                label="Next Call"
                                dense
                                outlined
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!-- Unavailable octo Products -->
                    <div v-if=" entityTypeCustomer  !== entityType.doctor" class="mt-4">
                        <!-- <p class="text-body-2 mb-1 font-weight-bold">Unavailable octo Products</p> -->
                        <v-textarea v-if="0" dense outlined rows="1" hide-details />
                        <v-autocomplete
                            v-model="unavailablesMaterials"
                            :items="materials"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            label="Unavailable octo Products"
                            multiple
                        />
                    </div>

                    <!-- competitive samples  -->
                    <div v-if="entityTypeCustomer !== entityType.doctor" class="my-3">
                        <v-textarea
                        	v-model="competitiveSamples"
                        	label="Competitors"
                        	outlined
                        	dense
                        	hide-details
                        	rows="1"
                        />
                    </div>

                    <!-- center notes -->
                    <div v-if="entityTypeCustomer === entityType.center">
                        <v-text-field
                            v-model="centerNotes"
                            class="mb-1"
                            label="Center notes"
                            dense
                            outlined
                            hide-details
                        >
                        </v-text-field>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text color="success" @click="update()">
                        Save
                    </v-btn>
                    <!-- dialog active button -->
                    <v-btn text @click="VisitDialog = false; entityTypeCustomer = 1">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- add dialog -->
        <v-dialog v-model="dialog" width="500px">
            <v-card>
                <div class="d-flex justify-end background-title-card mb-3">
                    <v-card-title
                        class="d-flex justify-center w-100 text-uppercase text-body-2 justify-center py-0 white--text"
                    >
                        {{ activityId ? 'Edit Activity' : 'Add new Activity' }}
                    </v-card-title>
                    <!-- close icon button -->
                    <v-btn icon @click="dialog = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <v-card-text>
                    <h4 v-if="employeeId === userLogin"> select activity </h4>
                    <v-radio-group
                        v-if="employeeId === userLogin"
                        row
                        hide-details
                        v-model="visitType"
                        dense
                        class="d-flex justify-start mt-0 mb-2 mx-0"
                    >
                        <v-radio label="Activities" :value="2" class="" color="success"/>
                        <v-radio label="Visits" :value="0" class="" color="success"/>
                        <v-radio label="Double visit" :value="1" class="" color="success"/>
                        
                    </v-radio-group>
                    <v-row :class="{ 'pt-2': employeeId !== userLogin }" >
                        <v-col cols="12" :sm="employeeId === userLogin ? visitType === 2 ? '12' : '6' : '12'" class="pa-1">
                            <bee-date-picker
                                v-model="dateEvent"
                                input-label="Date"
                                elevation="2"
                                menu-picker
                                dense
                                hide-details
                                :input-props="{
                                    outlined: true,
                                    dense: true
                                }"
                                months-over-max="1"
                                days-over-max="30"
                                min="1940-01-01"
                                :disabled="loading"
                            ></bee-date-picker>
                        </v-col>
                        
                        <v-col v-if="employeeId === userLogin && visitType === 1" cols="12" sm="6" class="pa-1">
                            <v-autocomplete
                            	v-model="medicalRepId"
                                :items="medicalReps.filter(c => c.employeeType === userType.medicalRep)"
                                item-text="name"
                                item-value="id"
                            	outlined
                            	dense
                            	label="Medical Rep name"
                            	hide-details
                            />
                        </v-col>

                        <v-col
                        	v-if="(employeeId === userLogin && visitType === 2) ||  employeeId !== userLogin"
                        	cols="12"
                        	sm="12"
                        	class="pa-1"
                        >
                            <v-textarea
                            	v-model="activity"
                            	outlined
                            	dense
                            	label="Activities"
                            	hide-details
                                :rows="2"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                    	@click="submit(); fetchAllDataInCalendar()"
                    	text
                    	color="success"
                    	:loading="submitLoading"
                    >
                        Save
                    </v-btn>

                    <v-btn
                    	text
                    	color="alert-color"
                    	@click="dialog = false"
                    	:disabled="submitLoading"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="500"
            v-model="deleteDialog"
        >
            <v-card>
                <div class="d-flex justify-end red-light-one-bg py-1">
                    <v-card-title
                        class="text-uppercase justify-center py-0 white--text text-subtitle-1 d-flex justify-center w-100 px-0 py-0"
                    >
                        {{$t('confirm')}} {{$t('delete')}}
                    </v-card-title>
                    <v-btn icon @click="deleteDialog = false">
                        <v-icon color="white">mdi-close-circle-outline</v-icon>
                    </v-btn>
                </div>

                <v-card-text class="text-body-1 pb-0 px-4 pt-2">
                    Are you sure you want to delete the activity? 
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        @click="submit(); fetchAllDataInCalendar()"
                        :loading="submitLoading"
                        color="red lighten-1 white--text"
                        elevation="0"
                        text
                    >
                        {{$t('delete')}}
                    </v-btn>
                    <v-btn
                        @click="deleteDialog = false"
                        :disabled="submitLoading"
                        elevation="0"
                        text
                    >
                        {{$t('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-form ref="form">
            <v-row dense class="ma-0">
                <!-- employeeId -->
                <v-col cols="12" sm="6" md="2">
                    <v-autocomplete
                        v-model="employeeId"
                        :items="medicalReps.filter(c => c.employeeType !== userType.medicalRep)"
                        item-text="name"
                        item-value="id"
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :loading="loading"
                        :disabled="loading"
                        @change="disable = false; fetchAllDataInCalendar()"
                    />
                </v-col>

                <v-col cols="12" sm="4" md="2">
                    <bee-date-input
                        v-model="date"
                        append-icon="mdi-menu-right"
                        @click:append="upDay(); fetchAllDataInCalendar()"
                        prepend-icon="mdi-menu-left"
                        @click:prepend="downDay(); fetchAllDataInCalendar()"
                        :label="date ? `${moment(date).format('MMMM')}` : 'Date'"
                        dense
                        :loading="loading"
                        input-format-order="year-month-day"
                        input-type="month"
                        readonly
				    ></bee-date-input>
                </v-col>

                <v-spacer />
            </v-row>
        </v-form>

        <v-sheet 
            height="" 
            :class="{
                'pt-3': $vuetify.breakpoint.smAndDown
            }"
        >
            <v-calendar
                ref="calendar"
                v-model="value" 
                color="primary"
                type="month"
                :events="events"
                :event-more="false"
                interval-height="50"
                :height-event="10"
                @click:event="showEvent"
            >
                <template v-slot:event="{ event, timed, eventSummary }">
                    <div class="v-event-draggable">
                        <p class="text-center" v-if="eventSummary"> 
                            {{event.type === 'visits' ? 'V' : event.type === 'double' ? 'DV.' : 'AC.'}}
                            {{event.name}}
                        </p>
                    </div>
                    <div
                        v-if="timed"
                        class="v-event-drag-bottom"
                        @mousedown.stop="extendBottom(event)"
                    ></div>
                </template>

                <template v-slot:day="{ date }">
                    <div style="width: 100%" class="d-flex justify-center align-items-center">
                        <v-btn
                            icon
                            color="success"
                            class="d-flex justify-center"
                            @click="dateEvent = date; eventDate = null; activityId = null; dialog = true"
                            :disabled="loading || moment(value).isAfter(moment(), 'month') || disable || !moment(date).isSame(value, 'month')"
                        >
                            <v-icon> mdi-plus-circle </v-icon>
                        </v-btn>
                    </div>
                </template> 
            </v-calendar>
        </v-sheet>

        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
            <v-list>
                <v-list-item
                    dense
                    @click="
                        type === 'activity' ? dialog = true : type === 'visits' ? fetchVisitData(null, visitId) : null
                    "
                    :loading ="submitLoading"
                >
                    <v-row>
                        <v-col cols="3" class="py-0" align-self="center">
                            <v-list-item-icon>
                                <v-btn icon small>
                                <v-icon color="edit-color" >
                                    mdi-pencil
                                </v-icon>
                                </v-btn>
                            </v-list-item-icon>
                        </v-col>

                        <v-col cols="9" class="py-0" align-self="center">
                            <v-list-item-title>Edit visit</v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>

                <v-list-item
                	dense
                	@click="isDeleted = true; type === 'activity' ? deleteDialog = true : $refs.DeleteVisitDialog.activeDeleteDialog(visitId, 'visits')"
                >
                    <v-row>
                        <v-col cols="3" class="py-0" align-self="center">
                            <v-list-item-icon>
                                <v-icon color="alert-color">
                                    mdi-delete
                                </v-icon>
                            </v-list-item-icon>
                        </v-col>

                        <v-col cols="9" class="py-0" align-self="center">
                            <v-list-item-title>Delete visit</v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import rules from '@/validation rules'
import { userType, entityType } from '@/helpers/enums'    
import moment from 'moment'
import VisitDialog from '@/components/VisitDialog.vue'
import DeleteDialog from '@/components/DeleteDialog'

export default {
    name: 'SupervisorReport',
    components: {
        VisitDialog,
        DeleteDialog
    },

    data() {
        return {
            entityIForRow: null,
            entityTypeCustomer: 1,
            type: null,
            editAddressRowIndex: null,
            sampleTableInputs: {},
            entriesSamples: [],
            entityType,
            samples: [],
            otherSamples: null,
            notes: null,
            objections: null,
            coreMessage: null,
            durationInMinutes: 0,
            nextCall: null,
            unavailablesMaterials: [],
            competitiveSamples: [],
            centerNotes: null,
            VisitDialog: false,
            isDeleted: false,
            disable: false,
            date: null,
            deleteDialog: false,
            dateEvent: null,
            eventDate: null,
            selectedOpen: false,
            activity: null,
            selectedElement: null,
            events: [],
            dialog: false,
            employeeId: null,
            employeeReport: [],
            submitLoading: false,
            loading: false,

            rules,
            pastOffOfficeActivities: null,
            pastOfficeActivities: null,
            plannedOfficeActivities: null,
            supervisorActivities: [],
            plannedOffOfficeActivities: null,
            visitedHospitals:  null,

            // visits
            // visits: [],

            // data
            unavailableMaterials:  null,
            activityId: null,
            value: null,
            userType,
            medicalRepId: null,
            entityId: null,
            visitType: 2,
            showVisit: false,
            dateVisit: null,
            visitId: null 
        }
    },

    watch: {
        dialog(val) {
            if (this.activityId !== null) {
                this.activity = this.employeeReport.filter(c => this.moment(c.date).isSame(this.eventDate) && this.activityId === c.id)[0]?.activity
            } else {
                this.activityId = null
                this.medicalRepId = null
                this.activity = null
                this.visitType = 2
            }
        },

        visitType(val) {
            if (val === 0) {
                this.$refs.VisitDialogAdd.addVisit()
            } else if (val === 1) {
                this.$router.replace({
                    name: 'double-visit-report',
                    query: {
                        from: 'report'
                    }
                })
            }
        },
    },

    computed: {
        headerSamples() {
            return [
                {
                    text: 'Samples',
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: 'quantity',
                    name: 'amount',
                    value: 'amount',
                    type: 'number'
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                }
            ]
        },

        headers() {
            const headers = [
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    dateFormat: 'DD-MM-YYYY',
                    type: 'date',
                    inputRules: [
                        val => this.isBetweenStartAndEnd(val) ||
                        'must be between the start and end dates'
                    ],
                    width: 180
                }, 
                {
                    text: 'Supervisor activities',
                    name: 'activity',
                    value: 'activity',
                    inputRules: rules.required
                }
            ];

            if (!this.$route.query.preview) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    align: 'center',
                    width: 80,
                    sortable: false,
                    noInput: true,
                });
            }

            return headers
        },
        ...mapState({
            userData: state => state.auth.userData, 
            reports: state => state.supervisorReports.reports, 
            specialties: state => state.specialties.specialties, 
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions,
            doubleVisits: state => state.doubleVisit.doubleVisits,
            medicalReps: state => state.medicalreps.medicalreps,
            userLogin: state => state.auth.userData.employeeId,
            entitiesBrief: state => state.entities.entitiesBrief,
            cities: state => state.cities.cities,
            myVisits: state => state.mineVisits.visits,
            materials: state => state.materials.materials,
            transactions: state => state.materialTransactions.transactions,
            materialForms: state => state.materialForms.materialForms,
        }),
        ...mapGetters({
            getReportById: 'supervisorReports/getReportById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            isAdmin: 'auth/isAdmin',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getMaterialById: 'materials/getMaterialById',

        }),

        headersVisits() {
            const headersVisits = [
                {
                    text: 'Doctor',
                    name: 'doctor',
                    value: 'entity.name'
                },
                {
                    text: 'specialty',
                    name: 'specialty',
                    value: 'entity.specialtyIds',
                    noNotAvailableText: true,
                    setValueExpr: val => {
                        const specialties = [];
                        val && val.forEach(specialtyId => {
                            specialties.push(this.getSpecialtyById(specialtyId)?.name)
                        });

                        return specialties.length ? specialties.join(', ') : '-';
                    }
                },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'createdAt',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                },
                {
                    text: 'Note',
                    name: 'notes',
                    value: 'notes'
                }
            ]
            return headersVisits
        },

        headersDouble() {
            const headersDouble = [
                {
                    text: 'Medical rep name',
                    name: 'medicalRep',
                    value: 'medicalRepId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                // {
                //     text: 'Doctor',
                //     name: 'doctor',
                //     value: 'doctor'
                // },
                // {
                //     text: 'specialty',
                //     name: 'specialty',
                //     value: 'specialtyId'
                // },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                },
                {
                    text: 'Note',
                    name: 'note',
                    value: 'note'
                }
            ]
            return headersDouble
        }
    },

    methods: {
        // samples
        deleteSamplesRow(rowData) {
            this.$refs.sampleTable.resetEdit();
			this.isEditSample = false;
			this.entriesSamples.splice(rowData.index, 1);

            for (let i = 0; i < this.entriesSamples.length; i++) {
                if (this.entriesSamples[i].id > rowData.id) {
                    this.entriesSamples[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.sampleTableInputs.resetInputs();
				this.$refs.sampleTableInputs.resetInputsFocus()
			})
		},
        editSamples() {
            if (this.$refs.sampleTable.validate()) {
                this.entriesSamples[this.editAddressRowIndex].materialId = this.sampleTableInputs.materialId
                this.entriesSamples[this.editAddressRowIndex].amount = this.sampleTableInputs.amount
                
                this.$refs.sampleTable.resetEdit();
                this.isEditSample = false;
                this.$nextTick(() => {
                    this.$refs.sampleTable.resetInputs();
					this.$refs.sampleTable.resetInputsFocus()
                })
			} 
        },
        addSamples() {
            if (this.$refs.sampleTable.validate()) {
                const itemInTable = this.entriesSamples.find(c => c.materialId === this.sampleTableInputs.materialId);
                if (itemInTable) {
                    itemInTable.amount = itemInTable.amount + 1
                } else {
                    this.entriesSamples.push({
                        ...this.sampleTableInputs,
                    })
                    this.entriesSamples.map((c, index) => ({ ...c, id: index }))
                }
                this.$refs.sampleTable.resetInputs();
                this.$refs.sampleTable.resetInputsFocus();
            }
        },
        // get data for visit
        fetchVisitData(type, visitId){
            this.submitLoading = true
            this.$store.dispatch('visits/fetchById', { id: this.visitId })
            .then((data) => {
                this.entriesSamples = data.materialAmounts.map((c, index) => ({ ...c, id: index }));
                this.otherSamples = data.otherSamples;
                this.notes = data.notes;
                this.objections = data.objections;
                this.nextCall = data.purpose;
                this.coreMessage = data.coreMessage;
                this.durationInMinutes = data.durationInMinutes;

                this.unavailablesMaterials = data.unavailableMaterialIds;
                this.competitiveSamples = data.competitiveSamples;
                this.centerNotes = null;
                
                this.VisitDialog = true;
                this.entityIForRow = data.entityId
                this.createdAtForVisit = data.createdAt
                this.entityTypeCustomer = data.entityType

                if (type === entityType.pharmacy || this.tab === 0) {
                    data.unavailableMaterialIds.forEach((element) => {
                        this.unavailablesMaterials.push(this.getMaterialById(element).name)
                    });
                    this.competitiveSamples = data.competitiveSamples;
                } else if (type === entityType.center || this.tab === 0) {
                    this.centerNotes = data.nextVisitNotes;
                }
                this.$store.dispatch('materialTransactions/fetchRemaining', { id: data.employeeId }).then((data) => {
                    this.materialsEmployee = data
                })
            }).finally(() => {
                this.submitLoading = false
            })
        },  

        showEvent ({ nativeEvent, event }) {
            this.type = event.type
            this.activityId = this.type === 'activity' ? event.id : null
            this.visitId = this.type === 'visits' ? event.id : null
            
            this.eventDate = this.moment(new Date(event.start)).format('YYYY-MM-DD')
            this.dateEvent = this.moment(new Date(event.start)).format('YYYY-MM-DD')

            const open = () => {
                requestAnimationFrame(() => requestAnimationFrame(() => {
                    if (event.type === 'activity') {    
                        this.selectedElement = nativeEvent.target 
                        this.showVisit = false
                    } else if (
                        event.type === 'visits' && (
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(1, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(2, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(3, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(4, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(5, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(6, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(7, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isAfter(moment(), 'day')
                        )) {
                        this.visitId = event.id
                        this.selectedOpen = true
                        this.dateVisit = moment(new Date(event.start)).format('YYYY-MM-DD')
                        this.selectedElement = nativeEvent.target
                    } else {
                        this.selectedOpen = false
                        this.showVisit = false
                    }
                }))

                if (
                    event.type === 'activity' || 
                    (
                        (event.type === 'visits') && 
                        (this.employeeId === this.userLogin) && 
                        (
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment(), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(1, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(2, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(3, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(4, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(5, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(6, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isSame(moment().subtract(7, 'days'), 'day') || 
                            moment(moment(new Date(event.start)).format('YYYY-MM-DD')).isAfter(moment(), 'day')
                        )
                    ) ||
                    (event.type === 'visits' && this.employeeId !== this.userLogin)) {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                }
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                this.showVisit = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },
        
        // update visit
        update() {
            this.$store.dispatch('visits/updateVisit', {
                id: this.visitId,
                entityId: this.entityIForRow, 
                otherSamples: this.otherSamples, 
                objections: this.objections,
                nextVisitNotes: this.centerNotes,
                purpose: this.nextCall, 
                materialAmounts: this.entriesSamples.map(c => ({ materialId: c.materialId, amount: c.amount })),
                unavailableMaterialIds: this.unavailablesMaterials, 
                competitiveSamples: [this.competitiveSamples], 
                createdAt: this.createdAtForVisit,
                coreMessage: this.coreMessage, 
                durationInMinutes: this.durationInMinutes,
                notes: this.notes
           }).then(() => {
                this.fetchAllDataInCalendar()
                this.VisitDialog = false
                this.entityTypeCustomer = 1
           }).finally(() => {
                this.visitId = null
           })
        },
        // add or edit report
        addOrEditReport() {
            const action = this.$route.params.id ? 'update' : 'create';
            return this.$store.dispatch(`supervisorReports/${action}`, {
                id: this.$route.params.id,
                fromDate: this.moment().startOf('month').format('YYYY-MM-DD'),
                toDate: this.moment().endOf('month').format('YYYY-MM-DD'),
                pastOfficeActivities: this.pastOfficeActivities,
                plannedOfficeActivities: this.plannedOfficeActivities,
                pastOffOfficeActivities: this.pastOffOfficeActivities,
                plannedOffOfficeActivities: this.plannedOffOfficeActivities,
                activities: this.employeeReport.filter(c => c.activity !== null),
                visitedHospitals: this.visitedHospitals,
                unavailableMaterials: this.unavailableMaterials,
                notes: this.notes
            })
            .then(() => {
                this.dialog = false
                this.deleteDialog = false
                this.submitLoading = false;
                this.$eventBus.$emit('show-snackbar', this.$route.params.id ? 'Report is edited' : 'Report added', 'info');
            }).catch(() => {
                this.dialog = false
                this.deleteDialog = false
                this.submitLoading = false;
            })
            .finally(() => {
                this.isDeleted = false
            })
        },
        submit() {
            this.loading = true
            this.submitLoading = true;
            if (this.employeeId !== this.userLogin) {
                if (this.activityId === null) {
                    this.employeeReport.push({
                        activity: this.activity,
                        date: this.dateEvent,
                    })
                } else {
                    this.employeeReport = this.employeeReport.filter(c => Number(c.id) !== this.activityId)
                    if (this.isDeleted === false) {
                        this.employeeReport.push({
                            activity: this.activity,
                            date: this.dateEvent,
                        })
                    }
                }
                this.addOrEditReport()
            } else {
                if (this.visitType === 1) {
                    this.$store.dispatch('doubleVisit/create', { date: this.dateEvent, medicalRepId: this.medicalRepId, entityId: null, details: [], regionsName: '', notes: null }).then(() => {
                        this.addOrEditReport().finally(() => {
                            this.dialog = false
                        })
                    })
                } else if (this.visitType === 2) {
                    if (this.activityId === null) {
                        this.employeeReport.push({
                            activity: this.activity,
                            date: this.dateEvent,
                        })
                    } else {
                        this.employeeReport = this.employeeReport.filter(c => Number(c.id) !== this.activityId)
                        if (this.isDeleted === false) {
                            this.employeeReport.push({
                                activity: this.activity,
                                date: this.dateEvent,
                            })
                        }
                    }
                    this.addOrEditReport()
                }   
            }
        },
        moment,

        upDay(){
            this.date = this.moment(this.date).add(1, 'month').format('YYYY-MM-DD')
            this.$refs.calendar.next()
			return this.date
        }, 

        downDay() {
            this.date = this.moment(this.date).subtract(1, 'month').format('YYYY-MM-DD')
            this.$refs.calendar.prev()
			return this.date
        },

        fetchAllReports(fromDate, toDate, employeeId) {
            return this.$store.dispatch('supervisorReports/fetchAll', {
                fromDate: fromDate,
                toDate: toDate,
                employeeId: employeeId
            })
        },

        fetchAllDataInCalendar(fromDate, toDate, employeeId) {
            this.events = []
            this.loading = true
            this.fetchAllReports(!fromDate ? this.moment(this.date).startOf('month').format('YYYY-MM-DD') : fromDate, !toDate ? moment(this.date).endOf('month').format('YYYY-MM-DD') : toDate, !employeeId ? this.employeeId : employeeId)
            .then((data) => {
                if (data.length === 0) {
                    this.disable = true
                    this.loading = false
                    if (this.$route.params.id) {
                        this.$router.replace({
                            name: 'administrative-weekly-report',
                            query: {
                                employeeId: this.employeeId
                            }
                        })
                    }
                } else {
                    const myReport = data.length ? data[data.length - 1] : []
                    this.value = myReport?.fromDate
                    this.date = myReport?.fromDate
                    this.employeeReport = []
                    this.employeeReport = myReport?.activities?.map((c, index) => ({ ...c, id: index }))
        
                    this.employeeId = myReport?.supervisorId
                    this.notes = myReport?.notes
                    const dataArray = this.employeeReport?.length ? this.employeeReport : []
            
                    var activities = dataArray
                    const dateArray = activities.map(c => new Date(c.date).getTime())
                    for (let i = 0; i < activities.length; i++) {
                        var start = dateArray[i];
                        const end = dateArray[i];
                        const timed = false;
                        this.events.push({
                            name: activities[i].activity,
                            color: 'primary',
                            start,
                            end,
                            timed,
                            type: 'activity',
                            id: i
                        })
                    }
                    Promise.all([
                        this.$store.dispatch('doubleVisit/fetchAll', { 
                            employeeId: !employeeId ? this.employeeId : employeeId,
                            fromDate: !fromDate ? this.moment(this.date).startOf('month').format('YYYY-MM-DD') : fromDate,
                            toDate: !toDate ? this.moment(this.date).endOf('month').format('YYYY-MM-DD') : toDate, 
                        }),

                        this.$store.dispatch('visits/fetchVisitsWithoutPagination', {
                            fromDate: !fromDate ? this.moment(this.date).startOf('month').format('YYYY-MM-DD') : fromDate,
                            toDate: !toDate ? this.moment(this.date).endOf('month').format('YYYY-MM-DD') : toDate, 
                            employeeId: !employeeId ? this.employeeId : employeeId
                        }) 
                    ]).then((data) => {
                        const double = data[0].map(c => ({ 
                            start: new Date(c.date).getTime(), 
                            end: new Date(c.date).getTime(), 
                            date: c.date,
                            medicalRepId: c.medicalRepId,
                            id: c.id,
                        })).filter(c => moment(c.date).isSame(moment(), 'month'))
                            if (double.length) {
                                for (let k = 0; k < double.length; k++) {
                                    this.events.push({
                                        name: this.getMedicalRepById(double[k].medicalRepId)?.name,
                                        start: double[k].start,
                                        end: double[k].start,
                                        type: 'double',
                                        id: double[k].id
                                    })
                                }
                            }
                            // visits
                            const visits = data[1].map(c => ({
                                name: c.entity?.name,
                                start: new Date(c.createdAt).getTime(), 
                                end: new Date(c.createdAt).getTime(),
                                type: 'visits',
                                id: c.id
                            }))
                            for (let k = 0; k < visits.length; k++) {
                                this.events.push({
                                    name: visits[k]?.name,
                                    start: visits[k].start,
                                    end: visits[k].start,
                                    type: 'visits',
                                    id: visits[k].id
                                })
                            }
                            this.loading = false
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                    this.disable = false
                    if (this.$route.params.id !== data[data.length - 1]?.id) {
                        this.$router.replace({
                            name: 'administrative-weekly-report',
                            params: {
                                id: data[data.length - 1]?.id 
                            },
                            query: {
                                employeeId: this.employeeId
                            }
                        })
                    }
                })
            }
        },

    created() {
        this.loading = true
        Promise.all([
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.entitiesBrief.length ? this.$store.dispatch('entities/fetchAllBrief', { regionIds: [] }) : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data });
            }) : null,

            !this.myVisits.length ? this.$store.dispatch('mineVisits/fetchAllData', { 
                day: moment().format('YYYY-MM-DD') 
            }) : null,
			!this.materials.length ? this.$store.dispatch('materials/fetchAll', {}) : null,
            this.$store.dispatch('materialTransactions/fetchSelfSummary'),
			!this.transactions.length ? this.$store.dispatch('materialTransactions/fetchAll', { 
                queryType: userType.supervisor 
            }) : null,
			!this.materialForms.length ? this.$store.dispatch('materialForms/fetchAll') : null,
        ]).then(() => {
            if (this.$route.params.id) {
                this.employeeId = this.reports.find(c => c.id === this.$route.params.id)?.supervisorId

                this.fetchAllDataInCalendar(this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD'), this.employeeId)
            } else {
                this.employeeId = this.userLogin
                this.fetchAllReports(this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD'), this.employeeId).then((data) => {
                    if (this.employeeId === this.userLogin && data.length === 0) {
                        this.$store.dispatch('supervisorReports/create', { 
                            fromDate: this.moment().startOf('month').format('YYYY-MM-DD'), 
                            toDate: this.moment().endOf('month').format('YYYY-MM-DD'),
                            pastOfficeActivities: null, 
                            plannedOfficeActivities: null, 
                            pastOffOfficeActivities: null, 
                            plannedOffOfficeActivities: null, 
                            visitedHospitals: null, 
                            unavailableMaterials: null, 
                            notes: null, 
                            activities: [] 
                        }).then(() => {
                            this.fetchAllDataInCalendar(this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD'), this.userLogin)
                        })
                    } 
                    else if (data.length !== 0) {
                        this.fetchAllDataInCalendar(this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD'), this.userLogin)
                    }
                })
            }
        })
    },

    metaInfo: {
        title: 'Weekly report',
        titleTemplate: '%s | Octopharma'
    },

    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('entities/clearEntitiesBrief')
        if (!this.entitiesBrief.length) {
            next()
        } else {
            next(false)
        }
    }
}
</script>
