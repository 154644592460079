export default {
    "visits-count": "Visits count",
    "view-chart": "View chart",
    "visit-details": "Visit details",
    "distributed-materials": "Distributed materials",
    "materials": "Materials",
    "quantity": "Quantity",
    "other-materials": "Other materials",
    "duration-in-minutes": "Duration In Minutes",
    "core-message": "Core message",
    "notes": "Notes",
    "objections": "Objections",
    "next-call": "Next Call",
    "unavailable-products": "Unavailable Products",
    "competitors": "Competitors",
    "center-notes": "Center notes"
}