<template>
    <div>
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="filter()"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> the report ?
        </delete-dialog>

        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; filter();">
            <v-row class="my-1" align="center">
                <!-- employeeId -->
                <v-col md="2" sm="3" cols="6" class="pb-sm-0 pb-md-2">
                    <v-autocomplete
                        v-model="employeeId"
                        :items="medicalreps.filter(c => c.employeeType  !== userType.medicalRep)"
                        item-value="id"
                        item-text="name"
                        :loading="loading"
                        :disabled="loading"
                        label="Employee"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>
                <!-- start date -->
                <v-col md="3" sm="6" cols="6" class="pb-sm-0 pb-md-2">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col md="3" sm="6" cols="6" class="pb-sm-0 pb-md-2">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
            
                <v-col md="2" lg="2" class="d-flex justify-end mx-md-3">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        small
                        type="submit"
                    >
                        <v-icon> mdi-magnify </v-icon>
                        Search
                    </v-btn>
            
                    <v-btn
                        class="px-sm-8"
                        @click="clearFilter(); filter();"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        Reset
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? reports : []"
            :loading="loading"
            dense
            fixed-header
            :height="$vuetify.breakpoint.height - 152"
            pagination-on-scroll
            zebra
        >
            <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-center">
                    <tooltip text="View Report" top>
                        <v-btn
                            icon
                            small
                            :to=" {
                                name: 'administrative-weekly-report',
                                params: { id: item.id  },
                                query: { preview: true }
                            }" 
                            :color="isSuperAdmin || permissionsUser.WeeklyReports !== roleName.action  ? 'success' : 'edit-color'"
                        >
                            <v-icon>
                                mdi-eye-outline
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip
                    	v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
                    	text="Delete report"
                    	top
                    >
                        <v-btn
                        	icon
                        	small
                        	@click="$refs.deleteDialog.activeDeleteDialog(item.id, 'supervisorReports')"
                        	color="alert-color"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Tooltip from '../../components/Tooltip'
import DeleteDialog from '../../components/DeleteDialog'
import { userType, roleName } from '@/helpers/enums'
import { dig } from '@/helpers/functions'

export default {
    name: 'AllAdministrativeReports',

    components:{
        Tooltip,
        DeleteDialog
    },

    data(){
        return {
            employeeId: null,
            roleName,
            // start date
            activePickerStartDate: null,
            menuOfStartDate: null,
            startDate: null,

            // end date
            activePickerEndDate: null,
            menuOfEndDate: null,
            endDate: null,

            // helpers
            userType,
            loading: false,
            isFiltered: false,
            reportId: null,
        }
    },

    computed:{
        ...mapState({
            employeeType: state => state.auth.userData.employeeType,
            reports: state => state.supervisorReports.reports,
            sectorCities: state => state.sectors.sectorCities,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            medicalreps: state => state.medicalreps.medicalreps,
            cities: state => state.cities.cities
        }),

        ...mapGetters({
            getCityById: 'cities/getCityById',
            getSupervisorById: 'supervisors/getSupervisorById',
            getReportById: 'supervisorReports/getReportById',
            isAdmin: 'auth/isAdmin',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getRegionAllById: 'regions/getRegionAllById'
        }),

        isAllFieldsEmpty() {
            return !this.startDate && !this.endDate && !this.employeeId;
        },

        headers() {
            const headers = [
                {
                    text: 'Start Date', 
                    align: 'start', 
                    value: 'fromDate', 
                    type: 'date',
                    cellClass:'width-fixed-date'
                },
                {
                    text: 'End Date', 
                    align: 'start', 
                    value: 'toDate', 
                    type: 'date',
                    cellClass:'width-fixed-date'
                },
                { 
                    text: 'Actions', 
                    name: 'actions', 
                    value: 'actions', 
                    align: 'center',
                    sortable: false
                },
            ];

            if (this.isSuperAdmin || this.permissionsUser.WeeklyReports !== roleName.nothing) {
                headers.splice(2, 0, ...[
                    { 
                        text: 'City', 
                        value: 'supervisorId', 
                        setValueExpr: val => this.getMedicalRepById(val)?.regionIds.map(c => this.getCityById(this.getRegionAllById(c)?.cityId)?.id).filter((value, index) => this.getMedicalRepById(val)?.regionIds.map(c => this.getCityById(this.getRegionAllById(c)?.cityId)?.id).indexOf(value) === index).map(c => this.getCityById(c)?.name).join(' ,'),
                        align: 'start',
                        sortable: false
                    },
                    { 
                        text: 'Employee name', 
                        value: 'supervisorId', 
                        align: 'start',
                        setValueExpr: val => this.getMedicalRepById(val)?.name,
                    },
                ])
            }

            const sharedClass = 'text-body-2 font-weight-bold';
            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
            }

            return headers;
        } 
    },

    methods:{
        // helpers
        dig,

        // filter functions
        clearFilter(){
            this.startDate = null;
            this.endDate = null;
            this.employeeId = null
            
            this.isFiltered = false;
        },

        filter(isFirstLoad = false){
            this.loading = true;
            return this.$store.dispatch('supervisorReports/fetchAll', {
                fromDate: this.startDate,
                toDate: this.endDate,
                employeeId: this.employeeId
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        }
    },

    created(){
        this.loading = true;
        Promise.all([
            !this.sectorCities.length
                ? this.$store.dispatch('cities/fetchCityMine')
                    .then((cities) => this.$store.dispatch('sectors/fetchSectorCities', { cities }))
                : null,
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data })
            }) : null,
            this.filter(true),
        ])
        .finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Supervisors reports',
        titleTemplate: '%s | Octopharma'
    }
}
</script>