<template>
        <div>
            <v-dialog
                transition="dialog-transition"
                max-width="500"
                ref="dialog"
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <div class="d-flex justify-end red-light-one-bg py-1">
                            <v-card-title
                                class="text-uppercase justify-center py-0 white--text text-subtitle-1 d-flex justify-center w-100 px-0 py-0"
                            >
                                {{$t('confirm')}} {{$t('delete')}}
                            </v-card-title>
                            <v-btn icon @click="dialog.value = false">
                                <v-icon color="white">mdi-close-circle-outline</v-icon>
                            </v-btn>
                        </div>

                        <v-card-text class="text-body-1 pb-0 px-4 pt-2">
                            <!-- dialog content -->
                            <!-- default slot -->
                                <slot></slot>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                @click="deleteData"
                                :loading="loading"
                                color="red lighten-1 white--text"
                                elevation="0"
                                text
                            >
                                {{$t('delete')}}
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                :disabled="loading"
                                elevation="0"
                                text
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

            <v-snackbar
              v-model="snackbar"
              transition="slide-y-reverse-transition"
              color="red lighten-1 white--text"
              class="text-body-2"
            >
                {{ message }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
</template>

<script>
export default {
    props:[
        'success-message'
    ],
    data(){
        return {
            snackbar: false,
            message: null,
            
            dataId: null,
            moduleType: null,
            additionalData: {},

            loading: false,
        }
    },

    methods:{
        activeDeleteDialog(id, moduleType){
            this.$refs.dialog.isActive = true;
            this.dataId = id;
            this.moduleType = moduleType;
        },

        setAdditionalData(additionalData) {
            this.additionalData = additionalData;
        },

        deleteData(){
            this.message = this.successMessage;
            this.loading = true;

            this.$store.dispatch(`${this.moduleType}/delete`, { id: this.dataId, ...this.additionalData })
            .then(() => {
                this.message = "Delete operation completed successfully.";
                this.$emit('on-delete');
                this.$refs.dialog.isActive = false;
            })
            .catch((e) => {
                this.message = e.response.data.message;
            })
            .finally(() => {
                this.snackbar = true;
                this.loading = false;
            });

            this.dataId = null;
            this.moduleType = null;
        },
    }
}
</script>

<style>

</style>