import * as types from '../mutation-types'

export default {
	namespaced: true,
    state:{
        sales: []
    },

    actions:{
        fetchAll({ commit }, { materialIds, cityIds, warehouseIds, fromDate, toDate, returnSums }){
            var urlSearchParams = new URLSearchParams()
            var params = { fromDate, toDate, returnSums };
            Object.keys(params).forEach(param => {
                if (params[param] !== null) urlSearchParams.append(param, params[param]);
            })

            materialIds.forEach(materialId => {
                urlSearchParams.append('materialIds', materialId);
            })
            cityIds.forEach(cityId => {
                urlSearchParams.append('cityIds', cityId);
            })
            warehouseIds.forEach(warehouseI => {
                urlSearchParams.append('warehouseId', warehouseI);
            })
            
            return window.axios.get('/sales', {
                params: urlSearchParams
            })
            .then((response) => {
                commit(types.STORE_SALES, response.data);  
                return response.data;
            })
        },

        create({ commit }, { materialId, cityId, warehouseId, date, quantity, gifts, inventory, order, packageCapacity, notes }){
            return window.axios.post('/sales', { 
                materialId,
                cityId,
                warehouseId,
                date,
                quantity,
                sales: 0,
                gifts,
                inventory,
                order,
                packageCapacity,
                notes
            });
        },
        
        update({ commit }, { id, materialId, cityId, warehouseId, date, quantity, gifts, inventory, order, packageCapacity, notes }){
            return window.axios.put(`/sales/${id}`, {
                materialId,
                cityId,
                warehouseId,
                date,
                quantity,
                sales: 0,
                gifts,
                inventory,
                order,
                packageCapacity,
                notes
            });
        },

        import({ commit }, { date, excelFile }){
            var formData = new FormData();
            formData.append('file', excelFile);
            return window.axios.post('/sales/import', formData, { params: { date } })
            .then((response) => {
                return response.data;
            });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/sales/${id}`);
        },
    },

    mutations:{
        [types.STORE_SALES](state, sales){
            state.sales = sales;
        }
    },

    getters:{
        getSaleById: state => id => state.sales.find(c => id === c.id),
    }
}