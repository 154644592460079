<template>
	<div>
		<v-form
			@submit.prevent="submit"
			ref="form"
			:disabled="Boolean($route.query.preview) || submitLoading"
		>
			<v-row dense class="ma-0">
				<!-- city -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.cityId"
						:items="cities"
						item-text="name"
						item-value="id"
						label="City"
						dense
						:disabled="loading || Boolean($route.query.preview)"
						prepend-icon="mdi-map-marker-multiple"
						persistent-hint
						outlined
                        multiple
                        clearable
                        @change="getRegion()"
                        :search-input.sync="searchCityId"
					>
						<!-- @change="getSector()" -->
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.cityId.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleCities">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ iconCities }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-autocomplete>
				</v-col>

                <!-- regions -->
                <v-col lg="4" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.regionIds"
						:items="employeeData.cityId.length !== 0 ?  regionsAll.filter(c => employeeData.cityId.includes(c.cityId)) : []"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Regions"
						prepend-icon="mdi-medical-bag"
						multiple
						:disabled="Boolean($route.query.preview)"
                        clearable
                        :search-input.sync="searchRegionId"
                        @change="searchRegionId = null"
					> 
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.regionIds.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleRegion">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ iconRegions }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>

                    </v-autocomplete>
				</v-col>

                <!-- teams -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.teamId"
						:items="teams"
						item-text="name"
						item-value="id"
						label="teams"
						dense
						hide-details
						outlined
                        multiple
                        clearable
						:disabled="Boolean($route.query.preview)"
						:loading="loading"
                        :search-input.sync="searchTeamId"
                        @change="searchTeamId = null; employeeData.specialtyIds = []"
					>
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.teamId.length - 1 }})
                            </span>
                        </template>

                        <!-- <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleTeam">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ iconTeam }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template> -->
                    </v-autocomplete>
				</v-col>

				<!-- lineManager -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.lineManagerId"
						:items="lineManager"
						item-text="name"
						item-value="id"
						label="Line manager"
						dense
						hide-details
						outlined
						prepend-icon="mdi-account-tie"
						:disabled="Boolean($route.query.preview)"
						:loading="loading"
					/>
				</v-col>

				<v-col v-if="employeeData.teamId.length === 0" lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.specialtyIds"
						:items="specialties"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Specialties"
						prepend-icon="mdi-medical-bag"
						multiple
						:disabled="Boolean($route.query.preview) || employeeData.teamId.length !== 0"
                        :search-input.sync="searchSpecialties"
                        @change="searchSpecialties = null"
					> 

                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.specialtyIds.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>

                    </v-autocomplete>
				</v-col>
                
                <v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.roleId"
						:items="roles"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Role"
						prepend-icon="mdi-medical-bag"
						:disabled="Boolean($route.query.preview)"
                        @change="!(
                            roles.find(c => c.id === employeeData.roleId).name.includes('Rep') || 
                            roles.find(c => c.id === employeeData.roleId).name.includes('REP') || 
                            roles.find(c => c.id === employeeData.roleId).name.includes('rep')) ? 
                            showEmployee = true : 
                            showEmployee = false;
                            getDirectEmployee()
                        "
					/>
				</v-col>
                
                <!-- show -->
                <v-col>
                    <v-btn
                        small
                        color="green darken-1 white--text"
                        @click="showEmployee = !showEmployee"
                        :disabled="loadingDirect || Boolean($route.query.preview)"
                    >
                        <v-icon small class="me-2">mdi-eye</v-icon>
                        Show your employees
                    </v-btn>
                </v-col>
				<!-- target -->
				<v-col v-if="info.employeeType === 1" lg="3" md="4" sm="6" cols="12">
					<v-text-field
						v-model.number="employeeData.target"
						label="Target"
						type="number"
						:rules="rules.number"
						required
						outlined
						dense
						hide-details="auto"
						:loading="loading"
						prepend-icon="mdi-target-account"
						:disabled="Boolean($route.query.preview)"
					></v-text-field>
				</v-col>

                <!-- leaveDaysNumber -->
				<v-col
					v-if="isSuperAdmin || permissionsUser.ManageLeaveBalances !== roleName.nothing"
					lg="3"
					md="4"
					sm="6"
					cols="12"
				>   
					<v-text-field
						v-model.number="employeeData.leaveDaysNumber"
						label="Leave Days Number"
						type="number"
						:rules="rules.number"
						required
						outlined
						dense
						hide-details="auto"
						:loading="loading"
						prepend-icon="mdi-calendar"
						:disabled="Boolean($route.query.preview)"
					></v-text-field>
                        <!-- :readonly="!isAdmin || (permissionsUser.ManageLeaveBalances === roleName.read)" -->
				</v-col>

				<!-- submit button -->
				<v-col v-if="(isSuperAdmin || permissionsUser.MedicalReps === roleName.action) && !showEmployee" cols="12" class="text-end">
					<v-btn
                        v-if="isSuperAdmin || userData !== $route.params.id"
						height="30"
						:color="Boolean($route.query.preview) ? 'edit-color white--text' : 'success white--text' "
						type="submit"
						class="px-md-4"
						:class="{ 'mb-1': $vuetify.breakpoint.smAndDown }"
						:loading="submitLoading"
					>
						<v-icon size="22" class="me-1"> 
							{{ Boolean($route.query.preview) ? 'mdi-pencil' : 'mdi-content-save' }} 
						</v-icon>
						{{ Boolean($route.query.preview) ? 'Edit' : 'save'}}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
        
        <!-- employee direct -->
        <v-card v-if="showEmployee"
            class="scroll-style mb-4"
            >
            <v-card-title class="fs-1rem">
                <span>
                    Choose the employees who will be their supervisor
                </span>
            </v-card-title>
            <v-card-text >
                <div v-if="directBriefEmployee.length !== 0" class="d-flex flex-wrap">
                    <div v-for="(r, index) in directBriefEmployee" :key="index" class="mx-2 " style="width: 200px">
                        <v-checkbox 
                            v-model="r.model"
                            :value="r.id"
                            :label="r.name"
                            dense
                            hide-details
                        />
                    </div>
                </div>
                <div v-else class="text-center">
                    <v-icon> mdi-data </v-icon>                        
                    <span> Not found data </span>
                </div>
            </v-card-text>

            <!-- <v-card-actions>
                <v-spacer />
                <v-btn @click="updateLineManager()" color="success white--text" small :loading="loading">
                    save
                </v-btn>
            </v-card-actions> -->
        </v-card>

        <v-col v-if="(isSuperAdmin || permissionsUser.MedicalReps === roleName.action) && showEmployee" cols="12" class="text-end">
            <v-btn
                v-if="isSuperAdmin || userData !== $route.params.id"
                height="30"
                :color="Boolean($route.query.preview) ? 'edit-color white--text' : 'success white--text' "
                @click="updateEmployeeWithYourTeam()"
                class="px-md-4"
                :class="{ 'mb-1': $vuetify.breakpoint.smAndDown }"
                :loading="submitLoading"
            >
                <v-icon size="22" class="me-1"> 
                    {{ Boolean($route.query.preview) ? 'mdi-pencil' : 'mdi-content-save' }} 
                </v-icon>
                {{ Boolean($route.query.preview) ? 'Edit' : 'save'}}
            </v-btn>
        </v-col>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { gender, userType, roleName } from '@/helpers/enums'
import rules from '@/validation rules'
import moment from 'moment'

export default {
    data() {
        return {
            info: [],
            showEmployee: null,
            searchCityId: null,
            searchRegionId: null,
            searchTeamId: null,
            searchSpecialties: null,
            roleName,
            // helpers
            submitLoading: false,
            loading: false,
            rules,

            // data
            employeeData: {
                name: null,
                birthDate: null, 
                landlineNumber: null,
                phoneNumber: null,
                address: null,
                email: null,
                facebookProfileUrl: null, 
                startingDate: null,
                username: null,
                password: null,
                cityId: [],
                regionIds: [],
                teamId: [],
                lineManagerId: null,
                specialtyIds: [],
                roleId: null,
                leaveDaysNumber: 12,
                sectorId: null,
                userType: userType.medicalRep,
                gender: gender.male,
                target: null,
                jobPosition: null,
                directBriefEmployee: [],
            },
            lineManager: [],
            loadingDirect: false
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            supervisors: state => state.supervisors.supervisors,
            specialties: state => state.specialties.specialties,
            roles: state => state.rolePermission.roles,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            regions: state => state.regions.regions,
            regionsAll: state => state.regions.regionsAll,
            medicalReps: state => state.medicalreps.medicalreps,
            teams: state => state.teams.teams,
            userData: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            getSupervisorById: 'supervisors/getSupervisorById',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            isAdmin: 'auth/isAdmin',
            getRegionById: 'regions/getRegionById',
            getRegionAllById: 'regions/getRegionAllById',
            getCityById:'cities/getCityById',
            getTeamById: 'teams/getTeamById'
        }),

        employeeType() {
            return Number(this.$route.query.employeeType);
        },

        icon() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllSpecialties () {
            return this.employeeData.specialtyIds?.length === this.specialties.length;
        },

        selectedAllRegions () {
            return this.employeeData.regionIds?.length === this.regionsAll.filter(c => this.employeeData.cityId?.includes(c.cityId)).length;
        },

        selectedAllCities () {
            return this.employeeData.cityId?.length === this.cities?.length;
        },

        iconCities() {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        submit() {
            if (this.$route.query.preview) {
                this.$router.replace({ 
                    name: this.$route.params.name, 
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        employeeType: this.$route.query.employeeType
                    } 
                })
            } else {
                if (this.$refs.form.validate()) {
                    this.submitLoading = true;
                    return Promise.all([
                        this.$store.dispatch('medicalreps/update', {
                            id: this.$route.params.id,
                            // data: this.employeeData
                            data: { 
                                ...this.employeeData,
                                specialtyIds: this.employeeData.teamId.length !== 0 ? this.employeeData.teamId.map(c => this.getTeamById(c).specialtyIds).toString().split(',').filter(c => c !== '') : this.employeeData.specialtyIds
                            }
                        }),
                        this.employeeData.target !== null 
                        ? this.$store.dispatch('medicalrepTarget/update', {
                            id: this.$route.params.id,
                            value: this.employeeData.target
                        }) : null
                    ]).then(() => {
                        const moduleName = this.$route.query.employeeType === 'medicalRep' ? 'medicalreps' : 'supervisors'
                        // this.$store.dispatch('medicalreps/fetchAll', { employeeId: this.$route.params.id })
                        const employeeType =
                            this.$route.query.employeeType === 'medicalRep' ? 'Medical rep' : 'supervisor';
                        this.$eventBus.$emit('show-snackbar', 'Employee is edited', 'this.info');

                        this.$store.dispatch('leaveManagement/fetchCount', { 
                            employeeId: this.$route.params.id 
                        }).then((data) => {
                            this.employeeData.leaveDaysNumber = data.daysCount
                        })
                    }).finally(() => {
                        this.submitLoading = false;
                        this.$router.replace({
                            name: this.$route.params.name,
                            params: {
                                id: this.$route.params.id
                            },
                            query: {
                                employeeType: this.$route.query.employeeType,
                                preview: true
                            }
                        })
                    })
                }
            }
        },

        toggle () {
            this.$nextTick(() => {
                if (this.selectedAllSpecialties) {
                    this.employeeData.specialtyIds = []
                } 
                else {
                    this.employeeData.specialtyIds = this.specialties.map(c => c.id);
                    this.employeeData.specialtyIds.forEach(specialty => {
                    if (this.employeeData.specialtyIds.includes(specialty.id)) {
                        this.employeeData.specialtyIds.push(specialty.id);
                    }
                });  
                }
            })
        },

        // toggleTeam() {
        //     this.$nextTick(() => {
                
        //     })
        // },

        toggleRegion() {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.regionIds = []
                    this.employeeData.regionIds = this.regionsAll.filter(c => this.employeeData.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        changed () {
            this.employeeData.specialtyIds = [];
            this.specialties.forEach(specialty => {
                if (this.employeeData.specialtyIds.includes(specialty.id)) {
                    this.employeeData.specialtyIds.push(specialty.id);
                }
            })
        },

        toggleCities () { 
            this.employeeData.regionIds = []
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.employeeData.cityId = []
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.cityId = this.cities.map(c => c.id);
                }
            })
        },

        getRegion () {
            this.employeeData.regionIds = []
            this.employeeData.regionIds = this.regionsAll.filter(c => this.employeeData.cityId.includes(c.cityId)).map(c => c.id); this.searchCityId = null
        },

        updateLineManager () {
            this.loading = true
            this.$store.dispatch('medicalreps/updateLineManager', { 
                newLineManagerId: this.$route.params.id, 
                employees: this.directBriefEmployee.map(c => c.model).filter(c => c !== null)
            }).finally(() => {
                this.loading = false
                this.showEmployee = false
            })
        },

        getDirectEmployee() {
            this.loadingDirect = true
            this.showEmployee = false
            this.$store.dispatch('medicalreps/fetchEmployeeDirectBrief', {
                employeeId: this.$route.params.id,
                roleId: this.employeeData.roleId
            }).then((data) => {
                this.directBriefEmployee = data.map(c => ({ ...c, model: null }))
                this.showEmployee = true
            }).finally(() => {
                this.loadingDirect = false
            })
        },

        updateEmployeeWithYourTeam() {
            this.submit().then(() => {
                this.updateLineManager()
            })
        }
    },

    created() {
        this.loading = true;
        Promise.all([
            this.$store.dispatch('medicalreps/fetchEmployeeLineManager', {
                EmployeeId: this.$route.params.id
            }).then((data) => { this.lineManager = data; }),
            this.$store.dispatch('medicalreps/fetchEmployeeDirectBrief', {
                employeeId: this.$route.params.id
            }).then((data) => {
                this.directBriefEmployee = data.map(c => ({ ...c, model: null }))
            }),
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.teams.length ? this.$store.dispatch('teams/fetchAll') : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
            this.permissionsUser.Roles !== roleName.nothing
                ? this.$store.dispatch('rolePermission/fetchChildrenRole', { id: this.userData })
                : null,
            this.$store.dispatch('leaveManagement/fetchCount', {
                employeeId: this.$route.params.id
            }).then((data) => {
                this.employeeData.leaveDaysNumber = data.daysCount !== 0 ? data.daysCount : 12
            }),
            (async () => {
                if (!this.regionsAll.length) {
                    await this.$store.dispatch('regions/fetchAllRegions', { cities: this.cities });
                }
                await this.$store.dispatch('medicalreps/fetchAll', { employeeId: this.$route.params.id }).then((data) => {
                    this.info = data[0]
                    // const data = { ...this.getMedicalRepById(this.$route.params.id) };   

                    this.employeeData.name = this.info?.name ? this.info.name : null;
                    this.employeeData.birthDate =
                        this.info?.birthDate ? moment(this.info?.birthDate).format('YYYY-MM-DD') : null;
                    this.employeeData.email = this.info.email ? this.info.email : null;
                    this.employeeData.phoneNumber = this.info.phoneNumber ? this.info.phoneNumber : null;
                    this.employeeData.landlineNumber = this.info.landlineNumber ? this.info.landlineNumber : null;
                    this.employeeData.sectorId = this.info.sectorId ? this.info.sectorId : null;
                    this.employeeData.lineManagerId = this.info.lineManager ? this.info.lineManager : null;
                    this.employeeData.address = this.info.address ? this.info.address : null;
                    this.employeeData.facebookProfileUrl = this.info.facebookProfileUrl ? this.info.facebookProfileUrl : null;
                    this.employeeData.jobPosition = this.info.jobPosition ? this.info.jobPosition : null;

                    this.employeeData.startingDate =
                        this.info.startingDate ? moment(this.info.startingDate).format('YYYY-MM-DD') : null;
                    this.employeeData.specialtyIds = this.info.specialtyIds;
                    this.employeeData.roleId = this.info.roleId ? this.info.roleId : null;
                    this.employeeData.username = this.info.username ? this.info.username : null;
                    this.employeeData.userType = userType.medicalRep;
                    this.employeeData.teamId = this.info.teamId
                    this.$store.dispatch('medicalrepTarget/fetch', { id: this.$route.params.id }).then((data) => {
                        this.employeeData.target = data;
                    })
                    this.employeeData.gender = this.info.gender;
                    this.employeeData.regionIds = this.info.regionIds;
                    
                    this.employeeData.cityId =
                        this.employeeData.regionIds.map(c => c !== undefined ? this.getRegionAllById(c)?.cityId : null)
                            .filter((id, index, arr) => arr.indexOf(id) === index)
                })
            })()
        ]).then(() => {
            this.loading = false;
        }).catch(() => {
            this.loading = false
        })
    }    
}
</script>

<style scoped>
.fs-1rem {
    font-size: 1rem;
}
.scroll-style {
    float:left;
    width: 100%;
    overflow-y: auto;
    height: 350px;
}
</style>