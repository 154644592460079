import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        rate: {
            reviews: [
                { 
                    date: null,
                    marks: {}, 
                },
            ],
            averageMarks: {
                visitsQuality: 0,
                examResult: 0,
                regionSalesLevel: 0,
                cooperation: 0,
                commitment: 0,
                behavior: 0,
                appearance: 0,
                sum: 0
            },
        }
    },

    actions:{
        fetchAll({ commit }, { employeeId, fromData, toDate }){
            return window.axios.get('/reviews', {
                params:{
                    employeeId,
                    fromData,
                    toDate
                }
            })
            .then(response => {
                commit(types.STORE_RATES, response.data)
                return response.data;
            });
        },

        create({ commit }, { employeeId, date, visitsQuality, examResult, regionSalesLevel, cooperation, commitment, behavior, appearance }){
            return window.axios.post('/reviews', { employeeId, date, visitsQuality, examResult, regionSalesLevel, cooperation, commitment, behavior, appearance })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/reviews/${id}`);
        },
    },

    mutations:{
        [types.STORE_RATES](state, rate){
            state.rate = rate;
        }
    },
}