import * as types from '../mutation-types'
export default {
    namespaced: true,

    state: {
        works: [],
        myWorks: []
    },

    actions: {
        fetchAllMine({ commit }, { fromDate }) {
            return window.axios.get('/PlannedWork/Mine', {}, { params: { fromDate } }).then(({ data }) => {
                commit(types.STORE_MY_WORKS, data)
                return data
            })
        }, 

        fetchAllByEmployee({ commit }, { employeeId, fromDate }) {
            return window.axios.get(`/PlannedWork/Employee/${employeeId}`, { params: { fromDate } }).then(({ data }) => {
                commit(types.STORE_WORKS, data)
                return data
            })
        },

        create({ commit }, { medicalRepId, workDescription, date, visitPlanId }) {
            return window.axios.post('/PlannedWork', { medicalRepId, workDescription, date, visitPlanId })
        },

        update({ commit }, { id, medicalRepId, workDescription, date, visitPlanId }) {
            return window.axios.put(`/PlannedWork/${id}`, {}, { params: { medicalRepId, workDescription, date, visitPlanId } }).then(({ data }) => {
                return data
            })
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/PlannedWork/${id}`)
        }
    },

    mutations: {
        [types.STORE_MY_WORKS] (state, myWorks) {
            state.myWorks = myWorks
        },

        [types.STORE_WORKS] (state, works) {
            state.works = works
        },
    },

    getters: {
        getWorKById: state => id => state.works.find(c => c.id === id)
    }

}