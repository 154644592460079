import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		cities: [],
	},

	actions: {
		fetchAll({ commit }) {
            return window.axios.get('/cities').then(response => {
                commit(types.STORE_CITIES, response.data);

                return response.data;
            });
		},

		fetchById({ commit }, { id }) {
			return window.axios.get(`/cities/${id}`).then(({ data }) => {
				return data
			})
		},
	
        create({ commit }, { name }) {
            return window.axios.post('/cities', {
                name
            });
        },

        update({ commit }, { id, name }) {
            return window.axios.put(`/cities/${id}`, {
                name
            });
        },

		fetchCityMine({ commit }) {
			return window.axios.get('/cities/mine').then(({ data }) => {
				commit(types.STORE_CITIES, data);
				return data
			})
		},

		delete({ commit }, { id }) {
			return window.axios.delete(`/cities/${id}`)
		}
	},

    mutations: {
		[types.STORE_CITIES](state, cities){
			state.cities = cities.sort(function(a, b) { if (a.name < b.name) { 
				return -1
			}; 
			if (a.name > b.name) {
				return 1 
			}; 
			return 0 
		});
		},
		// [types.STORE_MY_CITIES](state, myCities) {
		// 	state.myCities = myCities
		// }
	},

	getters: {
		getCityById: state => id => state.cities.find(c => c.id === id),
		getCityByName: state => name => state.cities.find(c => c.name === name),
	},
}