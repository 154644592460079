<template>
    <div>
        <h2 class="text-h5 primary--text text-capitalize mb-5">Supervisors</h2>

        <!-- dialogs is decleared here to use as ref -->
        <!---->
        <!-- supervisor-dialog putted here to use it as ref -->
        <supervisor-dialog
          @on-save="isFiltered ? filter() : fetchSupervisor()"
          title="add supervisor"
          ref="SupervisorDialog"
        ></supervisor-dialog>

        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
          ref="deleteDialog"
          @on-delete="isFiltered ? filter() : fetchSupervisor()"
          success-message='Data is deleted'
        >
            <template v-slot:default>
                <p class="mb-2">
                    Are you sure you want to <span class="red--text">delete</span> supervisor account?
                </p>
            </template>
        </delete-dialog>
        <!---->
        
        <!-- add supervisor -->
        <v-btn text small @click="launchSupervisorDialog">
            <v-icon class="me-2">
                mdi-plus-circle-outline
            </v-icon>
            add supervisor
        </v-btn>
        
        <!-- filter -->
        <v-expansion-panels accordion focusable class="my-5">
            <v-expansion-panel>
                <v-expansion-panel-header color="primary--text">Filter</v-expansion-panel-header>

                <v-card flat :loading="loading">
                    <v-expansion-panel-content color="pt-8">
                        <v-row>
                            <!-- supervisor name -->
                            <v-col md="3" sm="6" cols="12" class="pa-1">
                                <v-autocomplete
                                    v-model="supervisorName"
                                    :items="supervisors"
                                    item-text="name"
                                    item-value="name"
                                    :return-object="false"
                                    label="supervisor name"
                                    dense             
                                    hide-details
                                    clearable
                                    :loading="filterLoading"
                                    :disabled="loading"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- city region filter -->
                            <v-col md="5" sm="6" cols="12" class="pa-1">
                                <v-row class="pa-3">
                                    <!-- city -->
                                    <v-col cols="4" class="pa-0">
                                        <v-autocomplete
                                            v-model="cityId"
                                            :items="cities"
                                            item-text="name"
                                            item-value="id"
                                            :return-object="false"
                                            @change="cityIdChanged"
                                            label="City Name"
                                            dense
                                            hide-details
                                            append-icon
                                            :loading="filterLoading"
                                            :disabled="loading"
                                            class="rounded-tr-0"
                                        ></v-autocomplete>
                                    </v-col>

                                    <!-- region -->
                                    <v-col cols="8" class="pa-0">
                                        <v-autocomplete
                                            v-model="regionIds"
                                            :items="regions"
                                            item-text="name"
                                            :return-object="false"
                                            item-value="id"
                                            label="Regions"
                                            dense
                                            multiple
                                            hide-details
                                            clearable
                                            :loading="regionsLoading"
                                            :disabled="regionsLoading || cityId === null"
                                            @click:clear="cityId = null"
                                            class="rounded-tl-0"
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                                <span
                                                    v-if="index === 1"
                                                    class="grey--text text-caption"
                                                >
                                                    (+{{ regionIds.length - 1 }})
                                                </span>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-divider class="mt-5"></v-divider>

                        <!-- search button -->
                        <v-card-actions class="justify-end px-0 pb-0">
                            <v-btn
                                text
                                color="success"
                                class="px-sm-8"
                                :loading="loading"
                                :disabled="loading || isAllFieldsEmpty"
                                @click="filter"
                            >
                                Search
                            </v-btn>
                            <v-btn
                                text
                                :disabled="!isFiltered || loading"
                                @click="clearFilter(); fetchSupervisor()"
                            >
                                Reset
                            </v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-card>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-data-table
            :headers="headers"
            :items="!loading ? (isFiltered ? supervisorsFiltered : supervisors) : []"
            :items-per-page="5"
            :loading="loading"
            multi-sort
            :item-class="item => item.activeAccount === false? 'red lighten-5 red--text font-weight-medium': ''"
            loading-text="Loading... Please wait"
            class="elevation-3"
        >
            <!-- active account -->
            <template v-slot:item.activeAccount="{ item }">
               <div class="d-flex justify-center">
                    <v-checkbox
                        color="green"
                        @click="changeLoading"
                        v-model="item.activeAccount"
                    ></v-checkbox>
               </div>
            </template>

            <!-- age column -->
            <template v-slot:item.age="{ item }">
                {{ item.birthDate? moment(item.birthDate) : 'N/A'}}
            </template>
            
            <!-- city column -->
            <template v-slot:item.city="{ item }">
                {{ findCity(item.sectorId) || 'N/A'}}
            </template>

            <!-- phone number column -->
            <template v-slot:item.phoneNumber="{ item }">
                {{ item.phoneNumber || 'N/A'}}
            </template>
            
            <!-- visits number column -->
            <template v-slot:item.visitsNumber="{ }">
                {{ 'N/A' }}
            </template>

            <!-- job Position column -->
            <template v-slot:item.jobPosition="{ item }">
                {{item.jobPosition || 'N/A'}}
            </template>

            <!-- experience years column -->
            <template v-slot:item.experienceYears="{ item }">
                {{item.startingDate? moment(item.startingDate) : 'N/A'}}
            </template>

            <!-- Action column -->
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-center align-center">
                    <!-- edit supervisor -->
                    <tooltip text="Edit supervisor account">
                        <v-btn text small @click="fillSupervisorDialogToEdit(item.id)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip
                      text="Delete supervisor"
                      v-if="getUserType === 'admin'"
                    >
                        <v-btn text small @click="activeDeleteDialog(item.id, 'supervisors')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip
                      text="Samples summary"
                      v-if="getUserType === 'admin'"
                    >
                        <v-btn text small :to="{ name: 'medical-rep-samples', params: { id: item.id }, query: { supervisor: true } }">
                            <v-icon>
                                mdi-pill
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import SupervisorDialog from '../../components/SupervisorDialog'
import Tooltip from '../../components/Tooltip'
import DeleteDialog from '../../components/DeleteDialog'
import { mapState, mapGetters } from 'vuex'
export default {
    data(){
        return {
            // fliterData
            supervisorName: null,
            cityId: null,
            regionIds: [],

            // helpers
            loading: false,
            filterLoading: false,
            regionsLoading: false,
            isFiltered: false,

            headers: [
                { 
                    text: 'Supervisor Name', value: 'name', lign: 'start', sortable: false, class:'primary--text', cellClass:'primary--text'
                },
                { text: 'Age', value: 'age', align: 'start', },
                { text: 'City', value: 'city', align: 'start', },
                { text: 'Phone Number', value: 'phoneNumber', align: 'start', },
                { text: 'Visits Per Day', value: 'visitsNumber', align: 'start', },
                { text: 'Job Position', value: 'jobPosition', align: 'start', },
                { text: 'Years Of Work', value: 'experienceYears', align: 'start', },
                { text: 'Active', value: 'activeAccount', align: 'center', },
                { 
                    text: 'Actions', value: 'action', align: 'center', cellClass:'text-start', sortable: false,
                },
            ]
        }
    },

    components:{
        SupervisorDialog,
        Tooltip,
        DeleteDialog
    },

    mounted(){
        this.loading = true;
        this.filterLoading = true;
        Promise.all([
            this.$store.dispatch('supervisors/fetchAll', true),
            this.$store.dispatch('cities/fetchCityMine'),
            this.$store.dispatch('sectors/fetchAll'),
        ])
        .then(() => {
            this.$store.dispatch('sectors/fetchSectorCities', { cities: this.cities })
            .then(() => {
                this.loading = false;
                this.filterLoading = false;
            });
        })
    },

    methods:{
        // TODO:delete changeLoading method
        changeLoading(){
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 2000)
        },
        
        fetchSupervisor(){
            this.loading = true;
            this.$store.dispatch('supervisors/fetchAll', true)
            .finally(() => {
                this.loading = false;
            });
        },

        launchSupervisorDialog(){
            this.$refs.SupervisorDialog.addNewSupervisor();
        },

        fillSupervisorDialogToEdit(supervisorId){
            this.$refs.SupervisorDialog.editSupervisor(supervisorId)
        },

        moment(date){
            return window.moment(date, "YYYYMMDD").fromNow().slice(0, 2);
        },

        findCity(sectorId){
            return this.getCityNameBySectorId(sectorId).join(', ');
        },

        // filter functions
        cityIdChanged(){
            this.regionIds = [];
            if (!this.cityId) return;
            
            this.regionsLoading = true;
            this.$store.dispatch('regions/fetchAll', {
                cityId: this.cityId
            })
            .finally(() => {
                this.regionsLoading = false
            })
        },

        clearFilter(){
            this.cityId = null;
            this.supervisorName = null;
            this.regionIds = [];

            this.isFiltered = false;
        },
        
        filter(){
            this.loading = true;
            this.$store.dispatch('supervisors/fetchByFilter', {
                name: this.supervisorName,
                regionIds: this.regionIds
            })
            .then(() => {
                this.isFiltered = true
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // delete dialog function
        activeDeleteDialog(id, moduleType){
            this.$refs.deleteDialog.activeDeleteDialog(id, moduleType);
        }
    },

    computed:{
        supervisors(){
            return ['نور الدين الخطيب', 'سارة فارس', 'أحمد خطيب'];
        },

        offices(){
            return ['حلب', 'حماة', 'دمشق'];
        },

        regions(){
            return ['region 1', 'region 2', 'region 3'];
        },

        isAllFieldsEmpty(){
            return (!this.supervisorName && this.regionIds.length === 0)
        },

        ...mapState({
            cities: state => state.cities.cities,
            regions: state => state.regions.regions,
            supervisors: state => state.supervisors.supervisors,
            supervisorsFiltered: state => state.supervisors.supervisorsFiltered,
        }),

        ...mapGetters({
            getSectorById:'sectors/getSectorById',
            getUserType:'auth/getUserType',
            getRegionById:'regions/getRegionById',
            getCityById:'cities/getCityById',
            getCityNameBySectorId: 'sectors/getCityNameBySectorId',
        })
    },

    metaInfo: {
        title: 'Supervisors',
        titleTemplate: '%s | Octoppharma'
    }
}
</script>

<style>

</style>