<template>
	<v-app>
        <navbar v-if="authenticated && $route.name !== 'login'" :drawer="drawer" />
        <navigation-drawer v-if="authenticated && $route.name !== 'login'" :drawer="drawer" />
        <v-main>
            <v-container fluid class="pt-0">
                <loader v-if="!authenticated && $route.name !== 'login'" height="95vh"/>
                <!--
                    if it is in any page (except login page), 'authenticated' value will control in route rendering
                    else (for login page) will always be rendered

                    this for rendering page in the same time of rendering navigation-drawer and navbar
                -->
                <router-view v-if="$route.name !== 'login'? authenticated : true"/>
            </v-container>
        </v-main>

        <!-- snackbar -->
        <v-snackbar
            v-model="snackbar"
            app
            transition="slide-y-reverse-transition"
            :color="
                (state === 'info' ? 'primary': '') +
                (state === 'warn' ? 'warning': '') +
                (state === 'error' ? 'red lighten-1': '') + ' white--text'
            "
        >
            {{ message }}

            <template v-slot:action>
				<v-btn
					color="white"
					icon
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import Loader from './components/Loader'
import NavigationDrawer from './components/NavigationDrawer'
import { mapState } from 'vuex'
import Navbar from './components/Navbar.vue'

export default {
	name: 'App',

	components:{
        Loader,
        NavigationDrawer,
        Navbar
	},

	data: () => ({
        isLogin: false,
        drawer: false,

        snackbar: false,
        state: null,
        message: null
    }),

    computed:{
        ...mapState({
            authenticated: state => state.auth.authenticated
        }),
    },

    methods: {
        // fetchMainData() {
        //     console.log(this.authenticated)
		// 	if (this.authenticated) {
		// 		return Promise.all([
        //             this.$store.dispatch('cities/fetchCityMine').then(() => {
        //             this.$store.dispatch('regions/fetchAllRegions', { cities: this.cities })
        //         }),
        //         this.$store.dispatch('workload/fetchAll'),
        //         this.$store.dispatch('medicalreps/fetchAll', true),
        //         this.$store.dispatch('materials/fetchAll', {})
		// 		]).then(() => {
        //             this.$router.replace({ name: 'home' })
        //         })
		// 	}
		// },
    },
    
    created() {
        // console.log(this.authenticated)
        // this.fetchMainData()

        this.$eventBus.$on('show-snackbar', (message, state = 'info') => {
            this.message = message;
            this.state = state;
            this.snackbar = true;
        })
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');
@import "scss/main.scss";

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
  
.pace-inactive {
	display: none;
}
  
.pace .pace-progress {
    background: $color-primary;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
}
  
.pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $color-primary, 0 0 5px rgb(126, 123, 123);
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -moz-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    -o-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}
  
.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: $color-primary;
    border-left-color: $color-primary;
    border-radius: 10px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}
  
@-webkit-keyframes pace-spinner {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
	0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
	0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
	0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
	0% { transform: rotate(0deg); transform: rotate(0deg); }
	100% { transform: rotate(360deg); transform: rotate(360deg); }
}

/* ============================ */
*{
    font-family: 'Cairo', sans-serif;
    // font-size: .9rem;
}

// remove arrows from input number type
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fixed-width{
    // fixing date column width
    &-date{
        width: 110px;
        min-width: 110px;
    }

    // fixing material column width
    &-material{
        width: 200px;
        min-width: 200px;
    }

    // fixing note column width
    &-notes{
        width: 200px;
        min-width: 200px;
    }
}

.font-wight{
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
    &-700 {
        font-weight: 700;
    }
}
.font-size{
    &-1 {
        font-size: .94rem !important;
    }
    &-2 {
        font-size: 1.2rem !important;
    }
    &-3 {
        font-size: 1.45rem !important;
    }
}

@media print {
    .v-navigation-drawer, .v-app-bar, .v-btn {
        display: none;
        
    }
    .v-main {
        padding: 0 !important;
    }
}

.background-title-card {
    background-color: #0b5b6f;
}
.red-light-one-bg {
    background-color: #EF5350
}
.w-100 {
    width: 100%;
}

*::-webkit-scrollbar{
    height: 5px;
    width: 5px;
	background-color: #f8f8f8;
}
*::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 10px;
}

#app {
    .v-input {
		[dir=rtl] & .v-messages__message {
			font-size: 12px;
		}
	}
	.v-input:not(.v-input--radio-group) .v-label:not(.v-label--active) {
		transform: translateY(-4px);
	}
	.v-text-field:not(.v-textarea) .v-input__control .v-input__slot {
		min-height: auto;
		max-height: 32px;
	}
    .v-text-field__suffix {
        margin-top: 0px;
    }
    .v-file-input .v-file-input__text {
        padding-top: 0;
    }
	.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-input__prepend-outer {
		margin-top: 4px;
	}
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		margin-bottom: 0;
	}
	.v-input--dense > .v-input__control > .v-input__slot {
		margin-bottom: 2px;
	}
    .ep-container linearGradient {
        transform: rotate(-10deg);
    }
}

.personal-image {
    position: relative;

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 38%;
        right: 38%;
        left: 38%;
    }
}
</style>