<template>
    <div>
        <v-row :no-gutters="$vuetify.breakpoint.mdAndUp" dense class="ma-0">
            <!-- info basic -->
            <v-col md="3" cols="12" class="pe-md-3">
                <v-form ref="form" @submit.prevent="submit">
                    <v-row dense class="ma-0">
                        <!-- employee photo -->
                        <v-col md="12" sm="6" cols="12" align-self="center">
                            <div class="d-flex justify-center pb-0">
                                <div class="personal-image">
                                    <v-img
                                        :height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        class="rounded-circle"
                                        :src="previewPhoto || supervisorImagePath || require(
                                            supervisorData.gender === gender.male
                                                ? '@/assets/male.jpg'
                                                : '@/assets/avatar-10-vector-37332908.jpg'
                                        )"
                                        :gradient="supervisorRepPhoto ? '#eee2, #eee7, #eee2' : null"
                                    ></v-img>
                                    
                                    <div class="personal-image__actions">
                                        <v-btn
                                            v-if="supervisorRepPhoto !== null"
                                            icon
                                            width="30"
                                            height="30"
                                            min-width="0"
                                            color="red lighten-1"
                                            @click="previewPhoto = null; supervisorRepPhoto = null"
                                        >
                                            <v-icon> mdi-delete </v-icon>
                                        </v-btn>

                                        <v-file-input
                                            v-model="supervisorRepPhoto"
                                            class="ma-0 pa-0"
                                            accept="image/*"
                                            label="employee photo"
                                            placeholder="Pick a photo"
                                            :prepend-icon="supervisorRepPhoto !== null ? 'mdi-image' : 'mdi-plus-circle'"
                                            hide-input
                                            @change="onImageChange"
                                        ></v-file-input>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <!-- name -->
                        <v-col md="12" sm="6" cols="12" align-self="center">
                            <!-- name -->
                            <v-text-field
                                v-model="supervisorData.name"
                                label="name"
                                hide-details
                                outlined
                                dense
                                pri
                                prepend-icon="mdi-account-tie"
                                :disabled="!isEditActive"
                                class="mb-1"
                                :rules="rules.required"
                            ></v-text-field>

                            <!-- gender -->
                            <v-radio-group
                                v-model="supervisorData.gender"
                                hide-details
                                row
                                dense
                                class="ma-0 pt-0"
                            >
                                <span class="d-flex align-center me-1">
                                    <v-icon class="me-2" size="26"> mdi-gender-male-female</v-icon>
                                    Gender:
                                </span>
                                <v-radio
                                    v-for="gender in genders"
                                    :key="gender.id"
                                    :label="gender.name"
                                    :value="gender.id"
                                    dense
                                    hide-details
                                    class="ma-0 me-2"
                                    :disabled="!isEditActive"
                                ></v-radio>
                            </v-radio-group>
                            
                            <!-- birthday & phone -->
                            <template v-if="$vuetify.breakpoint.smAndDown">
                                <!-- birthday -->
                                <bee-date-picker
                                    v-model="supervisorData.birthDate"
                                    input-label="Birthday date"
                                    elevation="2"
                                    menu-picker
                                    dense
                                    hide-details
                                    :input-props="{
                                        outlined: true,
                                        dense: true,
                                        prependIcon: 'mdi-calendar'
                                    }"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1940-01-01"
                                    :disabled="!isEditActive"
                                ></bee-date-picker>

                                <!-- phone -->
                                <v-text-field 
                                    v-if="$vuetify.breakpoint.smAndDown"
                                    v-model="supervisorData.phoneNumber"
                                    label="Phone number"
                                    prepend-icon="mdi-phone"
                                    dense
                                    hide-details
                                    outlined
                                    :disabled="!isEditActive"
                                    class="mt-2"
                                />
                            </template>
                        </v-col>

                        <!-- birthday & phone -->
                        <template v-if="!$vuetify.breakpoint.smAndDown">
                            <!-- birthday -->
                            <v-col md="12" sm="6" cols="12">
                                <bee-date-picker
                                    v-model="supervisorData.birthDate"
                                    input-label="Birthday date"
                                    elevation="2"
                                    menu-picker
                                    dense
                                    hide-details
                                    :input-props="{
                                        outlined: true,
                                        dense: true,
                                        prependIcon: 'mdi-calendar'
                                    }"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1940-01-01"
                                    :disabled="!isEditActive"
                                ></bee-date-picker>
                            </v-col>

                            <!-- phone -->
                            <v-col md="12" sm="6" cols="12">
                                <v-text-field 
                                    v-model="supervisorData.phone"
                                    label="Phone number"
                                    prepend-icon="mdi-phone"
                                    dense
                                    hide-details
                                    outlined
                                    :disabled="!isEditActive"
                                />
                            </v-col>
                        </template>

                        <!-- Telephone number -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                label="Telephone number"
                                v-model="supervisorData.landlineNumber"
                                outlined
                                dens
                                prepend-icon="mdi-phone-classic"
                                hide-details="auto"
                                :disabled="!isEditActive"
                            ></v-text-field>
                        </v-col>

                        <!-- address -->
                        <v-col md="12" sm="6" cols="12">
                            <v-text-field 
                                v-model="supervisorData.address"
                                label="Full Address"
                                prepend-icon="mdi-map-marker"
                                dense
                                hide-detail
                                outlined
                                :disabled="!isEditActive"
                            />
                        </v-col>

                        <!-- email -->
                        <v-col md="12" sm="6" cols="12">
                            <v-text-field 
                                v-model="supervisorData.email"
                                label="Email"
                                prepend-icon="mdi-email"
                                dense
                                hide-detail
                                outlined
                                :disabled="!isEditActive"
                            />
                        </v-col>   

                        <!-- facebookProfileUrl -->
                        <v-col md="12" sm="6" cols="12">
                            <v-text-field 
                                v-model="supervisorData.facebookProfileUrl"
                                label="Facebook profile Url"
                                prepend-icon="mdi-facebook"
                                dense
                                hide-detail
                                outlined
                                :disabled="!isEditActive"
                            />
                        </v-col>

                        <!-- startingDate -->
                        <v-col cols="12" sm="6" md="12">
                            <bee-date-picker
                                v-model="supervisorData.startingDate"
                                input-label="Hiring date"
                                elevation="2"
                                menu-picker
                                dense
                                hide-details
                                :input-props="{
                                    outlined: true,
                                    dense: true
                                }"
                                :disabled="!isEditActive"
                            ></bee-date-picker>
                        </v-col>

                        <!-- username -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                v-model="supervisorData.username"
                                label="Username"
                                elevation="2"
                                dense
                                hide-details
                                outline
                                prepend-icon="mdi-account"
                                :disabled="!isEditActive"
                                :rules="rules.required"
                            />
                        </v-col>

                        <!-- password -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                v-model="supervisorData.password"
                                label="Password"
                                elevation="2"
                                dense
                                hide-detail
                                outlined
                                prepend-icon="mdi-eye"
                                :disabled="!isEditActive"
                            />
                        </v-col>

                        <!-- action -->
                        <v-col cols="12" class="pa-md-0">
                            <v-btn
                                v-if="isSuperAdmin || permissionsUser.MedicalReps === roleName.action"
                                :color="!isEditActive ? 'edit-color white--text' : 'success white--text'"
                                :loading="submitLoading"
                                :disabled="!isSuperAdmin || permissionsUser.MedicalReps !== roleName.action"
                                type="submit"
                                height="35"
                                :width="!isEditActive ? '100%' : '70%'"
                            >
                                <v-icon size="22" class="me-1" >
                                    {{ !isEditActive ? 'mdi-pencil' : 'mdi-content-save-edit'}} 
                                </v-icon>
                                {{ !isEditActive ? 'Edit data' : 'Save'}}
                            </v-btn>
                            <v-btn
                                v-if="isEditActive"
                                height="35"
                                width="27%"
                                class="ms-2"
                                @click="isEditActive = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-divider
            	v-if="$vuetify.breakpoint.mdAndUp"
            	vertical
            	:style="{ minHeight: `${$vuetify.breakpoint.height - 60}px` }"
            />

            <v-col md="9" cols="12" class="ps-md-3">
                <div class="d-flex justify-space-between">
                    <div>
                        <!-- tabs -->
                        <v-tabs v-model="tab" show-arrows height="35">
                            <v-tab
                                class="text-capitalize "
                                :to="{ 
                                    name: 'supervisors-personal-data', 
                                    params: { id: $route.params.id },
                                    query: { employeeType: userType.supervisor }
                                }"
                            >
                                Work data
                            </v-tab>
                            <v-tab
                                class="text-capitalize "
                                :to="{ 
                                    name: 'supervisors-samples', 
                                    params: { id: $route.params.id }
                                }"
                            >
                                Summary samples
                            </v-tab>
                        </v-tabs>
                        <v-divider class="mb-2"/>
                    </div>

                    <!-- BACK -->
                    <v-btn
                        color="grey darken-2 white--text"
                        small
                        :to="{ name: 'staff' }"
                        class="align-self-center"
                    >
                        <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                        Back
                    </v-btn>
                </div>
                <loader v-if="loading"/>
                <router-view v-else/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { gender, genders, userType, roleName } from '@/helpers/enums'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import rules from '@/validation rules'
import Loader from '@/components/Loader.vue'

export default {
    name: 'SupervisorInfo',

    components: {
        Loader
    },

    data() {
        return {
            // helpers
            tab: 0,
            roleName,
            loading: false,
            isEditActive: false,
            isFiltered: false,

            submitLoading: false,
            gender,
            genders,
            userType,
            rules,

            supervisorRepPhoto: null,
            previewPhoto: null,

            // data
            supervisorData: {
                name: null,
                birthDate: null, 
                landlineNumber: null,
                phoneNumber: null,
                cityId: null,
                sectorId: null,
                address: null,
                visitPerDay: null,
                email: null,
                jobPosition: null,
                facebookProfileUrl: null, 
                startingDate: null,
                target: null,
                facebook: null,
                gender: 1,
                username: null
            },
            supervisorImagePath: null,
        }
    },

    computed: {
        ...mapState({
            supervisors: state => state.supervisors.supervisors,
            cities: state => state.cities.cities,
            sectorsFiltered: state => state.sectors.sectorsFiltered,
            sectors: state => state.sectors.sectors,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            getSupervisorById: 'supervisors/getSupervisorById',
            isAdmin: 'auth/isAdmin',
            getUserType: 'auth/getUserType',
        })
    },

    methods: {
        submit() {
            if (!this.isEditActive) {
                this.isEditActive = true;
            } else if (this.$refs.form.validate()) {
                this.submitLoading = true;
                this.$store.dispatch('supervisors/update', {
                    id: this.$route.params.id,
                    data: this.supervisorData
                }).then(() => {
                    this.isEditActive = false
                    this.$eventBus.$emit('show-snackbar', 'Employee is edited', 'info');
                }).finally(() => {
                    this.submitLoading = false
                })  
            }
        },

        onImageChange() {
            if (this.supervisorRepPhoto) {
                const reader = new FileReader();
                reader.readAsDataURL(this.supervisorRepPhoto);
                reader.onload = e => { this.previewPhoto = e.target.result; }
            }
        },
    },

    created() {
        const initMedicalRepData = () => {
            const data = this.getSupervisorById(this.$route.params.id);
            this.supervisorData.name = data.name;
            this.supervisorData.birthDate = moment(data.birthDate).format('YYYY-MM-DD');
            this.supervisorData.email = data.email;
            this.supervisorData.landlineNumber = data.phoneNumber;
            this.supervisorData.phoneNumber = data.landlineNumber;
            this.supervisorData.sectorId = data.sectorId;
            this.supervisorData.address = data.address;
            this.supervisorData.jobPosition = data.jobPosition;
            this.supervisorData.facebook = data.facebookProfileUrl;
            this.supervisorData.startingDate = null;
            this.supervisorRepPhoto = data.imagePath
            this.supervisorData.facebook = data.facebook;
            this.supervisorData.address = data.address;
            this.supervisorData.username = data.username;
        }
        if (this.supervisors.length === 0) { 
            this.loading = true
            this.$store.dispatch('supervisors/fetchAll', true).then(() => {
                initMedicalRepData();
            }).finally(() => {
                this.loading = false;
            })
        } else {
            initMedicalRepData();
        }
    }
}
</script>