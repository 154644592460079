import * as types from '../mutation-types'

export default {
    namespaced: true,

    state: {
        // plannedVisits: { plannedVisits:[] },
        plannedVisitsByEmployeeId: [],
        plan: [],
        myPlan: []
    },

    actions: {
        fetchAll ({ commit }) {
            // return window.axios.get('/plannedVisits').then((data) => {
            //     commit(types.STORE_VISITS_PLAN, data)
            //     return data
            // })
        },

        fetchByDate ({ commit }, { toDate, fromDate }) {
            return window.axios.get('/visitsPlan/ByDate', { params: { toDate, fromDate } }).then(({ data }) => {
                return data
            })
        },

        fetchByRepName ({ commit }, { employeeId }) {
            return window.axios.get(`/visitsPlans/${employeeId}`).then(({ data }) => {
                return data
            })
        },

        create ({ commit }, { entityId, date, visitPlanId }) {
            return window.axios.post('/plannedVisits', { entityId, date, visitPlanId }).then(({ data }) => {
                return data
            })
        },

        createMultiSelect ({ commit }, { entityId, date, visitPlanId }) {
            return window.axios.post('/plannedVisits/MultiSelect', { entityId, date, visitPlanId }).then((data) => {
                return data
            })
        },

        // update visit in plan
        update({ commit }, { id, entityId, date, visitPlanId }) {
            return window.axios.put(`/plannedVisits/${id}`, { entityId, date, visitPlanId }).then(({ data }) => {
                return data
            })
        },

        getPlanByMedicalRep ({ commit }, { employeeId }) {
            return window.axios.get(`/visitsPlan/${employeeId}`).then(({ data }) => {
                return data
            })
        },

        fetchPlannedVisitsByEmployeeId ({ commit }, { employeeId, fromDate }) {
            return window.axios.get(`plannedVisits/employee/${employeeId}`, { params: { fromDate } }).then(({ data }) => {
                commit(types.STORE_PLANNED_VISITS_PLAN, data)
                return data
            })
        },

        delete ({ commit }, { id }) {
            return window.axios.delete(`plannedVisits/${id}`)
        },

        allPlan ({ commit }, { employeeId }) {
            return window.axios.get(`visitsPlan/${employeeId}/All`).then(({ data }) => {
                commit(types.STORE_VISITS_PLAN, data)
                return data
            })
        },

        createPlan ({ commit }, { employeeId, fromDate, toDate }) {
            return window.axios.post('/visitsPlan', { employeeId, fromDate, toDate, active: true }).then(({ data }) => {
                return data
            })
        },

        updatePlan ({ commit }, { id }) {
            return window.axios.put(`visitsPlan/${id}`).then(({ data }) => {
                return data
            })
        },

        getPlanMine({ commit }) {
            return window.axios.get('/visitsPlan/Mine').then(({ data }) => {
                commit(types.STORE_MY_PLAN, data)
                return data
            })
        }
    },

    mutations: {
        [types.STORE_VISITS_PLAN] (state, plan) {
            state.plan = plan
        },
        [types.STORE_PLANNED_VISITS_PLAN] (state, plannedVisitsByEmployeeId) {
            state.plannedVisitsByEmployeeId = plannedVisitsByEmployeeId
        },
        [types.STORE_MY_PLAN] (state, myPlan) {
            state.myPlan = myPlan
        }
    },

    getters: {
        getPlanById: state => id => state.plan.find(c => c.id === id),
        getVisitById: state => id => state.plannedVisitsByEmployeeId.find(c => c.id === id),
        getPlanByDate: state => date => state.plan.find(c => c.createAt === date)
    }
}