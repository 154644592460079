<template>
    <div>
        <div class="d-flex justify-space-between my-1">
            <div>
                <!-- add specialty -->
                <v-btn
                	small
                	class="mx-2"
                	color="light-blue white--text"
                	@click="specialtyName = null; specialtyId = null; dialog = true;"
                    :disabled="loading"
                >
                    <v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    add new specialty
                </v-btn>

                <!-- BACK -->
                <v-btn
                    color="grey darken-2 white--text"
                    small
                    :to="{ name: 'customers' }"
                    :disabled="loading"
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    Back
                </v-btn>
            </div>
        </div>

        <!-- add/edit dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="300"
            v-model="dialog"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <div class="d-flex py-1 primary">
                        <v-card-title
                            class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
                        >
                            {{ specialtyId ? 'Edit specialty' : 'Add new specialty' }}
                        </v-card-title>

                        <v-btn icon @click="dialog = false">
                            <v-icon color="white"> mdi-close-circle-outline </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-2 px-3 pb-0">
                        <v-row dense class="ma-0">
                            <!-- Name -->
                            <v-col cols="12" class="py-1">
                                <v-text-field
                                    label="Specialty name"
                                    clearable
                                    v-model="specialtyName"
                                    :rules="rules.required"
                                    :disabled="loading"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn
                            type="submit"
                            text
                            color="success"
                            :loading="submitLoading"
                        >
                            save
                        </v-btn>
                        <v-btn
                            text
                            :disabled="submitLoading"
                            @click="dialog = false"
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <delete-dialog
            ref="deleteDialog"
            success-message="Specialty is deleted"
            @on-delete="fetchSpecialties"
        >
            Are you sure you want to <span class="red--text">delete</span> this specialty?
        </delete-dialog>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? specialties : []"
            :loading="loading"
            fixed-header
            :height="$vuetify.breakpoint.height - 100"
            pagination-on-scroll
            dense
            zebra
        >
            <template v-slot:item.action="{ item }">
                <v-btn
                	icon
                	small
                	color="edit-color"
                	@click="specialtyName = item.name; specialtyId = item.id; dialog = true;"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                	icon
                	small
                	color="alert-color"
                	@click="$refs.deleteDialog.activeDeleteDialog(item.id, 'specialties')"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import rules from '@/validation rules'
import DeleteDialog from '@/components/DeleteDialog'
import { mapState } from 'vuex'
import { roleName } from '@/helpers/enums'

export default {
    name: 'SpecializationsManagement',

    components: {
        DeleteDialog
    },

    data(){
        return {
            loading: false,
            submitLoading: false,
            
            specialtyId: null,
            dialog: false,
            rules,

            // data
            specialtyName: null,
            roleName
        }
    },

    computed:{
        ...mapState({
            specialties: state => state.specialties.specialties,
            permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        headers() {
            const headers = [
                { 
                    text: 'specialty',
                    align: 'start',
                    value: 'name',
                    class:'text-body-2 font-weight-bold',
                },
            ];
            if (this.isSuperAdmin || this.permissionsUser.Entities === roleName.action) {
                headers.push({

                    text: 'Action', 
                    name: 'action',
                    align: 'center',
                    value: 'action',
                    class:'text-body-2 font-weight-bold',
                    sortable: false,
             
                })
            }
            return headers
        }
    },

    methods: {
        fetchSpecialties() {
            this.loading = true;

            return this.$store.dispatch('specialties/fetchAll').finally(() => {
                this.loading = false;
            });
        },

        submit() {
            this.submitLoading = true;
            const actionName = !this.specialtyId ? 'create' : 'update';

            this.$store.dispatch(`specialties/${actionName}`, {
                id: this.specialtyId,
                specialty: { name: this.specialtyName }
            }).then(() => {
                this.dialog = false;
                this.fetchSpecialties();
            }).finally(() => {
                this.submitLoading = false;
            });
        }
    },

    created(){
        this.fetchSpecialties()
    },

    metaInfo: {
        title: 'Specializations management',
        titleTemplate: '%s | Octopharma'
    }
}
</script>