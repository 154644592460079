import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        materialForms: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/materialforms').then((response) => {
                commit(types.STORE_MATERIALS_FORM, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, id){
            return window.axios.get(`/materialforms/${id}`).then((response) => {
                return response.data;
            });
        },

        create({ commit }, name){
            return window.axios.post('/materialforms', name);
        },

        update({ commit }, { id, name }){
            return window.axios.put(`/materialforms/${id}`, { name });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/materialforms/${id}`);
        },
    },

    mutations:{
        [types.STORE_MATERIALS_FORM](state, materialForms){
            state.materialForms = materialForms;
        }
    },

    getters:{
        getMaterialFormById: state => id => state.materialForms.find(c => c.id === id)
    }
}