import * as types from '../mutation-types';

export default {
    namespaced: true,

    state: {
        roles: [],
        directChildrens: [],
        childrenRole: [],
        tree: [],
        employeesInRoles: []
    },

    actions: {
        // fetchAll ({ commit }) {
        //     return window.axios.get('/role').then(({ data }) => {
        //         commit(types.STORE_ROLES, data);
        //         return data
        //     })
        // },

        fetchById ({ commit }, { id }) {
            return window.axios.get(`/role/${id}`).then((data) => {
                return data
            })
        },

        create ({ commit }, { name, parentId, type }){
            return window.axios.post('/Role', { name, parentId, type }).then(({ data }) => {
                return data
            })
        },

        update ({ commit }, { id, newName, parentRoleId, permissions, type }) {
            return window.axios.put(`/Role/${id}`, { 
                newName, 
                parentRoleId, 
                type,
                permissions 
            }).then(({ data }) => {
                return data
            })
        },

        fetchChildrenRole ({ commit }, { id }) {
            return window.axios.get(`/Role/Children/${id}`).then(({ data }) => {
                commit(types.STORE_ROLES, data)
                return data
            })
        },

        fetchRoleEmployee ({ commit }, { id, name, regionIds, nonDeleted, teamIds }) {
            var urlSearchParams = new URLSearchParams();
            var params = { id, name, nonDeleted };
            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) {
                    urlSearchParams.append(param, params[param]);
                } 
            })
            regionIds.forEach(id => {
                urlSearchParams.append("regionIds", id);
            })
            teamIds.forEach(id => {
                urlSearchParams.append("teamIds", id);
            })
            return window.axios.get(`/Role/employee/${id}`, { 
                params: urlSearchParams 
            }).then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        fetchDirectChildrenForUser({ commit }, { id }) {
            return window.axios.get(`/Role/DirectDescendants/${id}`).then(({ data }) => {
                commit(types.STORE_DIRECT_CHILDREN, data)
                return data
            })
        },

        fetchTree({ commit }) {
            return window.axios.get('/Role/Tree').then(({ data }) => {
                commit(types.STORE_ROLE_TREE, data)
                return data
            })
        }
    },

    mutations: {
        [types.STORE_ROLES] (state, roles) {
            state.roles = roles
        },
        [types.STORE_DIRECT_CHILDREN] (state, directChildrens) {
            state.directChildrens = directChildrens
        },
        // [types.CHILDREN_ROLES] (state, childrenRole) {
        //     state.childrenRole = childrenRole
        // },
        [types.STORE_ROLE_TREE] (state, tree) {
            state.tree = tree
        },
        [types.STORE_EMPLOYEES_ROLE](state, employeesInRoles) {
            state.employeesInRoles = employeesInRoles
        }
    },

    getters:{
        getRoleById: state => id => state.roles.find(c => c.id === id),
        getEmployeeForRolesById: state => id => state.employeesInRoles.find(c => c.id === id)
    }

}