<template> 
    <v-app-bar
		app
		color="white"
		class="px-xs-5 elevation-0"
		height="40"
    >

		<!-- title -->
		<div class="d-flex flex-grow-1">
			<h1 class="text-h6 primary--text text-capitalize">
				{{ $route.name !== 'login' ?  getRouteTitle() : '' }}
			</h1>
		</div>
        
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    text
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="me-1"> mdi-account-circle </v-icon>
                    {{userName}}
                </v-btn>
            </template>

            <v-list class="px-0 py-0">
                <v-list-item class="px-0" >
                    <v-card
                        max-width="400"
                        class="mx-auto"
                    >
                        <v-card-title class="py-1">
                            <v-img 
                                src="@/assets/male.jpg" 
                                contain
								max-width="50"
								width="50"
								min-width="50"  
                                class="me-4"
                            />
                            <span class="text-body"> {{userName}} </span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-list>
                            <v-list-item >
                                <v-list-item-content>
                                    <v-text-field
                                        v-model="vacations"
                                    	label="Vacations balance"
                                    	dense
                                    	outlined
                                    	hide-details="auto"
                                    	disabled
                                    />
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-divider class="mx-2"></v-divider>
                            
                            <!-- <v-list-item @click="vacationDialog = true">
                                <v-list-item-title>
                                    <v-icon color="orange">
                                        mdi-calendar
                                    </v-icon>
                                    Day off request
                                </v-list-item-title>
                            </v-list-item> -->

                            <v-divider class="mx-2"></v-divider>

                            <v-list-item @click="logout()" >
                                <v-list-item-title>
                                    <v-icon color="orange">
                                        mdi-logout
                                    </v-icon>
                                    Logout
                                </v-list-item-title>
                            
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
    
</template>

<script>
import routes from '@/router/routes';
import { mapState } from 'vuex'
import { requestTypes } from '@/helpers/enums'
export default {
    name:'navbar',

    data(){
        return {
            submitLoading: false,
            selectedItem: -1,
            items:[
                { title: 'Logout' },
            ],
            on: false,
            vacationDialog: false,
            Days: [],
            vacations: null,
            requestTypes
        }
    },

    computed:{
        ...mapState({
            userName: state => state.auth.userData.username,
            employeeId: state => state.auth.userData.employeeId
        })
    },

    methods:{
		getRouteTitle() {
            const translateKey = this.$route.meta.i18nKey;
            const title = translateKey ? this.$t(`routes.${translateKey}`) : null;
            if (this.$route.params.type) {
                return this.$route.params.type + ' ' + title;
            } else {
                return title
            }
		},

        logout(){
            this.$store.dispatch('auth/logout');
            this.$router.replace({ name:'login' })
        }
    },

    created() {
        this.$store.dispatch('leaveManagement/fetchCount', { employeeId: this.employeeId }).then((data) => {
            this.vacations = data.remainingNumberOfLeaves
        })
    }
    
}
</script>

<style lang="scss">
@import '../scss/variables.scss';
#companey-name{
    font-weight: bolder;
    // line-height: 20px;
    h1{
        span:nth-child(1){
            font-size: 25px ;
            margin-bottom: -5px;
            line-height: 30px;
        }
        span:nth-child(2){
            font-size: 16px;
        }
    }
}
</style>