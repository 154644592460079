<template>
    <div>
         	
		<v-row class="mt-3 mx-1">
			<v-col
				v-if="0"
				cols="12"
				sm="12"
				md="3"
				class="style-tree px-0 py-0"
				:style="$vuetify.breakpoint.mdAndUp ? 'height: 585px' : 'height: auto' "
			>
				<!-- add button -->
				<div v-if="0" class="px-2 pt-0 pb-md-0 pb-1 mt-2">
					<v-btn
						elevation="1"
						color="primary"
						class="px-2"
						:disabled="disableAddBtn"
						small
						@click="roleDialog = true"
					>
						<v-icon class="me-1">mdi-seed-plus-outline</v-icon>
						add
					</v-btn>
				</div>
				<!-- <v-col md="4" cols="12" class="px-2 pb-md-0 pb-1">
					<v-autocomplete
						v-model="itemOpen"
						:items="items"
						label="id or name search"
						item-value="id"
						:item-text="item => `${item.name}`"
						outlined
						dense
						hide-no-data
						hide-details
						auto-select-first
					></v-autocomplete>
				</v-col> -->
			</v-col>

			<v-col
				cols="3"
				class="style-tree px-0"
				:style="$vuetify.breakpoint.mdAndUp ? 'height: 585px' : 'height: auto' "
			>
                <!-- <v-row class="align-center mt-0 mb-2">
                    <v-col md="8" cols="12" class="ps-4 pt-0 pb-md-0 pb-1 ">
                        <v-autocomplete
                            v-model="itemOpen"
                            :items="itemsAuto"
                            label="id or name search"
                            item-value="id"
                            :item-text="item => `${item.name}`"
                            outlined
                            dense
                            hide-no-data
                            hide-details
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                </v-row> -->
				<!-- {{activeNodes}} -->
                <bee-treeview
                    :items="usersTree"
                    dense
                    activatable
                    hoverable
                    selection-type="independent"
                    :open-item.sync="itemOpen"
                    :active.sync="activeNodes"
                    :open.sync="openNodes"
                    calculate-width
                    active-class="deep-orange--text text--darken-1"
                    @click="openNodes.push($event.id)"
					:load-children="fetchUsers"
                >
					<!-- @update:active="fetchUsers(activeNodes[0]); openNodes.push($event.id)" -->
                    <template v-slot:prepend-label="{  }">
                        {{'-'}}
                    </template>

                    <template v-slot:prepend="{ item, open, leaf }">
                        <v-icon
                            v-if="!leaf"
                            class="ma-0"
                            @click="
                                open && !disableAddBtn
                                ? openNodes.splice(openNodes.indexOf(item.id), 1)
                                : openNodes.push(item.id)"
                        >
                            {{open ? 'mdi-minus' : 'mdi-plus'}}
                        </v-icon>
                    </template>
                </bee-treeview>

            </v-col>

			<v-col>
				<v-row>
					<v-col cols="3" md="4" class="pb-0">
						<v-text-field v-model="label" outlined dense hide-details label="Name" />
					</v-col>

					<v-col cols="3" md="4" class="pb-0"> 
						<v-text-field v-model="type" outlined dense hide-details label="Job title" />
					</v-col>

					<v-col cols="3" md="4" class="pb-0">
						<v-text-field v-model="label" outlined dense hide-details label="Liner manager" />
					</v-col>

					<v-col cols="4" class="">
						<v-row>
							<v-col align-self="center" lg="5" md="5" sm="6" cols="12" class="pb-0 pe-0">
								<v-autocomplete
									:items="cities"
									item-text="name"
									item-value="id"
									label="City"
									dense
									:disabled="loading || Boolean($route.query.preview)"
									prepend-icon="mdi-map-marker-multiple"
									persistent-hint
									outlined
									hide-details="auto"
								></v-autocomplete>
									<!-- hint="Enter city to filter Areas" -->
							</v-col>
		
							<!-- sector -->
							<v-col align-self="center" lg="7" md="7" sm="6" cols="12" class="pb-0">
								<v-autocomplete
									label="Area"
									:items="sectors"
									item-value="id"
									item-text="name"
									outlined
									dense
									hide-details="auto"
									:disabled="Boolean($route.query.preview)"
									:loading="loading"
									:multiple="$route.query.employeeType !== 'supervisor'"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="3" md="4" class="pb-0" align-self="center">
						<v-autocomplete
							:items="specialties"
							item-text="name"
							item-value="id"
							dense
							hide-details
							outlined
							label="Specialties"
							prepend-icon="mdi-medical-bag"
							multiple
							:disabled="Boolean($route.query.preview)"

						/>
					</v-col>
			
					<!-- visitPerDay -->
					<v-col lg="4" md="4" sm="6" cols="12" class="pb-0" align-self="center">
						<v-text-field 
							label="Visit per day"
							dense
							hide-details
							outlined
							prepend-icon="mdi-counter"
							:disabled="Boolean($route.query.preview)"
							:loading="loading"
						/>
					</v-col>
            
					<!-- target -->
					<v-col lg="4" md="4" sm="6" cols="12" class="pb-0">
						<v-text-field
							label="Target"
							type="number"
							required
							outlined
							dense
							hide-details="auto"
							:loading="loading"
							prepend-icon="mdi-target-account"
							:disabled="Boolean($route.query.preview)"
						></v-text-field>
					</v-col>

					<!-- username -->
					<v-col lg="4" md="4" sm="6" cols="12" class="pb-0">
						<v-text-field
							label="Username"
							required
							outlined
							dense
							hide-details="auto"
							:loading="loading"
							prepend-icon="mdi-account"
							:disabled="Boolean($route.query.preview)"
						></v-text-field>
					</v-col>

					<!-- password -->
					<v-col lg="4" md="4" sm="6" cols="12" class="pb-0">
						<v-text-field
							label="Password"
							required
							outlined
							dense
							hide-details="auto"
							:loading="loading"
							prepend-icon="mdi-eye"
							:disabled="Boolean($route.query.preview)"
						></v-text-field>
					</v-col>

					<!-- actions -->
					<v-col cols="12" class="d-flex justify-end">
						<v-btn
							small
							:color="$route.query.preview ? 'edit-color white--text' : 'success white--text'"
							class="mx-1"
						>
							{{ $route.query.preview ? 'Edit' : 'Save'}} 
						</v-btn>

						<v-btn
							small
							color="alert-color white--text"
							:disabled="$route.query.preview"
						>
							Cancel
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		{{items}}
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import 'vue-simple-flowchart/dist/vue-flowchart.css';
// import SimpleFlowchart from 'vue-simple-flowchart';
export default {
    components: {
    	// TreeChart
		// SimpleFlowchart
    },
    data() {
        return {
			loading: false,
			type: null,
			label: null,
			users: [],

			data: {
				centerX: 1024,
				centerY: 140,
				scale: 1,
				nodes: [
					{
						id: 2,
						x: -700,
						y: -69,
						width: 400,
						height: 200,
						type: "Manager",
						label: "Ahmad",
						approvers: [{
							color: 'red'
						}]
					},
					{
						id: 4,
						x: -357,
						y: 80,
						type: "Supervisor",
						label: "Mohanad",
					},
					{
						id: 6,
						x: -557,
						y: 80,
						type: "Supervisor",
						label: "Rola",
						theme: { 
							headerBackgroundColor: "red"
						}
					},
        		],
				links: [
					{
						id: 3,
						from: 2,
						to: 4,
					},
				],
      		},
			// items: [],
			newItem: [],
			// items: [
                // { 
                //     id: 3,
                //     no: 1,
                //     category: 1,
                //     type: 1,
                //     name: 'long-long',
                //     children: [
                //         {
                //             id: 22,
                //             no: 2,
                //             category: 2,
                //             type: 2,
                //             name: 'long-long-item-text',
                //             children: [{
                //                 id: 122,
                //                 no: 3,
                //                 category: 5,
                //                 type: 5,
                //                 name: 'long-long-item-text'
                //             }]
                //         }
                //     ]
                // },
                // { 
                //     id: 1,
                //     no: 4,
                //     category: 1,
                //     type: 1,
                //     name: 'long-long-test',
                //     children: [
                //         { 
                //             id: 33,
                //             no: 5,
                //             category: 2,
                //             type: 2,
                //             name: 'long-long-item-text',
                //             : [
                //                 { 
                //                     id: 133,
                //                     no: 6,
                //                     category: 3,
                //                     type: 3,
                //                     name: 'long-long-item-text',
                //                     children: [
                //                         { 
                //                             id: 335,
                //                             no: 7,
                //                             category: 4,
                //                             type: 4,
                //                             name: 'long-long-item-text',
                //                             children: [{
                //                                 id: 1533,
                //                                 no: 8,
                //                                 category: 1,
                //                                 type: 1,
                //                                 name: 'long-long-item-text'
                //                             }]
                //                         }
                //                     ]
                //                 }
                //             ]
                //         }
                //     ]
                // }
            // ],
			itemsAuto: [
                { id: 3, no: 1, name: 'long-long-test' },
                { id: 22, no: 2, name: 'long-long-item-text' },
                { id: 122, no: 3, name: 'long-long-item-text' },
                { id: 1, no: 4, name: 'long-long-test' },
                { id: 33, no: 5, name: 'long-long-item-text' },
                { id: 133, no: 6, name: 'long-long-item-text' },
                { id: 335, no: 7, name: 'long-long-item-text' },
                { id: 1533, no: 8, name: 'long-long-item-text' }
            ],
			itemOpen: 122,
			activeNodes: [],
			openNodes: [],
            disableAddBtn: false,
			gtg: false,
			usersTree: []
    	};
	},

	computed: {
		...mapState({
			cities: state => state.cities.cities,
            sectors: state => state.sectors.sectors,
            // supervisors: state => state.supervisors.supervisors,
            // sectorsFiltered: state => state.sectors.sectorsFiltered,
            specialties: state => state.specialties.specialties,
			directChildrens: state => state.rolePermission.directChildrens,
			employeeId: state => state.auth.userData,
			// usersTree: state => state.medicalreps.usersTree
		}),

		items () {
			return [
				{
					name: 'Mohammad',
					children: this.users,
				},
			]
      	},
	},

	methods: {
		add () {
			this.data.nodes.push({ 
				type: this.type,
				label: this.label,
				id: Math.random(),
				x: 0,
				y: 0,
				// theme: { 
				// 	borderColor: "#bbbbbb", 
				// 	borderColorSelected: "#666666", 
				// 	headerTextColor: "red", 
				// 	headerBackgroundColor: "#f1f3f4", 
				// 	bodyBackgroundColor: "white", 
				// 	bodyTextColor: "black" 
				// }
			})
		},
		async fetchUsers(item) {
			// Remove in 6 months and say
			// you've made optimizations! :)
			this.loading = true
			this.$store.dispatch('rolePermission/fetchDirectChildrenForUser', { id: this.$route.params.id }).then((data) => {
				item.children.push(...data)		
			})
		}
	},

	created() {
		this.$router.replace({
			name: 'users-tree',
			params: {
				id: this.employeeId.employeeId
			}
		})
		this.loading = true 
		this.$store.dispatch('medicalreps/fetchUsersTree').then((data) => {
			this.usersTree = data
		})
		// this.$store.dispatch('rolePermission/fetchDirectChildrenForUser', { id: this.employeeId.employeeId }).then((data) => {
		// 	// console.log(data)
		// 	this.items = data

		// 	// console.log(f)
		// }).finally(() => {
		// 	this.loading = false
		// })
	}
}
</script>

<style scoped>
.style-tree {
    /* background: rgba(238, 236, 236, 0.726); */
    background: #118aa825;
    border-radius: 10px;
}
</style>
