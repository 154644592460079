import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import auth from '@/store/modules/auth'
import store from '@/store'
import { permissionsWithName } from '@/helpers/enums'

Vue.use(VueRouter)
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

// linkExactActiveClass: 'active',
const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
})

export default router
const permission = routes.map(c => c.permissionKey).filter(c => c !== undefined)
// for permission url
router.beforeEach((to, from, next) => {
    for (let i = 0; i < permission.length; i++) {
        if (to.name === permission[i]) {
            store.dispatch('auth/getSelf').then(() => {       
                if (!auth.state.isSuperAdmin && (auth.state.userData.permissions.filter(c => c.permissionsNames === permissionsWithName.find(c => c.permissionName === permission[i])?.id)[0].rolePermissions === 0)) {
                    next({ name: 'home', replace: true })
                }
            })
        } else {
            next()
        }
    }
// } 
})
