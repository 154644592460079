import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        exams: [],
        examResults: []
    },

    actions:{
        fetchAll({ commit }, { fromDate, toDate, perPage, page }){
            return window.axios.get('/exams', {
                params: { fromDate, toDate, perPage, page }
            })
            .then((response) => {
                commit(types.STORE_EXAMS, response.data)
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        fetchExamResults({ commit }, { id }){
            return window.axios.get(`/exams/${id}/marks`)
            .then((response) => {
                commit(types.STORE_EXAMS_RESULTS, response.data);
                return response.data;
            });
        },

        create({ commit }, { date, link }){
            return window.axios.post('/exams', { date, link });
        },

        update({ commit }, { id, date, link }){
            return window.axios.put(`/exams/${id}`, { date, link });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/exams/${id}`);
        },

        import({ commit }, { id, excelFile }){
            var formData = new FormData();
            formData.append('excelFile', excelFile);
            return window.axios.post(`/exams/${id}/marks/import`, formData)
            .then((response) => {
                return response.data;
            });
        }
    },

    mutations:{
        [types.STORE_EXAMS](state, exams){
            state.exams = exams;
        },

        [types.STORE_EXAMS_RESULTS](state, examResults){
            state.examResults = examResults;
        },
    },

    getters:{
        getExamById: state => id => state.exams.find(c => c.id === id),
    }
}