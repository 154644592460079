import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        warehouses:[]
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/warehouses')
            .then((response) => {
                commit(types.STORE_WAREHOUSES, response.data);
                return response.data
            })
        },

        create({ commit }, { name }){
            return window.axios.post('/warehouses', { name });
        },

        update({ commit }, { id, name }){
            return window.axios.put(`/warehouses/${id}`, { name });
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/warehouses/${id}`);
        }
    },

    mutations:{
        [types.STORE_WAREHOUSES](state, warehouses){
            state.warehouses = warehouses
        }
    },

    getters:{
        getWarehouseById: state => id => state.warehouses.find(c => id === c.id)
    }
}